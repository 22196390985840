import useSWR from "swr";
import { includes } from "lodash/fp";
import { usePhoneVerificationContext } from "storefront/components/PhoneVerification/Context";
import getUserDecisions, {
  CACHE_KEY,
} from "storefront/GrailedAPI/v1/Sift/getUserDecisions";
import type { Decision } from "storefront/GrailedAPI/v1/Sift/getUserDecisions";

// Sift Decision Constants
const USER_WATCH_DECISION = "user_watch_content_abuse_3";

const userWatchDecisionApplied: (decisions: Array<Decision>) => boolean =
  includes(USER_WATCH_DECISION);

// use phoneVerificationEnabledAtSignUp when user has just been created
export const phoneVerificationEnabledAtSignUp = (
  decisions: Array<string> | null | undefined,
): boolean => userWatchDecisionApplied(decisions || []);

type PhoneVerificationStatus = {
  phoneVerificationRequired: boolean;
  handlePhoneVerificationComplete: () => void;
};

/*
 * usePhoneVerification: () => [boolean, Function]
 *
 * Returns whether phone verification is required given the
 * feature status, feature variant, and verification
 * status of the current authenticated user. Also returns
 * also return an onComplete handler to be called after completing
 * phone verification.
 */
const usePhoneVerification = (): PhoneVerificationStatus => {
  const { phoneNumberVerified, setPhoneNumberVerified } =
    usePhoneVerificationContext();

  const { data: decisions } = useSWR<Array<Decision>, Error>(
    CACHE_KEY,
    getUserDecisions,
  );

  return {
    phoneVerificationRequired:
      !phoneNumberVerified && userWatchDecisionApplied(decisions || []),
    handlePhoneVerificationComplete: () => setPhoneNumberVerified(true),
  };
};

export default usePhoneVerification;
