import React from "react";
import { Department, WOMENSWEAR } from "storefront/Department";
import { TopLevelCategory } from "storefront/Category";
import Caret from "storefront/components/Icons/Navigation/Caret";
import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import classNames from "classnames";
import SubcategoryDisplay from "./SubcategoryDisplay";

type Props = {
  category: TopLevelCategory;
  department: Department;
  shouldLowerSubcategoryContainer?: boolean;
};

const CategoryPopover = ({
  category,
  department,
  shouldLowerSubcategoryContainer: shouldLowerSubcategory,
}: Props) => {
  const { track } = useAnalytics();

  const trackClick = () => {
    track(buttonClicked(category.name, "global_header"));
    track(
      navigationClicked(
        category.name,
        "sub_header_category_dropdown",
        department,
      ),
    );
  };

  return (
    <div className="-category-popover">
      <a
        className="-link"
        href={
          department === WOMENSWEAR
            ? `/categories/${WOMENSWEAR}/${category.slug}`
            : `/categories/${category.slug}`
        }
        onClick={trackClick}
      >
        {category.name}
        <Caret className="-arrow" width="10px" height="7px" />
      </a>
      <SubcategoryDisplay
        className={classNames("-popover", { "-lower": shouldLowerSubcategory })}
        subcategories={category.subcategories}
        from="global_header"
        department={department}
      />
    </div>
  );
};

export default CategoryPopover;
