import React from "react";
import { AuthenticationData } from "../Data";
import { AuthenticationError } from "../Error";
import { Layout } from "../Layout";
import SignInContent from "./SignInContent";

type Props = {
  onSignupClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  onSuccess: (data: AuthenticationData) => void;
  onError: (error: AuthenticationError) => void;
};

const SignInMultiStep = ({ onSignupClick, onSuccess, onError }: Props) => (
  <Layout title="Log in">
    <SignInContent
      onSuccess={onSuccess}
      onError={onError}
      onSignupClick={onSignupClick}
    />
  </Layout>
);

export default SignInMultiStep;
