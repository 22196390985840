import * as React from "react";
import { Field as RFField } from "redux-form";
// @ts-expect-error ts-migrate(2305) FIXME: Module '"redux-form"' has no exported member 'Fiel... Remove this comment to see the full error message
import type { FieldComponentProps } from "redux-form";
import BasicsField from "storefront/components/deprecated_Field";
import type { TypeValue } from "storefront/components/deprecated_Field/Control";

type InnerProps = {
  type: TypeValue;
  disabled?: boolean;
  isSuccessful?: boolean;
};

/**
 * @description This is simply a wrapper component for our Field component in basics,
 * so that it works with the ReduxForm Field API
 */
const ReduxFormFieldComponent = ({
  disabled,
  input,
  isSuccessful = true,
  meta,
  type,

  ...props
}: FieldComponentProps<unknown, InnerProps>) => {
  return (
    <BasicsField
      {...input}
      {...props}
      isFocused={meta.active}
      error={meta.touched && meta.error}
      isSuccessful={isSuccessful && meta.dirty && meta.valid}
      isDisabled={disabled}
      type={type}
      baseClassName="FormField"
    />
  );
};

// @ts-expect-error ts-migrate(2694) FIXME: Namespace 'React' has no exported member 'ElementP... Remove this comment to see the full error message
type Props = React.ElementProps<typeof RFField> & {
  type: TypeValue;
  disabled?: boolean;
};

/**
 * @description This is a Field component which should be used when building forms using ReduxForm
 * It wraps our input UI components in a ReduxForm Field so that we can easily build forms
 * using state management with ReduxForm, and the UI from our own React components.
 */
const Field = (props: Props) => (
  <RFField // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    component={ReduxFormFieldComponent}
  />
);

export default Field;
