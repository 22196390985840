import React, { useState } from "react";
import { isEmpty } from "lodash/fp";
import Button from "storefront/components/Button";
import Field from "storefront/components/deprecated_Field";
import styles from "./EmailForm.module.scss";

type Props = {
  isSubmitting: boolean;
  onSubmit: (value: string) => void;
};

/**
 * @private
 * @description Tests the given value against a regular expression from http://emailregex.com.
 */
const isEmail = (value: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

/**
 * @private
 * @description There is an error when the input has been focused and the value is not an email
 * address, otherwise returns null.
 */
const getError = (value: string, hasBeenFocused: boolean): string | undefined =>
  hasBeenFocused && !isEmail(value) ? "Required" : undefined;

/**
 * @name Users.Authentication.SignUp.EmailForm
 * @description A form to capture the user's email address.
 */
const EmailForm = ({ isSubmitting, onSubmit }: Props) => {
  const [value, setValue] = useState<string>("");
  const [hasBeenFocused, setHasBeenFocused] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // When the input changes, we update the state with the new value.
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  // When the input is focused, we set hasBeenFocused to true.
  const onInputFocus = (): void => {
    setHasBeenFocused(true);
    setIsFocused(true);
  };

  // When the input is blurred, we set isFocused to false.
  const onInputBlur = (): void => {
    setIsFocused(false);
  };

  // When the button is clicked, we validate the email input's value. If it is valid,
  // we call the onSubmit prop with the value.
  const onButtonClick = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setHasBeenFocused(true);
    const error = getError(value, hasBeenFocused);
    if (isEmpty(error)) onSubmit(value);
  };

  return (
    <form className={styles.emailForm}>
      <p>A valid email is required in order to sign up.</p>
      <Field
        type="email"
        label="Email"
        name="email"
        placeholder="Email Address"
        baseClassName={styles.field}
        error={getError(value, hasBeenFocused)}
        value={value}
        onChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        isFocused={isFocused}
        isRequired
      />

      <br />

      <Button
        className={styles.button}
        onClick={onButtonClick}
        disabled={isSubmitting}
        type="submit"
        size="large"
        variant="primary"
      >
        Submit
      </Button>
    </form>
  );
};

export default EmailForm;
