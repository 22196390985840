// TODO: @hsiung maybe use a library for this instead?
//  if not then move to shared forms utils
export type Validator = (value: string | null | undefined) => string | void;

// eslint wants to explicitly return undefined
export const required =
  (message: string): Validator =>
  (value) =>
    !value ? message : undefined;

export const minLength =
  (min: number, message: string): Validator =>
  (value) =>
    value && value.length < min ? message : undefined;

export const emailValidate = required("Required");

export const passwordValidate = [
  required("Required"),
  minLength(8, "Password must be at least 8 characters long."),
];
