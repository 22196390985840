import { compose } from "lodash/fp";
import type { GlobalState } from "../../../GlobalState";
import { conversations } from "../../../GlobalState";
import { hasUnread } from "../../../Conversations";

const hasNotificationsSelector: (arg0: GlobalState) => boolean = compose([
  hasUnread,
  conversations,
]);
export default hasNotificationsSelector;
