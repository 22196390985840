import React from "react";
import cn from "classnames";
import { reduxForm, InjectedFormProps } from "redux-form";
import { useTranslation } from "react-i18next";
import Button from "storefront/components/Button";
import Form, { Field } from "storefront/components/Form";
import { Message } from "../Layout";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../Button.module.scss";

export type Values = {
  username: string;
};

const UsernameForm = ({
  submitting,
  pristine,
  handleSubmit,
}: InjectedFormProps<Values>) => {
  const { t } = useTranslation("common");

  return (
    <Form onSubmit={handleSubmit}>
      <Message>
        Congratulations, we&apos;ve created your {t("MARKETPLACE_NAME")}{" "}
        account! Now add a username. Don&apos;t worry, you can change it later.
      </Message>
      <Field name="username" label="Username" type="text" />

      <Button
        className={cn(styles.authButton, "FormSubmit")}
        size="large"
        type="submit"
        variant="primary"
        disabled={pristine || submitting}
      >
        Create Username
      </Button>
    </Form>
  );
};

export default reduxForm<Values>({
  form: "usernameForm",
})(UsernameForm);
