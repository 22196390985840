import { get } from "storefront/GrailedAPI/request";

type PresignedPost = {
  url: string;
  key: string;
  imageUrl: string;
  fields: Record<string, string>;
};

type Response = {
  data: PresignedPost;
};

export type UploadType = "avatar" | "listing";

const unpack = (res: Response): PresignedPost => {
  return res.data;
};

const getPresignedPost = (uploadType?: UploadType): Promise<PresignedPost> => {
  switch (uploadType) {
    case "avatar":
      return get<Response>("/api/photos/presign/avatar").then(unpack);
    case "listing":
      return get<Response>("/api/photos/presign/listing").then(unpack);
    default:
      return get<Response>("/api/photos/presign").then(unpack);
  }
};

export default getPresignedPost;
