import React, { useEffect, useState } from "react";
import { User } from "storefront/User";
import { AuthenticationError } from "./Error";
import { AuthenticationData } from "./Data";
import { Method } from "./AuthenticateUser/Method";
import { Need, unknown, fromUserAndVerifications } from "./Need";
import { Verification } from "./Verification";
import AuthenticateUser from "./AuthenticateUser";
import VerifyEmail from "./VerifyEmail";

export type Props = {
  currentUser?: User;
  onSuccess: (data: AuthenticationData) => void;
  onError: (error: Error) => void;
  authType?: Method;
  onPhoneVerificationMount?: () => void;
  verifications: Set<Verification>;
};

/**
 * @name Users.Authentication
 * @description This component is responsible for managing the current phase of the sign-in/sign-up
 * process. It is, effectively, a state machine, reacting to the events from its children to change
 * its own state.
 */
const Authentication = ({
  currentUser,
  authType,
  onPhoneVerificationMount,
  onSuccess,
  onError,
  verifications,
}: Props) => {
  const [need, setNeed] = useState<Need>(unknown);
  useEffect(() => {
    const determinedNeed = fromUserAndVerifications(currentUser, verifications);

    if (determinedNeed) {
      setNeed(determinedNeed);
    } else {
      throw new Error("Impossible State");
    }
  }, [currentUser, verifications]);

  switch (need.type) {
    case "Unknown":
      return <></>;

    case "UserAuthentication": {
      const onAuthenticateUserSuccess = (data: AuthenticationData) => {
        const newNeed = fromUserAndVerifications(
          data.user,
          verifications,
          true,
        );

        if (newNeed) {
          setNeed(newNeed);
        } else {
          onSuccess(data);
        }
      };

      const onAuthenticateUserError = (error: AuthenticationError) => {
        switch (error.type) {
          case "OtpRequiredError":
            window.location.href = "/admins/gateway";
            break;

          default:
            onError(error);
            break;
        }
      };

      return (
        <AuthenticateUser
          onSuccess={onAuthenticateUserSuccess}
          onError={onAuthenticateUserError}
          initialMethod={authType}
          onPhoneVerificationMount={onPhoneVerificationMount}
        />
      );
    }

    case "EmailVerification":
      return (
        <VerifyEmail email={need.email} isAfterSignIn={need.isAfterSignIn} />
      );

    default:
      throw new Error("Impossible State");
  }
};

export default Authentication;
