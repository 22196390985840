/* eslint-disable react/jsx-props-no-spreading */
import React, { MouseEvent } from "react";
import cn from "classnames";
import { GetLabelProps } from "@algolia/autocomplete-core";
import Clear from "storefront/components/Icons/Navigation/X";
import MagnifyingGlass from "storefront/components/Icons/MagnifyingGlass";
import styles from "./Icon.module.scss";

type Props = {
  className: string;
  isOpen: boolean;
  closeSearchHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  inputIsFocused: boolean;
  labelProps: ReturnType<GetLabelProps>;
};

const Icon = ({
  className,
  isOpen,
  closeSearchHandler,
  inputIsFocused,
  labelProps,
}: Props) => {
  const { htmlFor, ...otherLabelProps } = labelProps;
  return isOpen || inputIsFocused ? (
    <button
      className={cn(styles.clear, className)}
      title="Clear Search"
      data-testid="clear"
      type="button"
      onClick={closeSearchHandler}
    >
      <Clear />
    </button>
  ) : (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={className}
      title="Open Search"
      data-testid="magnify"
      htmlFor={htmlFor}
      {...otherLabelProps}
    >
      <MagnifyingGlass />
    </label>
  );
};

export default Icon;
