import React from "react";

import classNames from "classnames";
import styles from "./SiteLink.module.scss";

type SiteLinkProps = {
  href: string;
  className?: string;
  onClick?: (arg0: React.SyntheticEvent<any>) => unknown;
  children?: React.ReactNode;
  rel?: string;
  isMobile?: boolean;
};

const SiteLink = ({
  href,
  children,
  className,
  onClick,
  rel,
  isMobile,
}: SiteLinkProps) => {
  const isOffsite = /^http/.test(href);

  return (
    <a
      href={href}
      onClick={onClick}
      className={classNames(styles.siteLink, className, {
        [styles.isMobile]: isMobile,
      })}
      target={isOffsite ? "_blank" : undefined}
      rel={rel}
    >
      {children}
    </a>
  );
};

export default SiteLink;
