import type { UserSelf } from "storefront/User";

export type TrackMessage = "log_in" | "sign_up" | "email_confirmation";
export type ModalTrigger =
  | "click_trigger"
  | "favorite_listing"
  | "follow_collection"
  | "follow_designer"
  | "follow_search"
  | "follow_user"
  | "header"
  | "log_in_button"
  | "logged_in_action" // Default for authed actions
  | "offer_button"
  | "purchase_button"
  | "sell_button"
  | "send_message_button"
  | "sign_up_button"
  | "view_messages"
  | "save_listing";

const formatTrackMessage = (
  currentUser: UserSelf | null | undefined,
  message: TrackMessage,
): TrackMessage =>
  !!currentUser && !currentUser.isConfirmed ? "email_confirmation" : message;

export type AccountModalOpenedEvent = {
  object: "account_modal";
  action: "opened";
  properties: {
    path: string;
    message: string;
    from: string;
  };
};

const accountModalOpenedEventCreator = (
  currentUser: UserSelf | null | undefined,
  path: string,
  message: TrackMessage,
  modalTrigger: ModalTrigger,
): AccountModalOpenedEvent => ({
  object: "account_modal",
  action: "opened",
  properties: {
    path,
    message: formatTrackMessage(currentUser, message),
    from: modalTrigger,
  },
});

export default accountModalOpenedEventCreator;
