import React, { useRef } from "react";

import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import { WOMENSWEAR, MENSWEAR } from "storefront/Department";
import Help from "storefront/components/Page/Header/SlideOutMenu/Items/Help";
import Department from "storefront/components/Page/Header/SlideOutMenu/Items/Department";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";

import Item from "./Item";

import styles from "./SlideOutMenuItems.module.scss";

export type Props = {
  expandedSection: string | null;
  setExpandedSection: (value: React.SetStateAction<string | null>) => void;
  isSignedIn: boolean;
};

const SlideOutMenuItems = ({
  isSignedIn,
  expandedSection,
  setExpandedSection,
}: Props) => {
  const { track } = useAnalytics();
  const menswearDepartmentItemRef = useRef<HTMLHeadingElement>(null);
  const cyberSale2024EnabledResource = useFeatureFlag(Feature.CYBER_SALE_2024);

  const trackNav = (name: string) => {
    track(navigationClicked(name, "global_header"));
  };

  const holidayLink = React.useMemo(() => {
    if (cyberSale2024EnabledResource.type === "Loading") {
      return null;
    }

    if (
      cyberSale2024EnabledResource.type === "Completed" &&
      cyberSale2024EnabledResource.value
    ) {
      return (
        <Item
          label="Cyber Sale"
          href="/drycleanonly/cyber-week "
          from="sub_header"
          onClick={() => trackNav("Cyber Sale 2024")}
          className={styles.red}
        />
      );
    }

    return (
      <Item
        label="Staff Picks"
        href="/shop/staff-picks"
        from="sub_header"
        onClick={() => trackNav("Staff Picks")}
      />
    );
  }, [cyberSale2024EnabledResource, trackNav]);

  return (
    <>
      <Item
        label="Designers"
        href="/designers"
        from="sub_header"
        onClick={() => trackNav("Designers")}
      />
      <SuspenseWithFragment>
        <Department
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
          department={MENSWEAR}
          menswearDepartmentItemRef={menswearDepartmentItemRef}
        />
        <Department
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
          department={WOMENSWEAR}
          menswearDepartmentItemRef={menswearDepartmentItemRef}
        />
      </SuspenseWithFragment>
      <Item
        label="Sneakers"
        href="/categories/sneakers"
        from="sub_header"
        onClick={() => trackNav("Sneakers")}
      />
      <Item
        label="Sell"
        href="/sell/for-sale"
        onClick={() => trackNav("Sell")}
      />
      {isSignedIn ? (
        <Item
          label="For You"
          href="/foryou"
          onClick={() => trackNav("For You")}
        />
      ) : null}
      <Item label="Shop" href="/shop" onClick={() => trackNav("Shop")} />
      {holidayLink}
      <Item
        label="Collections"
        href="/collections"
        onClick={() => trackNav("Collections")}
      />
      <Item
        label="Dry Clean Only"
        href="/drycleanonly"
        onClick={() => trackNav("Dry Clean Only")}
      />
      <Help />
    </>
  );
};

export default SlideOutMenuItems;
