import React from "react";
import { useTranslation } from "react-i18next";
import Body from "storefront/components/Body";
// eslint-disable-next-line css-modules/no-unused-class
import css from "./VerifyEmail.module.scss";

type Props = {
  email: string;
};

const Success = ({ email }: Props) => {
  const { t } = useTranslation("common");

  return (
    <div className={css.root}>
      <Body className={css.body}>
        We&#39;ve sent another confirmation email to <strong>{email}</strong>.
      </Body>

      <Body className={css.body}>
        Please check your inbox. Once you confirm your email, you will have full
        access to {`${t("MARKETPLACE_NAME")}`}.
      </Body>
    </div>
  );
};

export default Success;
