import React from "react";
import Login from "./Login";
import Signup from "./Signup";
import SignupMobile from "./Signup/SignupMobile";

const AuthButtons = () => (
  <>
    <Login authType="LogIn" />
    <Signup authType="CreateAccount" />
    <SignupMobile authType="CreateAccount" />
  </>
);

export default AuthButtons;
