import React, { useContext } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GlobalState } from "storefront/GlobalState";
import { Id } from "storefront/lib/Id";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import MenuExpandedContext, { Value } from "../MenuExpandedContext";
import hasNotificationsSelector from "../hasNotificationsSelector";
import SellButton from "./SellButton";
import MenuItem from "./MenuItem";
import AuthOrProfile from "./AuthOrProfile";
import Hamburger from "./Hamburger";

import "./Menu.scss";

const className = (isLoggedOut: boolean): string =>
  cn("Page-Header-Menu", {
    "_logged-out": isLoggedOut,
  });

const userIdSelector = (state: GlobalState): Id => state.session.currentUser.id;

const Menu = () => {
  const { t } = useTranslation("common");
  const { track } = useAnalytics();
  const { isExpanded, setExpanded } = useContext<Value>(MenuExpandedContext);
  const userId = useSelector(userIdSelector);
  const hasNotifications = useSelector(hasNotificationsSelector);
  const isLoggedOut = !userId || userId === -1;

  const handleClick = () => {
    track(navigationClicked("nav_open", "global_header"));
    setExpanded(true);
  };

  return (
    <div className={className(isLoggedOut)}>
      <SellButton className="SellButton" />

      <MenuItem
        href="/shop"
        label="Shop"
        onClick={() => track(navigationClicked("Shop", "global_header"))}
      />

      <MenuItem
        href={t("BLOG_LINK_PATH")}
        label="Read"
        onClick={() => track(navigationClicked("Read", "global_header"))}
      />

      <AuthOrProfile />

      <Hamburger
        onClick={handleClick}
        hasNotifications={hasNotifications}
        isHidden={isExpanded}
      />
    </div>
  );
};

export default Menu;
