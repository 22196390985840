import React from "react";
import Tooltip from "../../../Onboarding/Tooltip";

type Props = {
  onClick: () => void;
  className?: string;
};

const SavedSearchesTooltip = ({ onClick, className }: Props) => (
  <Tooltip hidden={false} className={className} onClick={onClick} onTop>
    View your Followed Searches here.
  </Tooltip>
);

export default SavedSearchesTooltip;
