import React, { useState } from "react";
import cn from "classnames";

import { isIOS } from "storefront/lib/Navigator";
import useCurrentDevice from "storefront/hooks/useCurrentDevice";
import Clear from "storefront/components/Icons/Navigation/X";
import ProtectionBadge from "storefront/components/Icons/ProtectionBadge";
import trustMicrositeViewed from "storefront/Analytics/EventCreators/trustMicrositeViewed";
import useAnalytics from "storefront/hooks/useAnalytics";
import Footnote from "storefront/components/Footnote";
import styles from "storefront/components/TrustStickyFooter/TrustStickyFooter.module.scss";

// Hide trust sticky footer for UGCs (i.e. community-collection)
// since UGCs have the StickyCommentsForm
// Also hide sticky footer when rendering the SellForm since the
// submit tray is now sticky
export const shouldHideTrustStickyFooter = () =>
  isIOS(window.navigator) ||
  /social-studies/.test(window.location.pathname) ||
  /community-collection/.test(window.location.pathname) ||
  /^\/(?:listings|drafts)\/\d+\/edit$/i.test(window.location.pathname) ||
  window.location.pathname === "/sell/new";

export const trustStickyFooterClosed = () =>
  window.localStorage.getItem("trustStickyClosed") === "true";

// The trust sticky footer does not render on mobile devices
const TrustStickyFooter = () => {
  const tracker = useAnalytics();

  const device = useCurrentDevice();

  const [hidden, setHidden] = useState<boolean>(false);

  if (device === "mobile") return null;

  const handleOnRequestClose = () => {
    window.localStorage.setItem("trustStickyClosed", "true");
    setHidden(true);
  };

  const handleOnLearnMoreClick = () =>
    tracker.track(trustMicrositeViewed("TrustStickyFooter"));

  return (
    <div
      data-testid="trustStickyFooter"
      className={cn(styles.root, {
        [styles.hidden]: hidden,
      })}
    >
      <ProtectionBadge className={styles.badge} height="25px" width="22px" />

      <Footnote as="span" className={styles.message}>
        Buyer Protection & Authenticity Checks
      </Footnote>

      <Footnote
        as="a"
        href="/trust"
        className={styles.link}
        onClick={handleOnLearnMoreClick}
      >
        Learn More
      </Footnote>

      <button
        type="button"
        className={styles.close}
        onClick={handleOnRequestClose}
      >
        <Clear />
      </button>
    </div>
  );
};

export default TrustStickyFooter;
