import React, { useState, useEffect } from "react";
import { IdentityCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import useAnalytics from "storefront/hooks/useAnalytics";
import { AuthenticationError } from "../Error";
import { AuthenticationData } from "../Data";
import { Method } from "./Method";
import { startAuthenticateUserEvent } from "./StartAuthenticateUserEvent";
import { completeAuthenticateUserEvent } from "./CompleteAuthenticateUserEvent";
import SignInMultiStep from "../SignIn/SignInMultiStep";
import SignUpMultiStep from "../SignUpMultiStep";

type Props = {
  initialMethod?: Method;
  onSuccess: (data: AuthenticationData) => void;
  onError: (error: AuthenticationError) => void;
  onPhoneVerificationMount?: () => void;
};

const AuthenticateUser = ({
  initialMethod = "CreateAccount",
  onSuccess,
  onError,
  onPhoneVerificationMount,
}: Props) => {
  const { track } = useAnalytics();
  const [method, setMethod] = useState<Method>(initialMethod);
  const [credentials, setCredentials] = useState<
    IdentityCredentials | undefined
  >();

  useEffect(() => {
    track(startAuthenticateUserEvent(initialMethod));
  }, [initialMethod, track]);

  switch (method) {
    case "LogIn": {
      const onLogInSuccess = (data: AuthenticationData) => {
        track(completeAuthenticateUserEvent("LogIn", data));
        onSuccess(data);
      };

      const onLogInError = (error: AuthenticationError) => {
        switch (error.type) {
          // NOTE: this error comes from api/v1/tokens_controller#from_oauth_provider
          // identity token must exist to reach that controller (see the request in Sessions/create)
          // for apple login, we use a required accessToken as identity token, email may be missing
          case "UserNotFoundError":
            setMethod("CreateAccount");
            setCredentials(error.credentials);
            break;

          default:
            onError(error);
            break;
        }
      };

      return (
        <SignInMultiStep
          onSuccess={onLogInSuccess}
          onError={onLogInError}
          onSignupClick={() => setMethod("CreateAccount")}
        />
      );
    }

    case "CreateAccount": {
      const onCreateAccountSuccess = (data: AuthenticationData) => {
        track(completeAuthenticateUserEvent("CreateAccount", data));
        onSuccess(data);
      };

      const onCreateAccountError = (error: AuthenticationError) => {
        switch (error.type) {
          case "GoBackError":
          case "SignUpError":
            setMethod("LogIn");
            break;

          // NOTE: this error comes from api/v1/tokens_controller#from_oauth_provider
          // identity token must exist to reach that controller (see the request in Sessions/create)
          // for apple login, we use a required accessToken as identity token, email may be missing
          case "UserNotFoundError":
            setMethod("CreateAccount");
            setCredentials(error.credentials);
            break;

          default:
            onError(error);
            break;
        }
      };

      return (
        <SignUpMultiStep
          credentials={credentials}
          onSuccess={onCreateAccountSuccess}
          onError={onCreateAccountError}
          onLoginClick={() => setMethod("LogIn")}
          onPhoneVerificationMount={onPhoneVerificationMount}
          onSignUpError={onCreateAccountError}
        />
      );
    }

    default:
      throw new Error(`Unknown User Authentication Method: ${method}`);
  }
};

export default AuthenticateUser;
