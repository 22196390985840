/**
 * @name Basics.Fields.Select
 * @description Provides an option select box
 *
 * Note that it may be a little abstract but to pass labels and values:
 * @param {Array<{label: value}>} collection - An array of objects in which
 * the key represents the option label, and the value the option value.
 */
import React from "react";
import { compose, map, toPairs } from "lodash/fp";
import type { IndexedList } from "../../../lib/IndexedList";

export type Props = {
  type: "select";
  name: string;
  placeholder?: string;
  collection: IndexedList<string>;
  value?: string;
  isDisabled?: boolean;
  onChange: (arg0: React.SyntheticEvent<HTMLSelectElement>) => void;
  onFocus?: (arg0: React.SyntheticEvent<HTMLSelectElement>) => void;
  onBlur?: (arg0: React.SyntheticEvent<HTMLSelectElement>) => void;
  onKeyPress?: (arg0: React.KeyboardEvent<HTMLSelectElement>) => void;
};

/**
 * @private
 */
const renderOption: (
  arg0: [string, string],
) => React.ReactElement<React.ComponentProps<any>, any> = ([label, value]) => (
  <option key={`${label}_${value}`} value={value}>
    {label}
  </option>
);

const renderPlaceholder: (
  arg0: string,
) => React.ReactElement<React.ComponentProps<any>, any> = (placeholder) => (
  <option key={`${placeholder}_`} disabled value="">
    {placeholder}
  </option>
);

/**
 * @private
 */
const renderOptions: (
  arg0: IndexedList<string>,
) => Array<React.ReactElement<React.ComponentProps<any>, any>> = compose([
  map(renderOption),
  toPairs,
]);

/**
 * @name Basics.Field.Control.Select
 * @private
 */
const Select = ({
  collection,
  isDisabled,
  placeholder,
  value,
  name,
  type,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
}: Props) => (
  <select
    className="--input"
    id={name}
    data-testid={`${name}-select`}
    disabled={isDisabled}
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    value={value || (placeholder ? "" : null)}
    name={name}
    type={type}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
  >
    {placeholder && renderPlaceholder(placeholder)}
    {renderOptions(collection)}
  </select>
);

export default Select;
