/* eslint-disable max-classes-per-file */
import { pipe } from "lodash/fp";
import type { UserSelf } from "storefront/User";
import "storefront/User";
import type { IdentityProvider } from "storefront/GrailedAPI/v1/Sessions/create";
import "storefront/GrailedAPI/v1/Sessions/create";
import { post } from "storefront/GrailedAPI/request";
import GrailedAPIError from "storefront/GrailedAPI/Error";

/**
 * NOTE: refreshToken is not in the API documentation[1]. It looks like it's only used on the
 * PayPal™ One-Touch Seller Sign-Up Experience.
 *
 * [1] https://www.grailed.com/internal/api-docs#operation/createUser
 */
type PasswordParams = {
  email: string;
  password: string;
  recaptchaToken: string;
  recaptchaType: "invisible" | "checkbox";
  optInEmails?: boolean;
  refreshToken?: string;
};

const passwordParams = (
  email: string,
  password: string,
  recaptchaToken: string,
  recaptchaType: "invisible" | "checkbox",
  optInEmails = false,
  refreshToken?: string,
): PasswordParams => ({
  email,
  password,
  recaptchaToken,
  recaptchaType,
  optInEmails,
  refreshToken,
});

/**
 * NOTE: refreshToken is not in the API documentation[1]. It looks like it's only used on the
 * PayPal™ One-Touch Seller Sign-Up Experience.
 *
 * [1] https://www.grailed.com/internal/api-docs#operation/createUser
 */
type IdentityParams = {
  email: string;
  identityProvider: IdentityProvider;
  identityToken: string;
  optInEmails?: boolean;
  refreshToken?: string;
};

const identityParams = (
  email: string,
  identityProvider: IdentityProvider,
  identityToken: string,
  optInEmails?: boolean,
  refreshToken?: string,
): IdentityParams => ({
  email,
  identityProvider,
  identityToken,
  optInEmails,
  refreshToken,
});

type Params = PasswordParams | IdentityParams;
type Data = {
  user: UserSelf;
  token: string;
};
type Metadata = {
  unreadBuyingCount: number;
  unreadSellingCount: number;
};
type Response = {
  data: Data;
  metadata: Metadata;
};

/**
 * Today, on April 23 2020, the only part of this response that we care about is the User.
 */
const unpack = (res: Response): UserSelf => res.data.user;

export class IPQSFailed extends Error {
  type: "IPQSFailed" = "IPQSFailed";

  params: Params;

  // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
  constructor(params: Params, message? = "Invalid email address.") {
    super(message);
    this.name = "IPQSFailed";
    this.params = params;
  }
}
export class EmailTaken extends Error {
  type: "EmailTaken" = "EmailTaken";

  params: Params;

  // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
  constructor(params: Params, message? = "That email is already taken.") {
    super(message);
    this.name = "EmailTaken";
    this.params = params;
  }
}
export class InvalidCredentials extends Error {
  type: "InvalidCredentials" = "InvalidCredentials";

  params: Params;

  // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
  constructor(params: Params, message? = "The given credentials are invalid.") {
    super(message);
    this.name = "InvalidCredentials";
    this.params = params;
  }
}
export class ServiceUnavailable extends Error {
  type: "ServiceUnavailable" = "ServiceUnavailable";

  params: Params;

  constructor(
    params: Params,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "We could not log you in. Please try again later or use a different identity provider.",
  ) {
    super(message);
    this.name = "ServiceUnavailable";
    this.params = params;
  }
}
export class Unknown extends Error {
  type: "Unknown" = "Unknown";

  params: Params;

  constructor(
    params: Params,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "Something went wrong, but we're not sure what. We suggest trying again or contacting help@grailed.com if the problem continues.",
  ) {
    super(message);
    this.name = "Unknown";
    this.params = params;
  }
}
export type UserCreateError =
  | IPQSFailed
  | EmailTaken
  | InvalidCredentials
  | ServiceUnavailable
  | Unknown;

const create = (params: Params): Promise<UserSelf> =>
  post<Response>("/api/users", params, { addPathHeader: true })
    .then(unpack)
    .catch((reason: unknown) => {
      if (typeof reason === "string") {
        throw new Unknown(params, reason);
      }

      if (!(reason instanceof GrailedAPIError)) {
        if (reason instanceof Error) {
          throw new Unknown(params, reason.message);
        }

        throw new Unknown(params);
      }

      const { error } = reason.body;

      if (!error) {
        throw new Unknown(params);
      }

      // NOTE: For this endpoint, we assume that the error is our deprecated GrailedError, which
      // has an error_id. If we're wrong, the default case will make sure things aren't too broken.

      switch (error.error_id) {
        case "failed_ipqs":
          throw new IPQSFailed(params);

        case "email_already_taken":
          throw new EmailTaken(params);

        case "validation_error":
          throw new InvalidCredentials(params);

        case "service_unavailable":
          throw new ServiceUnavailable(params, error.message);

        default:
          throw new Unknown(params, error.message);
      }
    });

export const createWithPassword: (
  email: string,
  password: string,
  recaptchaToken: string,
  recaptchaType: "invisible" | "checkbox",
  optInEmails?: boolean,
  refreshToken?: string,
) => Promise<UserSelf> = pipe([passwordParams, create]);

export const createWithIdentity: (
  email: string,
  identityProvider: IdentityProvider,
  identityToken: string,
  optInEmails?: boolean,
  refreshToken?: string,
) => Promise<UserSelf> = pipe([identityParams, create]);
export default create;
