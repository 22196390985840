import type { Capsules } from "./Capsules";
import { empty as emptyCapsules } from "./Capsules";
import type { Conversations } from "./Conversations";
import { empty as emptyConversations } from "./Conversations";
import type { Filters } from "./Filters";
import { empty as emptyFilters } from "./Filters";
import type { FittingRoom } from "./FittingRoom";
import { empty as emptyFittingRoom } from "./FittingRoom";
import type { Session } from "./Session";
import { empty as emptySession } from "./Session";
import type { Homepage } from "./Homepage";
import { empty as emptyHomepage } from "./Homepage";
import type { Config } from "./Config";
import { empty as emptyConfig } from "./Config";
import type { Listings } from "./Listings";
import { empty as emptyListings } from "./Listings";
import type { SellForm } from "./SellForm";
import { initialState as emptySellForm } from "./SellForm";
import type { Onboarding } from "./Onboarding";
import { empty as emptyOnboarding } from "./Onboarding";

export type GlobalState = {
  capsules: Capsules;
  config: Config;
  conversations: Conversations;
  filters: Filters;
  fittingRoom: FittingRoom;
  form: Record<string, Record<string, any>>;
  homepage: Homepage;
  listings: Listings;
  session: Session;
  sellForm: SellForm;
  onboarding: Onboarding;
};
export type GetState = () => GlobalState;
export const empty: GlobalState = {
  capsules: emptyCapsules,
  config: emptyConfig,
  conversations: emptyConversations,
  filters: emptyFilters,
  fittingRoom: emptyFittingRoom,
  form: {},
  homepage: emptyHomepage,
  listings: emptyListings,
  session: emptySession,
  sellForm: emptySellForm,
  onboarding: emptyOnboarding,
};

/**
 * SELECTORS
 *
 * These functions are convient for use with react-redux's `useSelector` hook!
 *
 * If you have a case where you need to select something deeper, consider composing these functions with some other logic
 *
 * For example, if we have a function like this one:
 *
 * const getCurrentUserId = (session: Session): ?Id => session.currentUser.id;
 *
 * we can't use it with `useSelector`; the function we give to `useSelector` **must** have the type signature `GlobalState -> a` (in our case `a` would be `?Id`).
 *
 * If we compose `getCurrentUserId` with `session` (the selector defined below), we get a function that has exactly the type signature we need:
 *
 * const currentUserIdSelector = compose([ getCurrentUserId, session ]);
 *
 * ---
 *
 * Also, if the structure of the `GlobalState` type changes, we only need to update the logic for these selectors in one place (right here), instead of needing to find all of the places across the codebase where we use them.
 */
export type Selector<T> = (arg0: GlobalState) => T;
export const capsules: Selector<Capsules> = (state) => state.capsules;
export const config: Selector<Config> = (state) => state.config;
export const conversations: Selector<Conversations> = (state) =>
  state.conversations;
export const filters: Selector<Filters> = (state) => state.filters;
export const fittingRoom: Selector<FittingRoom> = (state) => state.fittingRoom;
export const homepage: Selector<Homepage> = (state) => state.homepage;
export const listings: Selector<Listings> = (state) => state.listings;
export const session: Selector<Session> = (state) => state.session;
export const onboarding: Selector<Onboarding> = (state) => state.onboarding;
