import api from "./api";
import type { User } from "./User";

/**
 * https://developers.facebook.com/docs/graph-api/reference/v6.0/profile
 */
type Params = {
  fields: string;
};

/**
 * Request the currently authenticated user's profile; only the name, email, and picture fields.
 *
 * NOTE: We are hardcoding the fields we assume that every caller of this function wants. If you find that there are other fields you'd like, please make `fields` an argument to this function and update the call sites to pass the fields they would like.
 */
const me = (): Promise<User> =>
  api<User, Params>({
    path: "/me",
    params: {
      fields: "name,email,picture",
    },
  });

export default me;
