import React from "react";
import { Icon as IconProps } from "storefront/Icon";

const XCircle = ({ className, height, width, ...props }: IconProps) => (
  <svg
    {...props}
    width={width || 22}
    height={height || 22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="11" cy="11" r="10.5" fill="#E1E1E1" />
    <path
      d="M9.9393 11.0001L5.96964 14.9697L7.03029 16.0304L11 12.0607L14.9696 16.0304L16.0303 14.9697L12.0606 11.0001L16.0303 7.03039L14.9696 5.96973L11 9.9394L7.0303 5.96973L5.96964 7.03039L9.9393 11.0001Z"
      fill="#343434"
    />
  </svg>
);

export default XCircle;
