import React, { useEffect, useState } from "react";
import { tap } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { Id } from "storefront/lib/Id";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import useAnalytics from "storefront/hooks/useAnalytics";
import { Tracker } from "storefront/Analytics/Tracker";
import createSession, {
  Credentials,
  IdentityCredentials,
  IdentityProvider,
  SessionCreateError,
} from "storefront/GrailedAPI/v1/Sessions/create";
import { UserSelf } from "storefront/User";
import {
  UserCreateError,
  createWithIdentity,
} from "storefront/GrailedAPI/v1/Users/create";
import EmailForm from "storefront/components/Authentication/SignUp/EmailForm";
import Button from "storefront/components/Button";
import { IdentityProviderError } from "../AuthenticateUser/IdentityProvider/Error";
import GoogleLogin from "../AuthenticateUser/IdentityProvider/Google";
import FacebookLogin from "../AuthenticateUser/IdentityProvider/Facebook";
import AppleLogin from "../AuthenticateUser/IdentityProvider/Apple";
import { BANNED } from "../SignIn/Errors";
import {
  AuthenticationError,
  OtpRequiredError,
  SignInError,
  UserNotFoundError,
  SignUpError,
} from "../Error";
import { AuthenticationData } from "../Data";
import { Error as ErrorComponent, Hr, Message } from "../Layout";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../Button.module.scss";

const SIGNUP = "signup" as const;

type Props = {
  credentials?: IdentityCredentials;
  displayPhoneVerificationStep: (user: UserSelf) => boolean;
  goToPhoneVerification: (data: AuthenticationData) => void;
  onLoginClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  onEmailClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onSuccess: (data: AuthenticationData) => void;
  onError: (error: AuthenticationError) => void;
  onSignUpError: (error: AuthenticationError) => void;
};

type OtpState = {
  isSubmitting: boolean;
  showEmailForm: boolean;
  error: string | null | undefined;
};

const initialOtpState: OtpState = {
  isSubmitting: false,
  showEmailForm: false,
  error: null,
};

type IdentityProviderSignUpObject =
  | "facebook_sign_up"
  | "google_sign_up"
  | "apple_sign_up"
  | "email_sign_up";

const objectFromProvider = (
  identityProvider: IdentityProvider,
): IdentityProviderSignUpObject => {
  switch (identityProvider) {
    case "google":
      return "google_sign_up";

    case "apple":
      return "apple_sign_up";

    case "email":
      return "email_sign_up";

    case "facebook":
      return "facebook_sign_up";

    default:
      throw new Error(`Unknown Identity Provider: ${identityProvider}`);
  }
};

export type IdentityProviderSignUpCompletedEvent = {
  object: IdentityProviderSignUpObject;
  action: "completed";
  properties: {
    user_id: Id;
  };
};

const trackCreateUser =
  (track: Tracker["track"]) =>
  (identityProvider: IdentityProvider) =>
  (user: UserSelf) =>
    track({
      object: objectFromProvider(identityProvider),
      action: "completed",
      properties: {
        // eslint-disable-next-line camelcase
        user_id: user.id,
      },
    });

const SignUpOptions = ({
  credentials: identityCredentials,
  displayPhoneVerificationStep,
  goToPhoneVerification,
  onSuccess,
  onError,
  onLoginClick,
  onEmailClick,
  onSignUpError,
}: Props) => {
  const { t } = useTranslation("common");
  const { track } = useAnalytics();
  const [otpState, setOtpState] = useState<OtpState>(initialOtpState);
  const [sessionError, setSessionError] = useState<string | null>(null);

  const handleIdentityProviderSuccess = (credentials: Credentials) => {
    setSessionError(null);
    createSession(credentials)
      .then(onSuccess)
      .catch((error: SessionCreateError) => {
        switch (error.type) {
          case "UserNotFound":
            if (error.credentials.identityProvider !== "email") {
              onError(new UserNotFoundError(error.credentials));
            }

            break;

          case "OneTimePasswordRequired":
            onError(new OtpRequiredError(credentials));
            break;

          case "InvalidCredentials":
            setSessionError(error.message);
            break;

          case "Banned":
            setSessionError(BANNED);
            break;

          default:
            onError(new SignInError(credentials, error.message));
            break;
        }
      });
  };

  const handleIdentityProviderError = ({ message }: IdentityProviderError) => {
    if (message) setSessionError(message);
  };

  const onSessionsCreateResponse = (data: AuthenticationData) => {
    setOtpState({ ...otpState, isSubmitting: false, error: null });

    if (displayPhoneVerificationStep(data.user)) {
      goToPhoneVerification(data);
    } else {
      onSuccess(data);
    }
  };

  const onSessionsCreateError = (error: SessionCreateError) => {
    setOtpState({ ...otpState, isSubmitting: false });

    // TODO: can move these errors to otpState
    switch (error.type) {
      case "OneTimePasswordRequired":
        onSignUpError(new OtpRequiredError(error.credentials));
        break;

      default:
        onSignUpError(new SignUpError(error.credentials));
        break;
    }
  };

  const onUserCreateSuccess = (
    _user: UserSelf,
    credentials: IdentityCredentials,
  ): void => {
    setOtpState({ ...otpState, error: null });
    createSession(credentials)
      .then(onSessionsCreateResponse)
      .catch(onSessionsCreateError);
  };

  const onUserCreateError = (error: UserCreateError) => {
    setOtpState({ ...otpState, isSubmitting: false, error: error.message });
  };

  const createAccount = (credentials: IdentityCredentials) => {
    setOtpState({ ...otpState, isSubmitting: true });
    const { email, identityProvider, identityToken } = credentials;

    if (!email) {
      return setOtpState({ ...otpState, showEmailForm: true });
    }

    return createWithIdentity(email, identityProvider, identityToken)
      .then(tap(trackCreateUser(track)(identityProvider)))
      .then((user) => onUserCreateSuccess(user, credentials))
      .catch((error) => onUserCreateError(error));
  };

  useEffect(() => {
    if (identityCredentials) {
      createAccount(identityCredentials);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityCredentials]);

  const onEmailFormSubmit = (email: string) => {
    if (!identityCredentials) return;
    const { identityProvider, identityToken } = identityCredentials;
    setOtpState({ ...otpState, isSubmitting: true });
    track(buttonClicked(`${identityProvider}_sign_up`, "login"));
    createWithIdentity(email, identityProvider, identityToken)
      .then(tap(trackCreateUser(track)(identityProvider)))
      .then((user) => onUserCreateSuccess(user, identityCredentials))
      .catch((error) => onUserCreateError(error));
  };

  const { error: userError, showEmailForm } = otpState;
  const showError = !!sessionError || !!userError;

  return (
    <>
      {showError ? (
        <ErrorComponent>{sessionError || userError}</ErrorComponent>
      ) : null}

      {showEmailForm ? (
        <EmailForm
          isSubmitting={otpState.isSubmitting}
          onSubmit={onEmailFormSubmit}
        />
      ) : (
        <>
          <Message>
            By creating an account on {t("MARKETPLACE_NAME")} you&apos;ll be
            able to buy, sell, comment, and more.
          </Message>
          <FacebookLogin
            onSuccess={handleIdentityProviderSuccess}
            onError={handleIdentityProviderError}
            authType={SIGNUP}
          >
            Continue with Facebook
          </FacebookLogin>
          <AppleLogin
            onSuccess={handleIdentityProviderSuccess}
            onError={handleIdentityProviderError}
            authType={SIGNUP}
          >
            Continue with Apple
          </AppleLogin>
          <GoogleLogin
            onSuccess={handleIdentityProviderSuccess}
            onError={handleIdentityProviderError}
            authType={SIGNUP}
          />
          <Hr />
          <Button
            size="large"
            variant="primary"
            data-cy="signup-with-email-button"
            className={styles.authButton}
            onClick={onEmailClick}
          >
            Create Account with Email
          </Button>
          <Message>
            Already have an account?{" "}
            <a
              href="/users/sign_up"
              onClick={(event) => {
                event.preventDefault();
                onLoginClick(event);
              }}
            >
              Log in
            </a>
          </Message>
        </>
      )}
    </>
  );
};

export default SignUpOptions;
