/* eslint-disable max-classes-per-file */
export class InvalidTokenError extends Error {
  identityProvider = "google" as const;

  type = "InvalidToken" as const;
}

export class Unknown extends Error {
  identityProvider = "google" as const;

  type = "Unknown" as const;
}

export type GoogleIdentityProviderError = InvalidTokenError | Unknown;
