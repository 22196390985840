import React from "react";
import classnames from "classnames";
import TrackableMenuItem from "../../TrackableMenuItem";

type Props = {
  label: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClick: () => void;
  menswearDepartmentItemRef?: React.RefObject<HTMLHeadingElement>;
};

const DepartmentItem = ({
  label,
  onClick,
  isOpen,
  children,
  menswearDepartmentItemRef,
}: Props) => (
  <div
    className={classnames({
      "--item": true,
      _open: isOpen,
      "_has-children": !!children,
    })}
    id={`slide_out_menu_item_${label}`}
  >
    <span className="departmentLabel" ref={menswearDepartmentItemRef}>
      <TrackableMenuItem className="--label" onClick={onClick} label={label}>
        {label}
      </TrackableMenuItem>
    </span>

    <div className="--nested-sub-items">{children}</div>
  </div>
);

export default DepartmentItem;
