import React, { useState, useContext } from "react";
import SuspenseWithLoading from "storefront/components/SuspenseWithLoading";
import { Context as AnalyticsContext } from "storefront/hooks/useAnalytics";
import createSession, {
  Credentials,
  SessionCreateError,
} from "storefront/GrailedAPI/v1/Sessions/create";
import authenticationStarted from "storefront/Analytics/EventCreators/authenticationStarted";
import { AuthenticationError, OtpRequiredError, SignInError } from "../Error";
import { AuthenticationData } from "../Data";
import { Message } from "../Layout";
import SignInOptions from "./SignInOptions";
import SignInForm from "./SignInForm";
import { USER_NOT_FOUND, BANNED } from "./Errors";

type Props = {
  onSuccess: (data: AuthenticationData) => void;
  onError: (error: AuthenticationError) => void;
  onSignupClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

const SignInContent = ({ onSuccess, onError, onSignupClick }: Props) => {
  const [step, setStep] = useState("sign-in-options");
  const { track } = useContext(AnalyticsContext);

  const useSetStep = () => {
    track(authenticationStarted("email", "login"));
    setStep("sign-in-form");
  };

  const handleLogin = (data: AuthenticationData) => {
    onSuccess(data);
  };

  const onSubmit = (
    credentials: Credentials,
  ): Promise<AuthenticationData | void> =>
    createSession(credentials).catch((error: SessionCreateError) => {
      switch (error.type) {
        case "OneTimePasswordRequired":
          onError(new OtpRequiredError(error.credentials));
          break;

        case "UserNotFound":
          throw new Error(USER_NOT_FOUND);

        case "InvalidCredentials":
        case "NeedsPasswordReset":
          throw new Error(error.message);

        case "Banned":
          throw new Error(BANNED);

        default:
          onError(new SignInError(error.credentials));
          break;
      }
    });

  switch (step) {
    case "sign-in-options":
      return (
        <SuspenseWithLoading>
          <SignInOptions
            onSuccess={onSuccess}
            onError={onError}
            useSetStep={useSetStep}
            onSignupClick={onSignupClick}
          />
        </SuspenseWithLoading>
      );

    case "sign-in-form":
      return (
        <>
          <SuspenseWithLoading>
            <SignInForm
              submitText="Log In"
              onSubmit={onSubmit}
              onSubmitSuccess={handleLogin}
              onSignupClick={onSignupClick}
            />
          </SuspenseWithLoading>
          <Message>
            <a href="/users/password/new">Forgot your password?</a>
          </Message>
        </>
      );

    default:
      throw new Error("Something went wrong, you are in an unknown phase");
  }
};

export default SignInContent;
