import React from "react";

type Props = {
  className?: string;
  size?: number;
};

const MagnifyingGlass = ({ className, size = 15 }: Props) => (
  <svg
    className={className}
    height={`${size}px`}
    width={`${size}px`}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6762 14.1493C11.3618 15.1535 9.71933 15.75 7.9375 15.75C3.62278 15.75 0.125 12.2522 0.125 7.9375C0.125 3.62278 3.62278 0.125 7.9375 0.125C12.2522 0.125 15.75 3.62278 15.75 7.9375C15.75 9.71928 15.1535 11.3617 14.1494 12.6761L18.57 17.0967L17.0968 18.5699L12.6762 14.1493ZM13.6667 7.9375C13.6667 11.1016 11.1016 13.6667 7.9375 13.6667C4.77337 13.6667 2.20833 11.1016 2.20833 7.9375C2.20833 4.77337 4.77337 2.20833 7.9375 2.20833C11.1016 2.20833 13.6667 4.77337 13.6667 7.9375Z"
      fill="black"
    />
  </svg>
);

export default MagnifyingGlass;
