import React from "react";
import { over } from "lodash/fp";
import cn from "classnames";
import authenticationStarted, {
  AuthType,
} from "storefront/Analytics/EventCreators/authenticationStarted";
import { IdentityCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import useAnalytics from "storefront/hooks/useAnalytics";
import { Tracker } from "storefront/Analytics/Tracker";
import Button from "storefront/components/Button";
import AppleLogo from "storefront/components/Icons/AppleLogo";
import { completeIdentityProviderEvent } from "../CompleteIdentityProviderEvent";
import { failIdentityProviderEvent } from "../FailIdentityProviderEvent";
import useAppleLogin from "./useAppleLogin";
import { AppleIdentityProviderError } from "./Error";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../../Button.module.scss";

type Props = {
  onSuccess: (credentials: IdentityCredentials) => unknown;
  onError: (error: AppleIdentityProviderError) => void;
  children: React.ReactNode;
  className?: string;
  authType: AuthType;
};

const withTracking =
  (track: Tracker["track"], authType: AuthType) =>
  (f: (event: React.MouseEvent<HTMLButtonElement>) => void) =>
    over([() => track(authenticationStarted("apple", authType)), f]);

const Apple = ({
  onSuccess,
  onError,
  children,
  className,
  authType,
}: Props) => {
  const { track } = useAnalytics();
  const method = authType === "signup" ? "CreateAccount" : "LogIn";

  const onAppleLoginSuccess = (credentials: IdentityCredentials) => {
    track(completeIdentityProviderEvent(method, "apple"));
    onSuccess(credentials);
  };

  const onAppleLoginFailure = (error: AppleIdentityProviderError) => {
    track(failIdentityProviderEvent(method, error));
    onError(error);
  };

  const [attemptLogin, isProcessing] = useAppleLogin({
    onSuccess: onAppleLoginSuccess,
    onFailure: onAppleLoginFailure,
  });

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    attemptLogin();
  };

  return (
    <Button
      variant="primary"
      size="large"
      data-testid="apple-btn"
      className={cn(styles.authButton, styles.apple, className)}
      onClick={withTracking(track, authType)(onClick)}
      disabled={isProcessing}
    >
      <AppleLogo />
      {children}
    </Button>
  );
};

export default Apple;
