import React from "react";

type Props = {
  className?: string;
  size?: number;
};

const YoutubeLogo = ({ className, size = 15 }: Props) => (
  <svg
    className={className}
    height={`${size}px`}
    width={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 121.48 85.04"
  >
    <title>Youtube Logo</title>
    <path d="M119,13.28A15.23,15.23,0,0,0,108.21,2.54C98.73,0,60.74,0,60.74,0s-38,0-47.46,2.54A15.21,15.21,0,0,0,2.54,13.28C0,22.75,0,42.52,0,42.52S0,62.29,2.54,71.76A15.21,15.21,0,0,0,13.28,82.5C22.75,85,60.74,85,60.74,85s38,0,47.47-2.54A15.23,15.23,0,0,0,119,71.76c2.53-9.47,2.53-29.24,2.53-29.24S121.48,22.75,119,13.28ZM48.59,60.74V24.3L80.16,42.52Z" />
  </svg>
);

export default YoutubeLogo;
