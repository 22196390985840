import "./Modal.scss";
import React, { useEffect } from "react";

import ReactModal from "react-modal";
import classNames from "classnames";
import Clear from "storefront/components/Icons/Navigation/X";

export type Props = {
  children: React.ReactNode;
  className?: string;
  customClasses?: string;
  hideCloseButton?: boolean;
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: (arg0: React.SyntheticEvent<EventTarget>) => void;
  preventCloseOnClickOutside?: boolean;
};

const Modal = ({
  children,
  className,
  customClasses,
  hideCloseButton = false,
  isOpen,
  onAfterOpen,
  onRequestClose,
  preventCloseOnClickOutside = false,
}: Props) => {
  useEffect(() => {
    ReactModal.setAppElement(window.document.body);
  }, []);

  const handleCloseClick = (event: React.SyntheticEvent<EventTarget>) => {
    if (onRequestClose) onRequestClose(event);
  };

  return (
    <ReactModal
      className={classNames("modal", className)}
      overlayClassName={classNames("modal-overlay", customClasses)}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleCloseClick}
      shouldCloseOnOverlayClick={!preventCloseOnClickOutside}
      closeTimeoutMS={151}
      shouldCloseOnEsc
    >
      {!hideCloseButton ? (
        <a
          className="close"
          onClick={handleCloseClick}
          data-testid="modal_close_button"
        >
          <Clear />
        </a>
      ) : null}
      {children}
    </ReactModal>
  );
};

export default Modal;
