import algoliasearch, { SearchClient } from "algoliasearch";
import config from "storefront/GrailedAPI/v1/Config/getConfig";

/**
 * @name createInsecureClient
 * @memberof Algolia.Client
 * @deprecated
 */
const createInsecureClient = (): Promise<SearchClient> =>
  config().then(({ algolia }) =>
    algoliasearch(algolia.appId, algolia.publicSearchKey),
  );

export default createInsecureClient;
