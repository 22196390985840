import React from "react";
import TrackableMenuItem from "../TrackableMenuItem";

type MenuItemProps = {
  href: string;
  label: string;
  onClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

const MenuItem = ({ href, label, onClick }: MenuItemProps) => (
  <TrackableMenuItem
    href={href}
    className="--link"
    label={label}
    onClick={onClick}
  />
);

export default MenuItem;
