import React, { useState } from "react";
import uploadPhotoToS3, { UploadedPhoto } from "storefront/lib/uploadPhotoToS3";

type Props = {
  onSuccess(url: string): void;
  className?: string;
};

const PhotoUpload = ({ onSuccess, className }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSuccess = ({ url }: UploadedPhoto) => onSuccess(url);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);

    setIsLoading(true);

    uploadPhotoToS3(files[0])
      .then(handleSuccess)
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return <p className="-loading">Loading...</p>;
  }

  return (
    <input
      className={className}
      accept="image/*"
      type="file"
      onChange={onChange}
    />
  );
};

export default PhotoUpload;
