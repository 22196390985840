import { snakeCase } from "lodash/fp";
import { Department } from "storefront/Department";

export type NavigationClickedEvent = {
  object: "navigation";
  action: "clicked";
  properties: {
    from: string;
    name: string;
    path: string;
    deparment?: Department;
  };
};

/**
 * @description Creates a "navigation clicked" event that can be given to `track` to record the event with our third-party event tracking services.
 */

const navigationClicked = (
  name: string,
  from: string,
  deparment?: Department,
): NavigationClickedEvent => ({
  object: "navigation",
  action: "clicked",
  properties: {
    name: snakeCase(name),
    from,
    path: window.location.href,
    deparment,
  },
});

export default navigationClicked;
