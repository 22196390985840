import getPresignedPost, {
  UploadType,
} from "storefront/GrailedAPI/v1/Photos/getPresignedPost";
import { getImageSize, Size } from "./getImageSize";

export interface UploadedPhoto extends Size {
  url: string;
  filename: string;
  size: number;
  type: string;
  key: string;
}

async function uploadPhotoToS3(
  file: File,
  uploadType?: UploadType,
): Promise<UploadedPhoto> {
  const presignedPostData = await getPresignedPost(uploadType);
  const formData = new FormData();
  Object.keys(presignedPostData.fields).forEach((key) => {
    // success action status needs to be underscored
    if (key === "successActionStatus") {
      formData.append("success_action_status", presignedPostData.fields[key]);
      return;
    }
    formData.append(key, presignedPostData.fields[key]);
  });
  formData.append("Content-Type", file.type);
  // THIS MUST BE LAST OR THE UPLOAD WILL FAIL
  formData.append("file", file);

  try {
    const response = await fetch(presignedPostData.url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const responseText = await response.text();
      const errorMessage =
        response.headers.get("content-type") === "application/xml"
          ? new window.DOMParser()
              .parseFromString(responseText, "text/xml")
              .querySelector("Message")?.textContent
          : null;
      throw new Error(errorMessage || responseText);
    }
  } catch (error) {
    throw new Error(`Failed to upload file: ${error.message}`);
  }

  const { height, width } = await getImageSize(presignedPostData.imageUrl);

  return {
    url: presignedPostData.imageUrl,
    filename: presignedPostData.fields.key,
    size: file.size,
    type: file.type,
    key: presignedPostData.fields.key,
    height,
    width,
  };
}

export default uploadPhotoToS3;
