import { $PropertyType } from "utility-types";
import type { IdentityProvider } from "storefront/GrailedAPI/v1/Sessions/create";
import "storefront/GrailedAPI/v1/Sessions/create";
import type { Method } from "../Method";
import "../Method";
import type { IdentityProviderError } from "./Error";
import "./Error";

type Properties = {
  method: Method;
  identityProvider: IdentityProvider;
  reason: $PropertyType<IdentityProviderError, "type">;
};
export type FailIdentityProviderEvent = {
  object: "fail_identity_provider";
  action: "";
  properties: Properties;
};
export const failIdentityProviderEvent = (
  method: Method,
  error: IdentityProviderError,
): FailIdentityProviderEvent => ({
  object: "fail_identity_provider",
  action: "",
  properties: {
    method,
    identityProvider: error.identityProvider,
    reason: error.type,
  },
});
