import React from "react";
import onlyOnEnterKey from "../../../lib/onlyOnEnterKey";

type Props = {
  type: string;
  onClick: (arg0: React.SyntheticEvent<any>) => void;
};

/**
 * @name Basics.Field.Icons.HideableToggle
 * @private
 */
const HideableToggle: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({
  type,
  onClick,
}) => (
  <span
    className="--icon _toggle"
    onClick={onClick}
    onKeyPress={onlyOnEnterKey(onClick)}
    role="button"
    tabIndex={0}
  >
    {type === "password" ? "Show" : "Hide"}
  </span>
);

export default HideableToggle;
