import { $PropertyType } from "utility-types";
import React from "react";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import NestedRadio from "./NestedRadio";
import PhotoInput from "./PhotoInput";
import type { Props as InputProps } from "./Input";
import type { Props as SelectProps } from "./Select";
import type { Props as TextareaProps } from "./Textarea";
import type { Props as CheckboxProps } from "./Checkbox";
import type { Props as RadioProps } from "./Radio";
import type { Props as NestedRadioProps } from "./NestedRadio";
import type { Props as PhotoInputProps } from "./PhotoInput";

export type TypeValue =
  | $PropertyType<InputProps, "type">
  | $PropertyType<SelectProps, "type">
  | $PropertyType<TextareaProps, "type">
  | $PropertyType<RadioProps, "type">
  | $PropertyType<CheckboxProps, "type">
  | $PropertyType<NestedRadioProps, "type">
  | $PropertyType<PhotoInputProps, "type">;
export type Props =
  | SelectProps
  | TextareaProps
  | InputProps
  | CheckboxProps
  | RadioProps
  | NestedRadioProps
  | PhotoInputProps;

class UnsupportedTypeError extends Error {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  constructor(type) {
    super(`Field does not support type '${type}'.`);
  }
}
/**
 * @name Basics.Field.Control
 * @private
 * @description Based on the type prop, renders the correct control, either an <input>, a <select>,
 * or a <textarea>. In the case that a valid type is not given, throws an UnsupportedTypeError.
 */

const Control: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = (props) => {
  switch (props.type) {
    case "select":
      return (
        <Select
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          collection={props.collection}
          value={props.value}
          isDisabled={props.isDisabled}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyPress={props.onKeyPress}
        />
      );

    case "checkbox":
      return (
        <Checkbox
          type={props.type}
          isDisabled={props.isDisabled}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
      );

    case "radio":
      return (
        <Radio
          collection={props.collection}
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
      );

    case "textarea":
      return (
        <Textarea
          isDisabled={props.isDisabled}
          className={props.className}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          maxLength={props.maxLength}
          minLength={props.minLength}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyPress={props.onKeyPress}
          autoFocus={props.autoFocus}
        />
      );

    case "nested-radio":
      return (
        <NestedRadio
          type={props.type}
          collection={props.collection}
          name={props.name}
          value={props.value}
          isDisabled={props.isDisabled}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
      );

    case "photo-input":
      return (
        <PhotoInput
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          className={props.className}
          placeholder={props.placeholder}
          maxSize={props.maxSize}
          dimensions={props.dimensions}
        />
      );

    case "color":
    case "date":
    case "datetime-local":
    case "email":
    case "month":
    case "number":
    case "password":
    case "search":
    case "tel":
    case "text":
    case "time":
    case "url":
    case "week":
    case "file":
      return (
        <Input
          isDisabled={props.isDisabled}
          name={props.name}
          autoFocus={props.autoFocus}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          maxLength={props.maxLength}
          minLength={props.minLength}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyPress={props.onKeyPress}
          multiple={props.multiple}
          accept={props.accept}
        />
      );

    default:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'never'.
      throw new UnsupportedTypeError(props.type);
  }
};

export default Control;
