import { AuthenticationData } from "../Data";
import {
  GO_TO_EMAIL_AND_PASSWORD,
  GO_TO_PHONE_VERIFICATION,
  GO_TO_USERNAME,
  SIGNUP_EMAIL,
  SIGNUP_OPTIONS,
  SIGNUP_PHONE,
  SIGNUP_USERNAME,
  TOGGLE_WANTS_EMAIL,
  Action,
  SignupEmailStep,
  SignupOptionsStep,
  SignupPhoneStep,
  SignupUsernameStep,
  Step,
} from "./actions";

export const signupEmailStep = (wantsEmail: boolean): SignupEmailStep => ({
  type: SIGNUP_EMAIL,
  wantsEmail,
});

export const signupOptionsStep: SignupOptionsStep = {
  type: SIGNUP_OPTIONS,
};

export const initialStep: Step = signupOptionsStep;

export const signupPhoneStep = (
  sessionData: AuthenticationData,
): SignupPhoneStep => ({
  type: SIGNUP_PHONE,
  sessionData,
});

export const signupUsernameStep = (
  sessionData: AuthenticationData,
): SignupUsernameStep => ({
  type: SIGNUP_USERNAME,
  sessionData,
});

const reducer = (currentStep: Step, action: Action): Step => {
  switch (action.type) {
    case GO_TO_EMAIL_AND_PASSWORD:
      return currentStep.type !== SIGNUP_OPTIONS
        ? currentStep
        : signupEmailStep(false);

    case GO_TO_PHONE_VERIFICATION:
      return signupPhoneStep(action.payload.sessionData);

    case GO_TO_USERNAME:
      return currentStep.type !== SIGNUP_EMAIL
        ? currentStep
        : signupUsernameStep(action.payload.sessionData);

    case TOGGLE_WANTS_EMAIL:
      return currentStep.type !== SIGNUP_EMAIL
        ? currentStep
        : signupEmailStep(!currentStep.wantsEmail);

    default:
      return currentStep;
  }
};

export default reducer;
