import React from "react";

type Props = {
  className?: string;
  size?: number;
};

const FacebookLogo = ({ className, size = 15 }: Props) => (
  <svg
    className={className}
    height={`${size}px`}
    width={`${size}px`}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 0H1.66667C0.75 0 0 0.75 0 1.66667V13.3333C0 14.2508 0.75 15 1.66667 15H7.5V9.16667H5.83333V7.10417H7.5V5.39583C7.5 3.5925 8.51 2.32583 10.6383 2.32583L12.1408 2.3275V4.49833H11.1433C10.315 4.49833 10 5.12 10 5.69667V7.105H12.14L11.6667 9.16667H10V15H13.3333C14.25 15 15 14.2508 15 13.3333V1.66667C15 0.75 14.25 0 13.3333 0Z"
      fill="black"
    />
  </svg>
);

export default FacebookLogo;
