import React from "react";
import TrackableMenuItem from "../../TrackableMenuItem";
import authWrapper, {
  AuthWrapperProps,
} from "../../../../Authentication/authWrapper";

type Props = AuthWrapperProps;
export const GLOBAL_HEADER_LOGIN_ID = "global-header-login-btn";

const Login = ({ authenticated }: Props) => (
  <TrackableMenuItem
    href="/users/sign_up"
    onClick={(e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      authenticated(() => {}, {
        modalTrigger: "log_in_button",
        message: "log_in",
        verifications: new Set(["email"]),
      })();
    }}
    className="--button _small"
    label="Login"
    data-testid="login-btn"
    data-cy="login-btn"
    id={GLOBAL_HEADER_LOGIN_ID}
  >
    Login
  </TrackableMenuItem>
);

export default authWrapper(Login);
