import { compose } from "lodash/fp";
import imageSrc, {
  SrcParams,
} from "storefront/components/Image/imageSrc/index";
import type { GlobalState } from "../../../GlobalState";
import { session } from "../../../GlobalState";
import { currentUser } from "../../../Session";
import { avatarUrl } from "../../../User";

const fallbackSrc =
  "https://media-assets.grailed.com/prd/misc/PVEFHQYACL92VZTZETOTVQNFENXZW7S5U0MLUG";

export const urlToSrc = (
  url: string | null | undefined,
  params: SrcParams = { height: 70, width: 70 },
): string => {
  if (!url) return fallbackSrc;
  return imageSrc(url, params);
};

const imageUrlSelector: (arg0: GlobalState) => string = compose([
  urlToSrc,
  avatarUrl,
  currentUser,
  session,
]);
export default imageUrlSelector;
