import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserSelf } from "storefront/User";
import hasNotificationsSelector from "../../../hasNotificationsSelector";
import activeHeader from "../../activeHeader";
import SubItem from "../../SubItem";

export const MESSAGES_CLICKED =
  "GlobalHeader.SlideOutMenu.Items.Account.SubItems.Messages.CLICKED";

type TrackNav = (name: string) => void;

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Messages
 */
export const Messages = ({ trackNav }: { trackNav: TrackNav }) => {
  const hasNotifications = useSelector(hasNotificationsSelector);
  const dispatch = useDispatch();

  const onClick = () => {
    trackNav("Messages");
    dispatch({
      type: MESSAGES_CLICKED,
      payload: {},
    });
  };

  return (
    <SubItem
      label="Messages"
      href="/messages"
      isActive={activeHeader() === "messages"}
      hasNotifications={hasNotifications}
      onClick={onClick}
    />
  );
};

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.MyGrails
 */
export const MyGrails = ({
  currentUser,
  trackNav,
}: {
  currentUser: UserSelf;
  trackNav: TrackNav;
}) => {
  const { t } = useTranslation("common");

  return (
    <SubItem
      label={t("MY_GRAILS_LINK_TEXT")}
      href={`${t("MY_GRAILS_LINK_PATH")}/${currentUser.id}`}
      isActive={activeHeader() === t("MY_GRAILS_LINK_TEXT")}
      onClick={() => {
        trackNav("My Grails");
      }}
    />
  );
};

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Purchases
 */
export const Purchases = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Purchases"
    href="/users/purchases"
    isActive={activeHeader() === "purchases"}
    onClick={() => {
      trackNav("Purchases");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.ForSale
 */
export const ForSale = ({ trackNav }: { trackNav: (name: string) => void }) => (
  <SubItem
    label="For Sale"
    href="/sell/for-sale"
    isActive={activeHeader() === "for-sale"}
    onClick={() => {
      trackNav("For Sale");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Sold
 */
export const Sold = ({ trackNav }: { trackNav: (name: string) => void }) => (
  <SubItem
    label="Sold"
    href="/sell/sold"
    isActive={activeHeader() === "sold"}
    onClick={() => {
      trackNav("Sold");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Drafts
 */
export const Drafts = ({ trackNav }: { trackNav: (name: string) => void }) => (
  <SubItem
    label="Drafts"
    href="/sell/drafts"
    isActive={activeHeader() === "drafts"}
    onClick={() => {
      trackNav("Drafts");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Feedback
 */
export const Feedback = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Feedback"
    href="/sell/feedback"
    isActive={activeHeader() === "feedback"}
    onClick={() => {
      trackNav("Feedback");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.VacationMode
 */
export const VacationMode = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Vacation Mode"
    href="/sell/vacation-mode"
    isActive={activeHeader() === "vacation-mode"}
    onClick={() => {
      trackNav("Vacation Mode");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Settings
 */
export const Settings = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Settings"
    href="/sell/settings"
    isActive={activeHeader() === "settings"}
    onClick={() => {
      trackNav("Settings");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.MyProfile
 */
export const MyProfile = ({ trackNav }: { trackNav: TrackNav }) => {
  return (
    <SubItem
      label="Profile Settings"
      href="/users/myprofile"
      isActive={activeHeader() === "myprofile"}
      onClick={() => {
        trackNav("My Profile");
      }}
    />
  );
};

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.MyAddresses
 */
export const MyAddresses = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Addresses"
    href="/users/myaddresses"
    isActive={activeHeader() === "myaddresses"}
    onClick={() => {
      trackNav("My Addresses");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.MySizes
 */
export const MySizes = ({ trackNav }: { trackNav: (name: string) => void }) => (
  <SubItem
    label="My Sizes"
    href="/users/mysizes"
    isActive={activeHeader() === "mysizes"}
    onClick={() => {
      trackNav("My Sizes");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Notifications
 */
export const Notifications = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Notifications"
    href="/users/notifications"
    isActive={activeHeader() === "notifications"}
    onClick={() => {
      trackNav("Notifications");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Payments
 */
export const Payments = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Payments"
    href="/users/payments"
    isActive={activeHeader() === "payments"}
    onClick={() => {
      trackNav("Payments");
    }}
  />
);

/**
 * @name GlobalHeader.SlideOutMenu.Items.Account.SubItems.Devices
 */
export const Devices = ({ trackNav }: { trackNav: TrackNav }) => (
  <SubItem
    label="Devices"
    href="/users/devices"
    isActive={activeHeader() === "devices"}
    onClick={() => {
      trackNav("Devices");
    }}
  />
);
