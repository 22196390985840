import React, { useContext } from "react";
import { SubmissionError } from "redux-form";
import updateUser from "storefront/GrailedAPI/v1/Users/update";
import { Context as AnalyticsContext } from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import { Tracker } from "storefront/Analytics/Tracker";
import { User } from "storefront/User";
import { Id } from "storefront/lib/Id";
import UsernameForm, { Values } from "./UsernameForm";

const USERNAME_TAKEN_ERROR = "Username has already been taken";
const USERNAME_OFFENSIVE_ERROR =
  "Username is invalid. Please don't use offensive language.";

type Props = {
  userId: Id;
  onSuccess: () => void;
};

const createUsername =
  (track: Tracker["track"]) =>
  (username: string, userId: Id): Promise<User | void> => {
    track(buttonClicked("create_username", "signup"));
    return updateUser(userId, {
      username,
    }).catch(({ body }) => {
      if (body.error.message === USERNAME_TAKEN_ERROR) {
        throw new SubmissionError({
          username: "Username is taken.",
        });
      } else if (body.error.message === USERNAME_OFFENSIVE_ERROR) {
        throw new SubmissionError({
          username: "Please don't use offensive words.",
        });
      }
    });
  };

const Username = ({ userId, onSuccess }: Props) => {
  const { track } = useContext(AnalyticsContext);
  return (
    <UsernameForm
      onSubmit={(values: Values) =>
        createUsername(track)(values.username, userId)
      }
      onSubmitSuccess={onSuccess}
    />
  );
};

export default Username;
