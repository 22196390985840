import { useLayoutEffect } from "react";

/**
 * @description Hook which applies styles to the body to prevent it from scrolling,
 * useful when opening modals and such
 * There are some things in here that specifically account for special behavior in Safari iOS
 * To learn more about scroll locking see this:
 * https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 * If you wondering what useLayoutEffect does see this:
 * https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
 */
const useWindowScrollLock = (lock: boolean) => {
  useLayoutEffect(() => {
    if (lock) {
      window.document.body.style.top = `-${window.scrollY}px`;
      window.document.body.style.position = "fixed";
    } else {
      const scrollY = window.document.body.style.top;
      window.document.body.style.position = "";
      window.document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    }
  }, [lock]);
};

export default useWindowScrollLock;
