/**
 * @description This is a neat little helper for handling keyboard events. Let's say you've got a
 * thing and you only want the thing to happen when the enter key is pressed. Don't put that logic
 * in the event handler! That's gonna get messy and it's a poor separation of concerns. Instead,
 * just wrap your handler with this little helper higher-order function and you'll have a much
 * better time. Enjoy!
 */
const onlyOnEnterKey: (
  arg0: (arg0: React.SyntheticEvent<any>) => void,
) => (arg0: React.KeyboardEvent<any>) => void = (fn) => (event) =>
  event.key === "Enter" ? fn(event) : undefined;

export default onlyOnEnterKey;
