import { createContext } from "react";

export type Value = {
  isExpanded: boolean;
  toggleExpanded: () => void;
  setExpanded: (arg0: boolean) => void;
};
const MenuExpandedContext = createContext<Value>({
  isExpanded: false,
  toggleExpanded: () => {},
  setExpanded: () => {},
});
export default MenuExpandedContext;
