import { IdentityProvider } from "storefront/GrailedAPI/v1/Sessions/create";
import { AuthenticationData } from "../Data";
import { Method, LogIn, CreateAccount } from "./Method";

type LogInProperties = {
  method: LogIn;
  identityProvider: IdentityProvider;
};

type CreateAccountProperties = {
  method: CreateAccount;
  identityProvider: IdentityProvider;
  emailOptIn?: boolean;
};

type Properties = LogInProperties | CreateAccountProperties;

export type CompleteAuthenticateUserEvent = {
  object: "complete_authenticate_user";
  action: "";
  properties: Properties;
};

export const completeAuthenticateUserEvent = (
  method: Method,
  data: AuthenticationData,
): CompleteAuthenticateUserEvent => {
  switch (method) {
    case "LogIn": {
      return {
        object: "complete_authenticate_user",
        action: "",
        properties: {
          method: "LogIn",
          identityProvider: data.credentials.identityProvider,
        },
      };
    }

    case "CreateAccount": {
      const emailOptIn =
        data.credentials.identityProvider === "email"
          ? data.credentials.optInEmails
          : false;
      return {
        object: "complete_authenticate_user",
        action: "",
        properties: {
          method: "CreateAccount",
          identityProvider: data.credentials.identityProvider,
          emailOptIn,
        },
      };
    }

    default:
      throw new Error(`Unknown Method: ${method}`);
  }
};
