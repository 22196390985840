import { camelCase } from "camelscore";

import useData from "storefront/hooks/data/useData";
import getFlipperFeatures from "storefront/GrailedAPI/v1/Users/Flipper/getFeatures";
import { Id } from "storefront/lib/Id";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";

/**
 * Custom hook to retrieve a feature flag value based on a given key.
 *
 * @param key - The key of the feature flag.
 * @param userId - Optional user ID to retrieve feature flag values on behalf of another user (logged in user must be admin).
 * @returns A resource object containing the feature flag value.
 * @throws {Error} If the `key` parameter contains a comma (`,`) character.
 */
export default function useFeatureFlag(key: Feature, userId?: Id) {
  if (key.includes(","))
    throw new Error("useFeatureFlag hook only supports one key");

  const arg: Parameters<typeof getFlipperFeatures>[0] = {
    keys: [key],
  };

  if (userId) arg.userId = userId;

  const resource = useData(getFlipperFeatures, {
    args: [arg],
    key: `useFeatureFlag-${camelCase(key)}`,
  });

  if (resource.type === "Completed") {
    return {
      ...resource,
      value: resource.value?.[camelCase(key)]?.enabled ?? false,
    };
  }
  return resource;
}
