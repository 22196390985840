import React from "react";
import { useTranslation } from "react-i18next";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import useAnalytics from "storefront/hooks/useAnalytics";
import ButtonLink from "storefront/components/ButtonLink";
import LinkButton from "storefront/components/LinkButton";
import Body from "storefront/components/Body";
import Callout from "storefront/components/Callout";
import css from "./VerifyEmail.module.scss";

const LETTER_ICON_SRC =
  "https://media-assets.grailed.com/prd/misc/3HR3UYWDCAO1S3SZFCLGRCWBNATFXIKHX3OQPA";

type Props = {
  email: string;
  onResendClick: () => unknown;
};

const Prompt = ({ email, onResendClick }: Props) => {
  const { t } = useTranslation("common");
  const { track } = useAnalytics();

  return (
    <div className={css.root}>
      <img alt="Open Letter" className={css.icon} src={LETTER_ICON_SRC} />

      <Body className={css.body}>
        A confirmation email was sent to <strong>{email}</strong>.
      </Body>

      <Body className={css.body}>
        Please check your inbox. Once you confirm your email, you will have full
        access to {`${t("MARKETPLACE_NAME")}`}.
      </Body>

      <ButtonLink
        className={css.button}
        size="large"
        variant="primary"
        href="/"
        onClick={() => {
          track(buttonClicked("shop_the_feed", "signup"));
        }}
      >
        Shop the Feed
      </ButtonLink>

      <Callout className={css.body}>
        Didn&#39;t receive an email?{" "}
        <LinkButton className={css.link} onClick={onResendClick}>
          Resend Email
        </LinkButton>
      </Callout>
    </div>
  );
};

export default Prompt;
