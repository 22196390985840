/* eslint-disable import/prefer-default-export */
export function safelyParse<T>(
  json: string | null | undefined,
): T | null | undefined {
  try {
    return JSON.parse(json || "");
  } catch (e) {
    return null;
  }
}
