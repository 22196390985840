import { User } from "storefront/User";
import { Verification } from "./Verification";

export type Unknown = {
  type: "Unknown";
};
export const unknown: Unknown = {
  type: "Unknown",
};

export type UserAuthentication = {
  type: "UserAuthentication";
};
export const userAuthentication: UserAuthentication = {
  type: "UserAuthentication",
};

export type EmailVerification = {
  type: "EmailVerification";
  isAfterSignIn: boolean;
  email: string;
};
export const emailVerification = (
  email: string,
  isAfterSignIn = false,
): EmailVerification => ({
  type: "EmailVerification",
  isAfterSignIn,
  email,
});

export type Need = Unknown | UserAuthentication | EmailVerification;

export const fromUserAndVerifications = (
  maybeUser: User | void,
  verifications: Set<Verification>,
  isAfterSignIn = false,
): Need | null => {
  if (!maybeUser || !maybeUser.id || maybeUser.id === -1) {
    return userAuthentication;
  }

  if (verifications.has("email") && !maybeUser.isConfirmed) {
    return emailVerification(maybeUser.email, isAfterSignIn);
  }

  return null;
};
