import React, { useEffect, useState } from "react";
import { sortBy } from "lodash/fp";
import { Resource, completed, failed, loading } from "storefront/lib/Resource";
import { Designer } from "storefront/Designer";
import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import fetchBySlugs from "storefront/Algolia/Designers/fetchBySlugs";
import GrailedAPIError from "storefront/GrailedAPI/Error";
import Subcategory from "./Subcategory";

import styles from "./Dropdown.module.scss";

const FAVORITE_DESIGNER_SLUGS = [
  "acne-studios",
  "adidas",
  "alexander-mcqueen",
  "amiri",
  "balenciaga",
  "bape",
  "bottega-veneta",
  "celine",
  "chanel",
  "chrome-hearts",
  "comme-des-garcons",
  "dior",
  "dries-van-noten",
  "fear-of-god",
  "gucci",
  "jacquemus",
  "kapital",
  "louis-vuitton",
  "maison-margiela",
  "nike",
  "number-n-ine",
  "off-white",
  "prada",
  "raf-simons",
  "rick-owens",
  "saint-laurent-paris",
  "stone-island",
  "supreme",
  "undercover",
  "vintage",
  "loewe",
];

type Props = { className?: string };

const Dropdown = ({ className = "" }: Props) => {
  const { track } = useAnalytics();
  const [designers, setDesigners] =
    useState<Resource<Array<Designer>>>(loading);

  useEffect(() => {
    fetchBySlugs(FAVORITE_DESIGNER_SLUGS)
      .then((d) => {
        const sortedByName = sortBy<Designer>("name")(d);
        setDesigners(completed(sortedByName));
      })
      .catch((e: GrailedAPIError) => setDesigners(failed(e)));
  }, []);

  if (designers.type !== "Completed") return <></>;

  const trackClick = (name: string) => {
    const from = "sub_header_designer_dropdown";
    track(buttonClicked(name, from));
    track(navigationClicked(name, from));
  };

  return (
    <div className={className}>
      <div className={styles.title}>
        <a className={styles.subcategoriesTitle} href="/designers">
          Designers A-Z
        </a>
      </div>
      <div className={styles.subcategories}>
        {designers.value.map((d) => (
          <Subcategory
            href={`/designers/${d.slug}`}
            name={d.name}
            onClick={() => trackClick(d.name)}
            key={d.id}
            className={styles.subcategory}
          />
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
