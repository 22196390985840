import { useEffect } from "react";
import useScript, { ScriptStatus } from "@charlietango/use-script";

import {
  InvalidTokenError,
  Unknown as UnknownGoogleError,
} from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/Google/Error";

import { getInstance } from "storefront/lib/Sentry";
import { IdentityCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import { IdentityProviderError } from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/Error";
import { Result } from "storefront/lib/Result";
import * as JWT from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/Google/JWT";
import isFailure from "storefront/lib/Result/isFailure";
import map from "storefront/lib/Result/map";
import usePublicConfig from "storefront/hooks/usePublicConfig";

type GooglePayload = {
  email: string;
};

const Sentry = getInstance();

const toCredentials = ({
  credential,
}: google.accounts.id.CredentialResponse): Result<
  Error,
  IdentityCredentials
> => {
  const result = JWT.parse<GooglePayload>(credential);

  return map<Error, GooglePayload, IdentityCredentials>(
    (payload: GooglePayload): IdentityCredentials => ({
      identityProvider: "google",
      identityToken: credential,
      email: payload.email,
    }),
  )(result);
};

export const GOOGLE_MISSING_ERROR = new Error("google is missing in window");
export const GOOGLE_FAILED_LOAD = new Error(
  "Google JavaScript API failed to load.",
);

const SCRIPT_URL = "https://accounts.google.com/gsi/client";

type Props = {
  buttonRef: React.RefObject<HTMLDivElement>;
  onClick: () => void;
  onSuccess: (credentials: IdentityCredentials) => void;
  onError: (error: IdentityProviderError) => void;
};

const useGoogleLogin = ({
  onClick,
  onSuccess,
  onError,
  buttonRef,
}: Props): void => {
  const { googleOauthClientId } = usePublicConfig();
  const [scriptReady, scriptStatus] = useScript(SCRIPT_URL);

  useEffect(() => {
    const onGoogleAuthenticationComplete = (
      res: google.accounts.id.CredentialResponse,
    ) => {
      const result = toCredentials(res);
      if (isFailure(result)) onError(new InvalidTokenError());
      else onSuccess(result.value);
    };

    if (!googleOauthClientId) return;

    if (scriptStatus === ScriptStatus.ERROR) throw GOOGLE_FAILED_LOAD;

    if (!scriptReady) return;

    if (!window.google) throw GOOGLE_MISSING_ERROR;

    if (!buttonRef.current) return;

    try {
      google.accounts.id.initialize({
        client_id: googleOauthClientId,
        callback: onGoogleAuthenticationComplete,
      });

      google.accounts.id.renderButton(buttonRef.current, {
        type: "standard",
        text: "continue_with",
        // https://developers.google.com/identity/gsi/web/reference/js-reference#width
        // google docs type "width" to a "string"
        // however it looks like the code is expecting a "number"
        // https://github.com/metabase/metabase/issues/32602#issuecomment-1648587499
        // @ts-ignore
        width: buttonRef.current.offsetWidth,
        click_listener: onClick,
      });
    } catch (error) {
      // pass exception to Sentry to track issue
      Sentry.captureException(error);
      onError(new UnknownGoogleError());
    }
  }, [
    buttonRef,
    googleOauthClientId,
    onClick,
    onError,
    onSuccess,
    scriptReady,
    scriptStatus,
  ]);
};

export default useGoogleLogin;
