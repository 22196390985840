import { useState, useEffect } from "react";
import useScript, { ScriptStatus } from "@charlietango/use-script";
import type { IdentityCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import "storefront/GrailedAPI/v1/Sessions/create";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import appleInit from "./init";
import type {
  Success as AppleSignInSuccess,
  Failure as AppleSignInFailure,
} from "./signIn";
import appleSignIn from "./signIn";
import type { AppleIdentityProviderError } from "./Error";
import { fromAppleSignInFailure } from "./Error";

type Options = {
  onSuccess: (credentials: IdentityCredentials) => unknown;
  onFailure: (error: AppleIdentityProviderError) => unknown;
};

const toCredentials = ({
  user,
  authorization,
}: AppleSignInSuccess): IdentityCredentials => ({
  identityProvider: "apple",
  identityToken: authorization.id_token,
  email: user?.email,
});

const SCRIPT_URL =
  "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";

const useAppleLogin = ({
  onSuccess,
  onFailure,
}: Options): [() => void, boolean] => {
  const [scriptReady, scriptStatus] = useScript(SCRIPT_URL);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const publicConfig = usePublicConfig();
  useEffect(() => {
    if (!publicConfig) return;

    if (scriptStatus === ScriptStatus.ERROR) {
      console.error("AppleJs failed to load.");
      return;
    }

    if (!scriptReady) return;
    appleInit(publicConfig);
  }, [scriptReady, scriptStatus, publicConfig]);

  const onError = (res: AppleSignInFailure) => {
    if (onFailure) onFailure(fromAppleSignInFailure(res));
  };

  const onComplete = () => setIsProcessing(false);

  const attemptLogin = () => {
    if (!scriptReady || isProcessing) return;
    setIsProcessing(true);
    appleSignIn()
      .then(toCredentials)
      .then(onSuccess)
      .catch(onError)
      .finally(onComplete);
  };

  return [attemptLogin, isProcessing || !scriptReady];
};

export default useAppleLogin;
