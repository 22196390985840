import {
  HighPriorityAttributes,
  ImageProps,
  ImageTransformer,
  LowPriorityAttributes,
} from "./types";

export function getPriorityAttrs(
  priority?: boolean,
): HighPriorityAttributes | LowPriorityAttributes {
  // High priority images should be loaded eagerly
  if (priority) {
    return {
      loading: "eager",
      decoding: "auto",
      fetchPriority: "high",
    };
  }

  // Otherwise use native lazy loading and async decoding
  return {
    loading: "lazy",
    decoding: "async",
    fetchPriority: "low",
  };
}

export const DEFAULT_RESOLUTIONS = [480, 770, 1024, 1280, 1600, 1920, 2560];

export function getSrcSet({
  src,
  widths,
  transformer,
  quality,
  format,
  additionalCDNOptions,
  heightRatio,
  rotate,
}: Pick<ImageProps, "src" | "quality" | "additionalCDNOptions" | "rotate"> & {
  heightRatio?: number;
  widths: number[];
  transformer: ImageTransformer;
  format?: "webp" | "jpg" | "png";
}): string {
  return widths
    .sort((a, b) => a - b)
    .map((width) => {
      const height = heightRatio ? Math.round(width * heightRatio) : undefined;
      const url = transformer({
        url: src,
        width,
        height,
        quality,
        additionalCDNOptions,
        format,
        rotate,
      });
      return `${url} ${width}w`;
    })
    .join(",\n");
}

export function getDPRSrcSet({
  src,
  width,
  height,
  transformer,
  format,
  additionalCDNOptions,
  rotate,
  quality,
}: Pick<
  ImageProps,
  "src" | "additionalCDNOptions" | "rotate" | "width" | "height" | "quality"
> & {
  transformer: ImageTransformer;
  format?: "webp" | "jpg" | "png";
}): string | undefined {
  if (!width && !height) {
    return undefined;
  }
  return [1, 2]
    .map((dpr) => {
      const url = transformer({
        url: src,
        width: width ? width * dpr : undefined,
        height: height ? height * dpr : undefined,
        quality: quality || (dpr === 1 ? 40 : 20),
        additionalCDNOptions,
        format,
        rotate,
      });
      return `${url} ${dpr}x`;
    })
    .join(",\n");
}

type GetStyleAttrProps = Pick<
  ImageProps,
  "fit" | "align" | "rotate" | "fill" | "width" | "height"
>;

function toPx(value?: number) {
  return value || value === 0 ? `${value}px` : undefined;
}

export function getStyleAttr({
  fit,
  align,
  rotate,
  fill,
  width,
  height,
}: GetStyleAttrProps): React.CSSProperties {
  const styleAttrs: React.CSSProperties = {};

  if (fill) {
    styleAttrs.position = "absolute";
    styleAttrs.height = "100%";
    styleAttrs.width = "100%";
    styleAttrs.left = 0;
    styleAttrs.top = 0;
    styleAttrs.right = 0;
    styleAttrs.bottom = 0;
  } else {
    if (width) {
      styleAttrs.maxWidth = toPx(width);
    } else {
      styleAttrs.maxWidth = "100%";
      styleAttrs.width = "auto";
    }

    if (height) {
      styleAttrs.maxHeight = toPx(height);
    } else {
      styleAttrs.maxHeight = "100%";
      styleAttrs.height = "auto";
    }
  }

  if (fit === "crop") {
    styleAttrs.objectFit = "cover";
  }

  if (fit === "clip") {
    styleAttrs.objectFit = "contain";
  }

  if (fit === "scale") {
    styleAttrs.objectFit = "fill";
  }

  if (align) {
    styleAttrs.objectPosition = align;
  }

  if (rotate) {
    styleAttrs.transform = `rotate(${rotate}deg)`;
  }

  return styleAttrs;
}

export function scaleImageToMaxHeight(
  width: number,
  height: number,
  maxHeight: number,
) {
  if (height <= maxHeight) {
    // Photo already fits within the maxHeight, no need to scale down
    return { width, height };
  }
  const scaleFactor = maxHeight / height; // Calculate the scaling factor
  const newHeight = maxHeight;
  const newWidth = Math.round(width * scaleFactor);
  return { width: newWidth, height: newHeight };
}
