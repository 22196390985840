import React from "react";
import cn from "classnames";
import onEnterAndSpace from "storefront/lib/onEnterAndSpace";
import Callout from "storefront/components/Callout";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Tooltip.module.scss";

type Props = {
  hidden: boolean;
  onClick: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  onTop?: boolean;
  position?: "center" | "left" | "right";
  children: React.ReactNode;
  className?: string;
};

const Tooltip = ({
  hidden,
  onClick,
  position = "right",
  onTop,
  children,
  className,
}: Props) => (
  <div
    className={cn(
      className,
      styles.onboardingTooltip,
      onTop ? styles.top : styles.bottom,
      styles[position],
      {
        [styles.hidden]: hidden,
      },
    )}
    onClick={onClick}
    onKeyPress={onEnterAndSpace(onClick)}
    role="presentation"
  >
    <Callout>{children}</Callout>
  </div>
);

export default Tooltip;
