import { useState } from "react";
import { compose } from "storefront/lib/Function";
import GrailedAPIError from "storefront/GrailedAPI/Error";

type Executor<T> = (...args: T[]) => void;
type Waiting = {
  type: "Waiting";
};
type Submitting = {
  type: "Submitting";
};
type Completed<T> = {
  type: "Completed";
  value: T;
};
type Failed = {
  type: "Failed";
  error: GrailedAPIError;
};
const waiting: Waiting = {
  type: "Waiting",
};
const submitting: Submitting = {
  type: "Submitting",
};
export function completed<T>(value: T): Completed<T> {
  return {
    type: "Completed",
    value,
  };
}

function failed(error: GrailedAPIError): Failed {
  return {
    type: "Failed",
    error,
  };
}

export type SubmitState<T> = Waiting | Submitting | Completed<T> | Failed;

/**
 * @name useSubmitState
 * @memberof Hooks
 * @description A React Hook that takes a function returning the Promise of a resource and returns a function to fetch the resource and the state of the fetch.
 * */
function useSubmitState<R, A>(
  fn: (...args: A[]) => Promise<R>,
): [Executor<A>, SubmitState<R>] {
  const [state, setState] = useState(waiting);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '<T>(value: T) => Completed<T>' i... Remove this comment to see the full error message
  const setCompleted = compose(setState)(completed);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(error: GrailedAPIError) => Fail... Remove this comment to see the full error message
  const setFailed = compose(setState)(failed);

  const executor = (...args: A[]): void => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Submitting' is not assignable to... Remove this comment to see the full error message
    setState(submitting);
    fn(...args)
      .then(setCompleted)
      .catch(setFailed);
  };

  return [executor, state];
}

export default useSubmitState;
