import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import myGrailsStateFromWindow from "storefront/Onboarding/myGrailsStateFromWindow";
import type { GlobalState } from "storefront/GlobalState";
import { compose } from "../../../lib/Function";
import { onboarding } from "../../../GlobalState";
import getStateForFlow from "../../../Onboarding/getStateForFlow";
import type { State } from "../../../Onboarding/State";
import { started } from "../../../Onboarding/State";
import Tooltip from "../Tooltip";
import { listingTooltipClickedActionCreator } from "./ListingTooltipClickedAction";
import { tooltipMountedActionCreator } from "./TooltipMountedAction";

const onboardingStateSelector = compose(getStateForFlow("myGrails"))(
  onboarding,
);
type Props = {
  className?: string;
  isCurrentUserSeller?: boolean;
};

const AddGrailsTooltip = ({
  className,
  isCurrentUserSeller = false,
}: Props) => {
  const dispatch = useDispatch();
  const onboardingState = useSelector<GlobalState, State>(
    onboardingStateSelector,
  );

  useEffect(() => {
    const myGrailsState = myGrailsStateFromWindow(window);
    dispatch(tooltipMountedActionCreator(myGrailsState));
  }, [dispatch]);

  const handleClick = compose(dispatch)(listingTooltipClickedActionCreator);
  const isHidden = isCurrentUserSeller || onboardingState !== started;

  return (
    <Tooltip hidden={isHidden} onClick={handleClick} className={className}>
      Get notified if this item drops in price by adding it to
      &quot;Favorites&quot;
    </Tooltip>
  );
};

export default AddGrailsTooltip;
