import React from "react";

type Props = {
  className?: string;
  size?: number;
};

const LinkedinLogo = ({ className, size = 15 }: Props) => (
  <svg
    className={className}
    height={`${size}px`}
    width={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <title>LinkedIn Logo</title>
    <path d="M66.69,0H5.32A5.32,5.32,0,0,0,0,5.32V66.68A5.32,5.32,0,0,0,5.32,72H66.69A5.32,5.32,0,0,0,72,66.68V5.32A5.32,5.32,0,0,0,66.69,0ZM21.46,61.34H10.64V27H21.46ZM16.05,22.19A6.13,6.13,0,0,1,9.81,16a6.12,6.12,0,0,1,6.24-6.21A6.13,6.13,0,0,1,22.29,16,6.14,6.14,0,0,1,16.05,22.19ZM61.37,61.36H50.54V42.58c0-5.54-2.35-7.25-5.39-7.25-3.21,0-6.36,2.42-6.36,7.39V61.36H28V27H38.37v4.76h.14A11.8,11.8,0,0,1,48.8,26c6,0,12.57,3.58,12.57,14.09Z" />
  </svg>
);

export default LinkedinLogo;
