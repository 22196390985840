import { snakeCase } from "lodash/fp";

export type MicrositeViewedEvent = {
  object: "microsite";
  action: "viewed";
  properties: {
    from: string;
    name: "authentication_microsite";
  };
};

const trustMicrositeViewed = (from: string): MicrositeViewedEvent => ({
  object: "microsite",
  action: "viewed",
  properties: {
    from: snakeCase(from),
    name: "authentication_microsite",
  },
});

export default trustMicrositeViewed;
