import React, { useState, useRef, useEffect } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { SubmitState } from "storefront/hooks/useSubmitState";
import { Response } from "storefront/GrailedAPI/v1/Users/Verification/sendVerificationCode";
import checkVerificationCode from "storefront/GrailedAPI/v1/Users/Verification/checkVerificationCode";
import BasicWrapper from "storefront/components/Field/BasicWrapper";
import TextControl from "storefront/components/Field/TextControl";
import { Country } from "storefront/Country";
import { Lookup } from "storefront/Twilio/Lookup";
import Button from "storefront/components/Button";
import styles from "./CodeInputs.module.scss";

type Props = {
  country: Country;
  error?: string;
  handleSuccess: (e: React.SyntheticEvent<HTMLElement>) => void;
  lookupData: Lookup | Record<string, never>;
  phoneNumber: string;
  sendCode: (
    via: "sms" | "call",
    country: Country,
    phoneNumber: string,
  ) => void;
  sendCodeState: SubmitState<Response>;
};

type Value = Array<string>;
const emptyValue: Value = ["", "", "", ""];

const clearDigit =
  (index: number) =>
  (value: Value): Value => {
    const newValue = [...value];
    newValue[index] = "";
    return newValue;
  };

const updateDigits =
  (index: number, digits: string) =>
  (value: Value): Value => {
    const newValue = [...value];
    const numInputs = value.length;
    digits
      .slice(0, numInputs - index)
      .split("")
      .forEach((char, charIndex) => {
        newValue[index + charIndex] = char;
      });
    return newValue;
  };

const isComplete = (value: Value): boolean =>
  value.every((current) => !!current);

const toString = (value: Value): string => value.join("");

type IncompleteInputs = {
  tag: "IncompleteInputs";
  value: Value;
};
type CompleteInputs = {
  tag: "CompleteInputs";
  value: Value;
};
type SubmittingCode = {
  tag: "SubmittingCode";
  value: Value;
};
type SubmitSuccess = {
  tag: "SubmitSuccess";
  value: Value;
};
type SubmitError = {
  tag: "SubmitError";
  value: Value;
  error: string;
};
type FormState =
  | IncompleteInputs
  | CompleteInputs
  | SubmittingCode
  | SubmitSuccess
  | SubmitError;

const CodeInputs = ({
  country,
  error,
  handleSuccess,
  lookupData,
  phoneNumber,
  sendCode,
  sendCodeState,
}: Props) => {
  const [formState, setFormState] = useState<FormState>({
    tag: "IncompleteInputs",
    value: emptyValue,
  });
  const [resendCooldown, setResendCooldown] = useState<number>(0);

  const resetCodeInput: () => void = () => {
    setFormState({
      tag: "IncompleteInputs",
      value: emptyValue,
    });
  };

  useEffect(() => {
    switch (sendCodeState.type) {
      case "Completed": {
        resetCodeInput();
        setResendCooldown(10);
        const id = setInterval(() => {
          setResendCooldown((oldCooldown) => {
            const newCooldown = oldCooldown - 1;
            if (newCooldown === 0) clearInterval(id);
            return newCooldown;
          });
        }, 1000);
        break;
      }

      case "Failed":
        if (error) {
          setFormState((prev) => ({ ...prev, tag: "SubmitError", error }));
        }

        break;

      default:
        break;
    }
  }, [sendCodeState.type]);

  const inputsRef = useRef<Array<HTMLInputElement>>([]);

  const handleResendButtonClick =
    (via: "sms" | "call") =>
    (e: React.SyntheticEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      sendCode(via, country, phoneNumber);
    };

  const handleSubmit = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    if (formState.tag !== "CompleteInputs") return;

    setFormState({
      tag: "SubmittingCode",
      value: formState.value,
    });

    checkVerificationCode({
      countryCode: country.phoneCode.toString(),
      phoneNumber,
      countryAbbreviation: country.value,
      verificationCode: toString(formState.value),
      lookupData,
    })
      .then(() => {
        setFormState({
          tag: "SubmitSuccess",
          value: formState.value,
        });
        handleSuccess(e);
      })
      .catch((err) => {
        setFormState({
          tag: "SubmitError",
          value: formState.value,
          error: err?.body?.error?.message || "",
        });
      });
  };

  const handleInputChange = (inputNo: number) => (inputData: string) => {
    let newValue: Value;
    const numDigits = inputData.length;

    if (!inputData) {
      newValue = clearDigit(inputNo)(formState.value);
    } else {
      newValue = updateDigits(inputNo, inputData)(formState.value);
    }

    // focus next input
    if (inputData) {
      const numInputs = inputsRef.current.length;
      const nextInput = inputsRef.current[inputNo + numDigits];
      const lastInput = inputsRef.current[numInputs - 1];
      (nextInput || lastInput).focus();
    }

    // update form state
    if (isComplete(newValue)) {
      setFormState({
        tag: "CompleteInputs",
        value: newValue,
      });
    } else {
      setFormState({
        tag: "IncompleteInputs",
        value: newValue,
      });
    }
  };

  return (
    <div className={styles.codeInputs}>
      <p className={styles.intro}>
        We sent a code to{" "}
        {formatPhoneNumber(`+${country.phoneCode}${phoneNumber}`)}. Please enter
        it below:
      </p>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.codeFieldsWrapper}>
          {formState.value.map((digit: string, index: number) => (
            <BasicWrapper
              className={styles.codeField}
              name={`digit${index}`}
              key={`digit${index}`}
              value={digit}
              onChange={handleInputChange(index)}
            >
              <TextControl
                key={`digit${index}input`}
                autocomplete="one-time-code"
                className={styles.codeFieldInput}
                maxlength={4}
                minlength={1}
                inputRef={(inputEl) => {
                  if (inputEl) {
                    inputsRef.current[index] = inputEl;
                  }
                }}
              />
            </BasicWrapper>
          ))}

          {formState.tag === "SubmitError" ? (
            <div className={styles.error}>{formState.error}</div>
          ) : null}
        </div>

        <Button
          className={styles.resendCode}
          disabled={resendCooldown > 0}
          onClick={handleResendButtonClick("sms")}
          type="button"
          size="large"
          variant="tertiary"
        >
          Resend Code
        </Button>

        <Button
          className={styles.resendCode}
          disabled={resendCooldown > 0}
          onClick={handleResendButtonClick("call")}
          type="button"
          size="large"
          variant="tertiary"
        >
          Call me again
        </Button>

        <Button
          className={styles.submitCode}
          data-testid="verify-button"
          loading={formState.tag === "SubmittingCode"}
          type="submit"
          disabled={formState.tag !== "CompleteInputs"}
          size="large"
          variant="primary"
        >
          Verify
        </Button>
      </form>
    </div>
  );
};

export default CodeInputs;
