import type { IdentityProvider } from "storefront/GrailedAPI/v1/Sessions/create";
import "storefront/GrailedAPI/v1/Sessions/create";
import type { Method } from "../Method";
import "../Method";

type Properties = {
  method: Method;
  identityProvider: IdentityProvider;
};
export type CompleteIdentityProviderEvent = {
  object: "complete_identity_provider";
  action: "";
  properties: Properties;
};
export const completeIdentityProviderEvent = (
  method: Method,
  identityProvider: IdentityProvider,
): CompleteIdentityProviderEvent => ({
  object: "complete_identity_provider",
  action: "",
  properties: {
    method,
    identityProvider,
  },
});
