import type { IdentityProvider } from "storefront/GrailedAPI/v1/Sessions/create";

export type AuthType = "signup" | "login";
export type AuthenticationStartedEvent = {
  object: AuthType;
  action: "started";
  properties: {
    identityProvider: IdentityProvider;
  };
};

/**
 * @description Creates either "Signup Started" or "Login Started" event that can be given to `track` to record the event with our third-party event tracking services.
 *
 * @example
 *   const event = authenticationStarted("google or apple or whatever", "login or signup");
 *   track(event);
 */
const authenticationStarted = (
  identityProvider: IdentityProvider,
  authType: AuthType,
): AuthenticationStartedEvent => ({
  object: authType,
  action: "started",
  properties: {
    identityProvider,
  },
});

export default authenticationStarted;
