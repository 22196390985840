/**
 * @name Basics.Fields.Checkbox
 * @description Provides a checkbox box
 */
import React from "react";

export type Props = {
  name: string;
  type: "checkbox";
  value: boolean;
  isDisabled?: boolean;
  onChange: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
};

/**
 * @name Basics.Field.Control.Checkbox
 * @private
 */
const Checkbox: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({
  isDisabled,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
}) => (
  <>
    <input
      className="--checkbox-input"
      type="checkbox"
      id={name}
      checked={value}
      disabled={isDisabled}
      name={name}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'string |... Remove this comment to see the full error message
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
    {/* eslint-disable jsx-a11y/label-has-associated-control */}
    {/* eslint-disable jsx-a11y/label-has-for */}
    <label className="--checkmark" htmlFor={name} />
    {/* eslint-enable jsx-a11y/label-has-for */}
    {/* eslint-enable jsx-a11y/label-has-associated-control */}
  </>
);

export default Checkbox;
