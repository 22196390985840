import getProductListFrom from "../getProductListFrom";

export type SearchBarClickedEvent = {
  object: "search_bar";
  action: "clicked";
  properties: {
    from: string;
  };
};

/**
 * @description Creates a "search bar clicked" event that can be given to `track` to record the event with our third-party event tracking services.
 */

const searchBarClicked = (): SearchBarClickedEvent => ({
  object: "search_bar",
  action: "clicked",
  properties: {
    from: getProductListFrom(),
  },
});

export default searchBarClicked;
