import { Authentication } from "storefront/Authentication";
import { UserSelf } from "storefront/User";
import { Verification } from "../Verification";

type Callback = (user: UserSelf) => Promise<void> | void;

export type Unknown = {
  _tag: "Unknown";
};
export const unknown: Unknown = {
  _tag: "Unknown",
};

export type Ready = {
  _tag: "Ready";
  currentUser: UserSelf | undefined;
};
export const ready = (currentUser?: UserSelf): Ready => ({
  _tag: "Ready",
  currentUser,
});

export type Authorizing = {
  _tag: "Authorizing";
  callback: Callback;
  verifications: Set<Verification>;
  shouldReload: boolean;
  currentUser: UserSelf | undefined;
};
export const authorizing = (
  callback: Callback,
  verifications: Set<Verification>,
  shouldReload: boolean,
  currentUser: UserSelf | undefined,
): Authorizing => ({
  _tag: "Authorizing",
  callback,
  verifications,
  shouldReload,
  currentUser,
});

export type State = Unknown | Ready | Authorizing;

export const fromAuthentication = (auth: Authentication): State => {
  switch (auth.type) {
    case "Unauthenticated":
      return ready();

    case "Authenticated":
      return ready(auth.user);

    case "Loading":
    case "Failed":
    default:
      return unknown;
  }
};
