import React, { Component } from "react";
import classnames from "classnames";
import type { IndexedList } from "../../../../lib/IndexedList";
import TrackableMenuItem from "../TrackableMenuItem";

type Props = {
  label: string;
  href?: string;
  imageSrc?: string;
  imageAlt?: string;
  isOpen?: boolean;
  children?: any;
  onClick?: (arg0: React.SyntheticEvent<any>) => void;
  from?: string;
  rel?: string;
  className?: string;
};

/**
 * @private
 * @description Our current version of React does not support onTransitionEnd, the event fired when
 * a CSS transition or animation ends. Because of that, we have to keep this constant aligned with
 * the duration of the transition from the CSS.
 */
const ITEM_CSS_TRANSITION_DURATION = 400;

/**
 * @private
 * @description We cache the result of the first selection in this variable and use that,
 * instead of re-selecting the menu each time a section is expanded.
 */
let menu: any = null;
const itemElements: IndexedList<HTMLElement> = {};

/**
 * @private
 * @description When called with a label, it scrolls the menu element to the top of the item with
 * the given label. This function is partially applied (i.e., a thunk) because it is intended to be
 * passed as a callback to another function.
 */
const scrollToItem: (arg0: string) => (arg0: void) => void = (label) => () => {
  itemElements[label] =
    itemElements[label] ||
    window.document.getElementById(`slide_out_menu_item_${label}`);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'offsetTop' does not exist on type 'HTMLE... Remove this comment to see the full error message
  const { offsetTop } = window.document.getElementById(
    `slide_out_menu_item_${label}`,
  );
  const slideOutMenu = window.document.getElementById("slide_out_menu");
  menu = menu || slideOutMenu;
  menu.scrollTo({
    top: offsetTop,
    behavior: "smooth",
  });
};

/**
 * @private
 */
const getClassName: (arg0: Props) => string = ({
  isOpen,
  children,
  imageSrc,
}) =>
  classnames({
    "--item": true,
    _open: isOpen,
    "_has-children": !!children,
    "_has-image": !!imageSrc,
  });
/**
 * @name GlobalHeader.SlideOutMenu.Item
 * @description An item can have children or it can have an href. When it has children, it can be
 * expanded to reveal the children. If there is an href, clicking the item will follow the link. It
 * is not intended to support both children and an href.
 */

class Item extends Component<Props> {
  /**
   * @private
   * @description When the component updates, we check to see if it is now being expanded. If it is,
   * we wait for the expand animation to complete, then we scroll to the top of the item.
   */
  componentDidUpdate: (arg0: Props) => void = (previousProps) => {
    if (this.props.isOpen && !previousProps.isOpen) {
      window.setTimeout(
        scrollToItem(this.props.label),
        ITEM_CSS_TRANSITION_DURATION,
      );
    }
  };

  render() {
    const {
      label,
      href,
      onClick,
      from,
      rel,
      imageSrc,
      imageAlt,
      children,
      className,
    } = this.props;

    return (
      <div
        className={getClassName(this.props)}
        id={`slide_out_menu_item_${label}`}
      >
        <span>
          <TrackableMenuItem
            href={href}
            className={classnames("--label", className)}
            onClick={onClick}
            label={label}
            from={from}
            rel={rel}
          >
            {imageSrc ? (
              <div
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ alt: string | undefined; className: string... Remove this comment to see the full error message
                alt={imageAlt}
                className="--image"
                style={{
                  backgroundImage: `url(${imageSrc})`,
                }}
              />
            ) : null}

            {label}
          </TrackableMenuItem>
        </span>

        {children ? <div className="--sub-items">{children}</div> : null}
      </div>
    );
  }
}

export default Item;
