import React from "react";
import cn from "classnames";
import Menu from "storefront/components/Icons/Navigation/Menu";

type Props = {
  onClick: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  hasNotifications: boolean;
  isHidden: boolean;
};

const onEnterKey =
  (fn: (e: React.KeyboardEvent<HTMLDivElement>) => void) =>
  (event: React.KeyboardEvent<HTMLDivElement>): void | null =>
    event.key === "Enter" ? fn(event) : null;

const className = (hasNotifications: boolean, isHidden: boolean): string =>
  cn("-menu-container", {
    _notifications: hasNotifications,
    _hidden: isHidden,
  });

const Hamburger = ({ onClick, hasNotifications, isHidden }: Props) => (
  <div
    className={className(hasNotifications, isHidden)}
    onClick={onClick}
    onKeyPress={onEnterKey(onClick)}
    role="button"
    tabIndex={0}
  >
    <Menu className="-menu-icon" />
  </div>
);

export default Hamburger;
