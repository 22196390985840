import { safelyGetItem } from "../lib/Storage";
import type { State } from "./State";
import { unstarted } from "./State";
import { LOCAL_STORAGE_KEY } from "./localStorageSubscriber";

const myGrailsStateFromWindow = (win: typeof window): State => {
  const key: string = LOCAL_STORAGE_KEY;
  const value: string | null | undefined = safelyGetItem(win.localStorage)(key);

  switch (value) {
    case "UNSTARTED":
    case "STARTED":
    case "SHOW_HEADER_TOOLTIP":
    case "COMPLETED":
      return value;

    default:
      return unstarted;
  }
};

export default myGrailsStateFromWindow;
