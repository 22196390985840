import React from "react";
import Link from "storefront/components/Link";
import { Error } from "../Layout";

export const USER_EXISTS = "USER_EXISTS" as const;

type Props = {
  error: string | null | undefined;
  onLoginClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

const Errors = ({ error, onLoginClick }: Props) =>
  error === USER_EXISTS ? (
    <Error>
      Looks like this email is already registered to an account.{" "}
      <Link
        href="/users/sign_up"
        onClick={(event) => {
          event.preventDefault();
          onLoginClick(event);
        }}
        variant="underline"
      >
        Log in instead
      </Link>
    </Error>
  ) : (
    <Error>{error}</Error>
  );

export default Errors;
