import React, { useState, useContext } from "react";
import cn from "classnames";
import useAuthentication from "storefront/hooks/useAuthentication";
import useWindowScrollLock from "storefront/hooks/useWindowScrollLock";
import onlyOnEnterKey from "storefront/lib/onlyOnEnterKey";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import XCircle from "storefront/components/Icons/Navigation/XCircle";
import MenuExpandedContext, { Value } from "../MenuExpandedContext";
import Account from "./Items/Account";
import SlideOutMenuItems from "./SlideOutMenuItems";
import "./SlideOutMenu.scss";

/**
 * @private
 * @description Renders a SlideOutMenu. Included are the close button, the drawer, and the logic
 * for which items to put in the drawer, based on the current props. The entire menu can be open or
 * not open; in the stylesheets we control how the menu, close button, and drawer look in either
 * state.
 */
const SlideOutMenu = () => {
  const auth = useAuthentication();
  const { track } = useAnalytics();

  const { isExpanded, setExpanded } = useContext<Value>(MenuExpandedContext);
  useWindowScrollLock(isExpanded);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    track(navigationClicked("nav_close", "global_header"));
    setExpanded(false);
  };

  const [expandedSection, setExpandedSection] = useState<string | null>(
    "account",
  );

  return (
    <div
      className={cn("Page-Header-SlideOutMenu", {
        _open: isExpanded,
      })}
      id="slide_out_menu"
      onClick={handleClick}
      onKeyPress={onlyOnEnterKey(handleClick)}
      role="button"
      tabIndex={0}
    >
      <button
        className="--close"
        type="button"
        onClick={handleClick}
        title="Close"
      >
        <XCircle />
      </button>

      <div
        className="--drawer"
        onClick={(e) => e.stopPropagation()}
        onKeyPress={onlyOnEnterKey((e) => e.stopPropagation())}
        role="button"
        tabIndex={0}
      >
        {auth.type === "Authenticated" ? (
          <Account
            isOpen={expandedSection === "account"}
            onClick={() => {
              track(navigationClicked("Account", "global_header"));
              setExpandedSection((current) =>
                current === "account" ? null : "account",
              );
            }}
            currentUser={auth.user}
          />
        ) : null}
        <SlideOutMenuItems
          isSignedIn={auth.type === "Authenticated"}
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
        />
      </div>
    </div>
  );
};

export default SlideOutMenu;
