import React from "react";

type Props = {
  note: string;
};

/**
 * @name Basics.Field.Note
 * @private
 */
const Note: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ note }) => (
  <span className="--note">{note}</span>
);

export default Note;
