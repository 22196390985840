import React, { useEffect, useState } from "react";
import { get } from "lodash";
import ProtectionBadge from "storefront/components/Icons/ProtectionBadge";
import sendVerificationCode, {
  Response,
  Params,
} from "storefront/GrailedAPI/v1/Users/Verification/sendVerificationCode";
import { Country } from "storefront/Country";
import { Lookup } from "storefront/Twilio/Lookup";
import CodeInputs from "storefront/components/PhoneVerification/CodeInputs";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import useSubmitState from "storefront/hooks/useSubmitState";
import PhoneInputs from "./PhoneInputs";
import styles from "./PhoneVerification.module.scss";

type Props = {
  handleSubmit: (e: React.SyntheticEvent<HTMLElement>) => void;
  onMount?: () => void;
};

// TODO: this can be a conditional type
type FormState = {
  tag: "CodeEntryView" | "PhoneEntryView";
  country?: Country;
  phoneNumber?: string;
  lookupData?: Lookup;
  error?: string;
};

const PhoneVerification = ({ handleSubmit, onMount }: Props) => {
  const [formState, setFormState] = useState<FormState>({
    tag: "PhoneEntryView",
  });
  const [sendCode, sendCodeState] = useSubmitState<Response, Params>(
    sendVerificationCode,
  );
  const { countries } = usePublicConfig();

  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);

  useEffect(() => {
    switch (sendCodeState.type) {
      case "Completed": {
        const { value: response } = sendCodeState;
        setFormState((prev) => {
          // if code was sent successfully from phone entry, go to code entry
          const nextTag =
            prev.tag === "PhoneEntryView" ? "CodeEntryView" : prev.tag;
          return {
            ...prev,
            tag: nextTag,
            lookupData: response.data.lookupData,
            error: undefined,
          };
        });
        break;
      }

      case "Failed": {
        const { error } = sendCodeState;

        if (get(error, "body.error.error_id") === "banned") {
          window.location.href = "/";
          // set error message to "Banned"
          setFormState((prev) => ({ ...prev, error: "Banned" }));
        }

        const errMsg =
          get(error, "body.error.message") ||
          "Unable to send code. Please try again.";
        setFormState((prev) => ({ ...prev, error: errMsg }));
        break;
      }

      case "Waiting":
      default:
        break;
    }
  }, [sendCodeState.type]);

  const handleSendCode = (
    via: "sms" | "call",
    country: Country,
    phoneNumber: string,
  ): void => {
    setFormState((prev) => ({ ...prev, country, phoneNumber }));
    sendCode({
      via,
      countryCode: country.phoneCode,
      phoneNumber: parseInt(phoneNumber, 10),
    });
  };

  const renderView = () => {
    if (
      formState.tag === "CodeEntryView" &&
      formState.country &&
      formState.lookupData &&
      formState.phoneNumber
    ) {
      return (
        <CodeInputs
          country={formState.country}
          error={formState.error}
          handleSuccess={handleSubmit}
          lookupData={formState.lookupData}
          phoneNumber={formState.phoneNumber}
          sendCode={handleSendCode}
          sendCodeState={sendCodeState}
        />
      );
    }

    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'children' is missing in type '{ countrie... Remove this comment to see the full error message
      <PhoneInputs
        countries={countries}
        error={formState.error}
        sendCode={handleSendCode}
      />
    );
  };

  return (
    <div className={styles.phoneVerification}>
      {renderView()}
      <div className={styles.verificationInfo}>
        <ProtectionBadge />
        <div className={styles.verificationDisclaimer}>
          Verifying your identity helps us protect buyers and sellers
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;
