import React from "react";
import { useTranslation } from "react-i18next";
import Body from "storefront/components/Body";
// eslint-disable-next-line css-modules/no-unused-class
import css from "./VerifyEmail.module.scss";

type Props = {
  email: string;
};

const Failure = ({ email }: Props) => {
  const { t } = useTranslation("common");

  return (
    <div className={css.root}>
      <Body className={css.body}>
        We encountered an error while attempting to send an email to{" "}
        <strong>{email}</strong>.
      </Body>

      <Body className={css.body}>
        Please try again. If the problem persists, please email{" "}
        <strong>{`${t("HELP_EMAIL")}`}</strong> for assistance.
      </Body>
    </div>
  );
};

export default Failure;
