import React, {
  useEffect,
  useState,
  useMemo,
  BaseSyntheticEvent,
  MouseEvent,
  KeyboardEvent,
} from "react";
import {
  AutocompleteApi,
  AutocompleteState,
  createAutocomplete,
} from "@algolia/autocomplete-core";
import { SearchClient } from "algoliasearch";

import createInsecureClient from "storefront/Algolia/Client/createInsecureClient";
import useAnalytics from "storefront/hooks/useAnalytics";
import productSearched from "storefront/Analytics/EventCreators/productSearched";

import Autocomplete from "./Autocomplete";
import useCreateResultsSearchAndRedirect from "./useCreateResultsSearchAndRedirect";
import {
  useAutocompleteSourcesPlugins,
  AutocompleteItem,
  reshape,
} from "./autocompleteSources";

export const AUTOCOMPLETE_ID = "header_search";

export type AutocompleteInstance = AutocompleteApi<
  AutocompleteItem,
  BaseSyntheticEvent,
  MouseEvent<Element>,
  KeyboardEvent<Element>
>;

type Props = {
  algoliaClient: SearchClient;
};

const AlgoliaAutocomplete = ({ algoliaClient }: Props) => {
  // state needed for Autocomplete JS
  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<AutocompleteItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: "",
    activeItemId: null,
    status: "idle",
  });

  const createResultsSearchAndRedirect = useCreateResultsSearchAndRedirect();

  const { track } = useAnalytics();

  const { getSources, plugins, recentSearchesPlugin } =
    useAutocompleteSourcesPlugins({
      algoliaClient,
    });

  const autocomplete = useMemo(
    () =>
      createAutocomplete<
        AutocompleteItem,
        BaseSyntheticEvent,
        MouseEvent,
        KeyboardEvent
      >({
        id: AUTOCOMPLETE_ID,
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        placeholder: "Search",
        openOnFocus: true,
        onSubmit({ state }) {
          track(productSearched(state.query, "header"));
          createResultsSearchAndRedirect(state.query);
        },
        // support prefilling form with existing query
        initialState: {
          query: window.SEARCH?.query || "",
        },
        reshape,
        getSources,
        plugins,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const removeRecentSearch = (id: string) => {
    recentSearchesPlugin.data?.removeItem(id);
    autocomplete.setQuery("");
    autocomplete.refresh();
  };

  return (
    <Autocomplete
      autocomplete={autocomplete}
      autocompleteState={autocompleteState}
      removeRecentSearch={removeRecentSearch}
    />
  );
};

const AlgoliaAutocompleteWithClient = () => {
  const [algoliaClient, setAlgoliaClient] = useState<SearchClient | null>(null);

  // use insecure client for public query suggestion database
  // secure keys dont have access to index for some reason
  useEffect(() => {
    createInsecureClient().then((client) => setAlgoliaClient(client));
  }, []);

  if (!algoliaClient) return null;

  // only render once algolia client is ready
  return <AlgoliaAutocomplete algoliaClient={algoliaClient} />;
};

// header rerenders on every scroll but we dont want to rerender this as a result
export default React.memo(AlgoliaAutocompleteWithClient);
