import React from "react";
import classnames from "classnames";

export type Props = {
  className?: string;
  // TODO: maybe we can get rid of this when we refactor Field?
  // The type attribute is not valid on <textarea>
  // eslint-disable-next-line react/no-unused-prop-types
  type: "textarea";
  name: string;
  placeholder?: string;
  value: string;
  maxLength?: number;
  minLength?: number;
  isDisabled?: boolean;
  onChange: (arg0: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  onFocus?: (arg0: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  onBlur?: (arg0: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (arg0: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
};

/**
 * @name Basics.Field.Control.Textarea
 * @private
 * NOTE: the data-gramm attribute is to disable the extension Grammarly from
 * adding elements to our DOM and messing up our styling.
 * Read more here: https://stackoverflow.com/questions/37444906/how-to-stop-extensions-add-ons-like-grammarly-on-contenteditable-editors
 */
const Textarea = ({
  isDisabled,
  className,
  name,
  placeholder,
  value,
  maxLength,
  minLength,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  autoFocus,
}: Props) => (
  <textarea
    data-gramm="false"
    className={classnames(className, "--input")}
    id={name}
    disabled={isDisabled}
    name={name}
    placeholder={placeholder}
    value={value}
    maxLength={maxLength}
    minLength={minLength}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
    autoFocus={autoFocus}
  />
);

export default Textarea;
