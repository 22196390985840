import { snakeCase } from "lodash/fp";
import type { Id } from "storefront/lib/Id";
import "storefront/lib/Id";

export type ButtonClickedEvent = {
  object: "button";
  action: "clicked";
  properties: {
    name: string;
    from?: string;
    listingId?: Id;
    listing_id?: Id;
    oldPrice?: number;
    newPrice?: number;
    priceRecommendation?: string;
    from_position?: number;
    to_position?: number;
    fromPrice?: number;
    floorPrice?: number | null;
    previousFloorPrice?: number | null;
    prev_paypal_merchant_id?: string;
    method?: "create" | "update";
    percentile25?: number | null;
    percentile40?: number | null;
    median?: number | null;
    percentile60?: number | null;
    percentile75?: number | null;
    recommendationTitle?: string | null;
    priceRecommendationType?: string | null;
    priceRecommendationLookbackWindow?: string | null;
    listingPrice?: number;
    marketplace?: "grailed";
  };
};

/**
 * @description Creates a "button clicked" event that can be given to `track` to record the event with our third-party event tracking services.
 *
 * @example
 *   const event = buttonClicked("some test name", "some page");
 *   track(event);
 */
const buttonClicked = (name: string, from: string): ButtonClickedEvent => ({
  object: "button",
  action: "clicked",
  properties: {
    name: snakeCase(name),
    from,
  },
});

export default buttonClicked;
