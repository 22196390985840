/* eslint-disable max-classes-per-file */
import type { Failure as AppleSignInFailure } from "./signIn";
import "./signIn";

export class UserCancelled extends Error {
  identityProvider: "apple" = "apple";

  type: "UserCancelled" = "UserCancelled";
}
export class Unknown extends Error {
  identityProvider: "apple" = "apple";

  type: "Unknown" = "Unknown";
}
export type AppleIdentityProviderError = UserCancelled | Unknown;
export const fromAppleSignInFailure = (
  failure: AppleSignInFailure,
): AppleIdentityProviderError => {
  switch (failure.error) {
    case "user_cancelled_authorize":
      return new UserCancelled();

    default:
      return new Unknown();
  }
};
