/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['charPadding1', 'charPadding2', 'charPadding3'] }] */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { find, map } from "lodash";
import { withTranslation, TranslationProps } from "react-i18next";
import {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { Country } from "storefront/Country";
import SelectControl from "storefront/components/Field/SelectControl";
import TelephoneControl from "storefront/components/Field/TelephoneControl";
import BasicWrapper from "storefront/components/Field/BasicWrapper";
import Label from "storefront/components/Field/Label";
import Button from "storefront/components/Button";
import styles from "./PhoneInputs.module.scss";

type OwnProps = {
  sendCode: (
    via: "sms" | "call",
    country: Country,
    phoneNumber: string,
  ) => void;
  countries: Array<Country>;
  error?: string;
};

type Props = OwnProps & TranslationProps;

type IncompleteForm = {
  tag: "IncompleteForm";
  country: Country;
  phoneNumber: string;
};
type CompleteForm = {
  tag: "CompleteForm";
  country: Country;
  phoneNumber: string;
};
type FormError = {
  tag: "FormError";
  error: string;
  country: Country;
  phoneNumber: string;
};
type SubmittingForm = {
  tag: "SubmittingForm";
  country: Country;
  phoneNumber: string;
};
type FormSuccess = {
  tag: "FormSuccess";
  country: Country;
  phoneNumber: string;
};
type FormState =
  | IncompleteForm
  | CompleteForm
  | FormError
  | SubmittingForm
  | FormSuccess;

// @ts-expect-error ts-migrate(2339) FIXME: Property 't' does not exist on type 'OwnProps & Tr... Remove this comment to see the full error message
const PhoneInputs = ({ error, sendCode, countries, t }: Props) => {
  const [formState, setFormState] = useState<FormState>({
    tag: "IncompleteForm",
    country:
      find(countries, {
        value: "US",
      }) || countries[0],
    phoneNumber: "",
  });

  useEffect(() => {
    if (error) {
      setFormState((prev) => ({ ...prev, tag: "FormError", error }));
    }
  }, [error]);

  const countryCodeOptions = map(countries, (c) => {
    return {
      label: c.name,
      value: c.value,
    };
  });
  const fullPhoneNumber = `+${formState.country.phoneCode}${formState.phoneNumber}`;

  const handleCountryChange = (newCountryCode: string) => {
    const newCountry = find(countries, {
      value: newCountryCode,
    });

    if (newCountry) {
      const newPhoneNumber = `+${newCountry.phoneCode}${formState.phoneNumber}`;

      if (isValidPhoneNumber(newPhoneNumber)) {
        const formattedPhoneNumber = formatPhoneNumberIntl(newPhoneNumber)
          .replace(`+${newCountry.phoneCode}`, "")
          .trimLeft();
        setFormState({
          tag: "CompleteForm",
          country: newCountry,
          phoneNumber: formattedPhoneNumber,
        });
      } else {
        setFormState({
          tag: "FormError",
          error: "Invalid phone number format",
          country: newCountry,
          phoneNumber: formState.phoneNumber.replace(/\s+/g, ""),
        });
      }
    }
  };

  const handlePhoneNumberBlur = (
    _e: React.FocusEvent<Element> | null | undefined,
  ) => {
    const formattedPhoneNumber = formatPhoneNumberIntl(fullPhoneNumber)
      .replace(`+${formState.country.phoneCode}`, "")
      .trimLeft();

    if (!isValidPhoneNumber(fullPhoneNumber)) {
      setFormState({
        tag: "FormError",
        error: "Invalid phone number format",
        country: formState.country,
        phoneNumber: formState.phoneNumber.replace(/\s+/g, ""),
      });
    } else {
      setFormState({
        tag: "CompleteForm",
        country: formState.country,
        phoneNumber: formattedPhoneNumber,
      });
    }
  };

  const handlePhoneNumberChange = (newPhoneNumber: string) => {
    if (
      isValidPhoneNumber(`+${formState.country.phoneCode}${newPhoneNumber}`)
    ) {
      setFormState({
        tag: "CompleteForm",
        country: formState.country,
        phoneNumber: newPhoneNumber,
      });
    } else {
      setFormState({
        tag: "IncompleteForm",
        country: formState.country,
        phoneNumber: newPhoneNumber,
      });
    }
  };

  const handleSubmit =
    (via: "sms" | "call") => (e: React.SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (!isValidPhoneNumber(fullPhoneNumber)) {
        setFormState({
          tag: "FormError",
          error: "Invalid phone number format",
          country: formState.country,
          phoneNumber: formState.phoneNumber.replace(/\s+/g, ""),
        });
        return;
      }

      setFormState({
        tag: "SubmittingForm",
        country: formState.country,
        phoneNumber: formState.phoneNumber,
      });
      sendCode(
        via,
        formState.country,
        formState.phoneNumber.replace(/\s+/g, ""),
      );
    };

  return (
    <div className={styles.phoneInputs}>
      <p className={styles.intro}>
        To use {t("MARKETPLACE_NAME")}, we need to verify your phone number. We
        will never display this number publicly.
      </p>
      <form className={styles.form}>
        <div className={styles.phoneInputs}>
          <BasicWrapper
            className={styles.countrySelectWrapper}
            name="phone-inputs-country-code-field-wrapper"
            value={formState.country.value}
            onChange={handleCountryChange}
          >
            <Label className={styles.label} size="small">
              Choose your country
            </Label>
            <SelectControl
              className={styles.countrySelectControl}
              options={countryCodeOptions}
              required
            />
          </BasicWrapper>

          <BasicWrapper
            className={styles.phoneNumberWrapper}
            name="phone-inputs-phone-number-field-wrapper"
            value={formState.phoneNumber}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneNumberBlur}
            error={formState.tag === "FormError" ? formState.error : undefined}
          >
            <Label className={styles.label} size="small">
              Enter your phone number
            </Label>
            <TelephoneControl
              className={classNames(
                styles.phoneNumber,
                styles[
                  `charPadding${formState.country.phoneCode.toString().length}`
                ],
              )}
              required
            />
            <div
              className={classNames(styles.countryCode, {
                [styles.listingPage]:
                  window.location.pathname.startsWith("/listings"),
              })}
            >
              +{formState.country.phoneCode}
            </div>
          </BasicWrapper>
        </div>

        {formState.tag === "FormError" ? (
          <div className={styles.error}>{formState.error}</div>
        ) : null}

        <Button
          className={styles.sendCode}
          type="submit"
          loading={formState.tag === "SubmittingForm"}
          disabled={formState.tag !== "CompleteForm"}
          onClick={handleSubmit("sms")}
          size="large"
          variant="primary"
        >
          Send Code
        </Button>

        <Button
          className={styles.sendCodeLink}
          type="submit"
          loading={formState.tag === "SubmittingForm"}
          disabled={formState.tag !== "CompleteForm"}
          onClick={handleSubmit("call")}
          size="large"
          variant="tertiary"
        >
          Call me instead
        </Button>
      </form>
    </div>
  );
};

export default withTranslation("common")(PhoneInputs);
