import { compose } from "lodash/fp";
import type { GlobalState } from "../../../GlobalState";
import { session } from "../../../GlobalState";
import { currentUser } from "../../../Session";
import { username } from "../../../User";

const imageAltSelector: (arg0: GlobalState) => string = compose([
  username,
  currentUser,
  session,
]);
export default imageAltSelector;
