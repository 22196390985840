import React from "react";

/**
 * @private
 * @description A union of all of the currently supported types. The commented out types are valid
 * HTML types for an <input>, but are not supported for a Field. Some will never be supported
 * because they don't really make sense for a Field, e.g. 'button', 'reset', others may be
 * supported at a later date, e.g. 'file', 'range'.
 */
type TypeValue =
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "month"
  | "number"
  | "password"
  | "search"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week"
  | "file";
// | 'button'
// | 'checkbox'
// | 'hidden'
// | 'image'
// | 'radio'
// | 'range'
// | 'reset'
export type Props = {
  name: string;
  type: TypeValue;
  placeholder?: string;
  value: string | number;
  maxLength?: number;
  minLength?: number;
  isDisabled?: boolean;
  onChange: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
  onKeyPress?: (arg0: React.KeyboardEvent<HTMLInputElement>) => void;
  isSuccessful?: boolean;
  autoFocus?: boolean;
  multiple?: boolean;
  accept?: string;
};

/**
 * @name Basics.Field.Control.Input
 * @private
 */
const Input: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({
  isDisabled,
  name,
  autoFocus,
  type,
  placeholder,
  value,
  maxLength,
  minLength,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  multiple,
  accept,
}) => (
  <input
    className="--input"
    id={name}
    disabled={isDisabled}
    autoFocus={autoFocus}
    name={name}
    type={type}
    placeholder={placeholder}
    value={value}
    maxLength={maxLength}
    minLength={minLength}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
    multiple={multiple}
    accept={accept}
  />
);

export default Input;
