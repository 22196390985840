import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { totalUnread } from "storefront/Conversations";
import { GlobalState } from "storefront/GlobalState";
import useAuthentication from "storefront/hooks/useAuthentication";
import { signOut } from "storefront/components/SignOutButton";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import Caret from "storefront/components/Icons/Navigation/Caret";
import TrackableMenuItem from "../../TrackableMenuItem";
import TrackableMenuButtonItem from "../../TrackableMenuButtonItem";
import styles from "./DropdownMenu.module.scss";

type LinkProps = {
  href: string;
  label: string;
  notificationCount?: number;
  className?: string;
  isPrimary?: boolean;
};

const Link = ({
  href,
  label,
  notificationCount,
  className,
  isPrimary,
}: LinkProps) => {
  const { track } = useAnalytics();
  if (!!notificationCount && notificationCount > 0) {
    return (
      <TrackableMenuItem
        href={href}
        label={label}
        className={cn(styles.link, className, {
          [styles.primary]: isPrimary,
        })}
        onClick={() => {
          track(navigationClicked(label, "global_header"));
        }}
      >
        {label}
        <span className={styles.notifications}>{notificationCount}</span>
      </TrackableMenuItem>
    );
  }

  return (
    <TrackableMenuItem
      href={href}
      label={label}
      className={cn(styles.link, className, {
        [styles.primary]: isPrimary,
      })}
      onClick={() => {
        track(navigationClicked(label, "global_header"));
      }}
    >
      {label}
      {isPrimary ? <Caret direction="right" className={styles.caret} /> : null}
    </TrackableMenuItem>
  );
};

type Props = { className?: string };

const DropdownMenu = ({ className = "" }: Props) => {
  const auth = useAuthentication();
  const { track } = useAnalytics();

  const notificationCount = useSelector((state: GlobalState) =>
    totalUnread(state.conversations),
  );

  if (auth.type !== "Authenticated") return null;

  return (
    <div className={cn(styles.menu, className)}>
      <Link
        href={`/${auth.user.username}`}
        label={auth.user.username}
        isPrimary
      />

      <div className={styles.usernameDivider} />

      <Link
        href="/messages"
        label="Messages"
        notificationCount={notificationCount}
      />
      <Link href={`/mygrails/${auth.user.id}`} label="Favorites" />
      <Link href="/users/purchases" label="Purchases" />

      <div className={styles.divider} />

      <Link href="/sell/for-sale" label="Sell" isPrimary />
      <Link href="/sell/for-sale" label="For Sale" />
      <Link href="/sell/sold" label="Sold" />
      <Link href="/sell/drafts" label="Drafts" />

      <div className={styles.divider} />

      <Link href="/users/myprofile" label="My Account" isPrimary />

      <div className={styles.divider} />

      <TrackableMenuButtonItem
        label="Sign Out"
        onClick={(e) => {
          track(navigationClicked("Sign Out", "global_header"));
          signOut(e);
        }}
        className={cn(styles.link, styles.signOut)}
      />
    </div>
  );
};

export default DropdownMenu;
