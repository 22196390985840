import React from "react";
import onlyOnEnterKey from "../../../lib/onlyOnEnterKey";

type Props = {
  onClick: (arg0: React.SyntheticEvent<any>) => void;
};

/**
 * @name Basics.Field.Icons.ClearButton
 * @private
 */
const ClearButton: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ onClick }) => (
  <span
    className="--icon _clear"
    onClick={onClick}
    onKeyPress={onlyOnEnterKey(onClick)}
    role="button"
    tabIndex={0}
  >
    Clear
  </span>
);

export default ClearButton;
