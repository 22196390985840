import React from "react";
import classNames from "classnames";

import styles from "./Heart.module.scss";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  isFull?: boolean;
};

const Heart = ({ isFull, className, height = 10, width = 15 }: Props) => {
  const classes = classNames(styles.heart, "heart", className);

  return isFull ? (
    <svg
      className={classes}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8941 12.6944L14 22.5886H12L2.10585 12.6944C-0.701951 9.8866 -0.701951 5.33367 2.10585 2.52587C4.91366 -0.281938 9.46658 -0.281938 12.2744 2.52587L13 3.25148L13.7256 2.52587C16.5334 -0.281938 21.0863 -0.281938 23.8941 2.52587C26.702 5.33367 26.702 9.8866 23.8941 12.6944Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      className={classes}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2744 2.53035C9.46658 -0.277452 4.91366 -0.277452 2.10585 2.53035C-0.701951 5.33816 -0.701951 9.89108 2.10585 12.6989L12 22.593H14L23.8941 12.6989C26.702 9.89108 26.702 5.33816 23.8941 2.53035C21.0863 -0.277452 16.5334 -0.277452 13.7256 2.53035L13 3.25596L12.2744 2.53035ZM13.1716 20.593L22.4799 11.2847C24.5067 9.25792 24.5067 5.97132 22.4799 3.94457C20.4532 1.91781 17.1666 1.91781 15.1398 3.94457L13.5 5.58439H13H12.5L10.8602 3.94457C8.83342 1.91781 5.54682 1.91781 3.52007 3.94457C1.49331 5.97132 1.49331 9.25792 3.52007 11.2847L12.8284 20.593H13.1716Z"
        fill="black"
      />
    </svg>
  );
};

export default Heart;
