import getCategories, {
  CACHE_KEY,
} from "storefront/GrailedAPI/v1/Config/getCategories";

import type { CategoriesV2 } from "storefront/Categories";
import useData from "storefront/hooks/data/useData";
import { completed, failed, loading, Resource } from "storefront/lib/Resource";

/**
 * @name useCategories
 * @description Fetches listing categories using the useSWR hook in
 * functional components.
 *
 */
const useCategories = (): Resource<CategoriesV2> => {
  const categoryData = useData(getCategories, {
    key: CACHE_KEY,
  });

  if (categoryData.type === "Loading") {
    return loading;
  }

  if (categoryData.type === "Failed") {
    return failed(
      new Error(`Error fetching categories: ${categoryData.error.message}`),
    );
  }

  return completed(categoryData.value.categories);
};

export default useCategories;
