/* eslint-disable jsx-a11y/label-has-for */

/**
 * @name Basics.Fields.Radio
 * @description Provides an set of radio inputs
 */
import React from "react";
import { toPairs } from "lodash/fp";
import type { IndexedList } from "../../../lib/IndexedList";

export type Props = {
  type: "radio";
  name: string;
  value: string;
  collection: IndexedList<string>;
  onChange: (arg0: React.SyntheticEvent<any>) => void;
  onFocus?: (arg0: React.SyntheticEvent<any>) => void;
  onBlur?: (arg0: React.SyntheticEvent<any>) => void;
};

/**
 * @name Basics.Field.Control.Radio
 * @private
 */
const Radio = ({
  collection,
  name,
  type,
  value,
  onChange,
  onFocus,
  onBlur,
}: Props) => (
  <>
    {toPairs(collection).map(([label, radioValue]) => (
      <label className="radio-container" key={label}>
        <input
          className="--radio-input"
          type="radio"
          id={`${radioValue}-${name}-${type}`}
          name={name}
          checked={value === radioValue}
          value={radioValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <span className="--radio-fill" />
        <label className="--label" htmlFor={`${radioValue}-${name}-${type}`}>
          {label}
        </label>
      </label>
    ))}
  </>
);

export default Radio;
