import React from "react";
import { SubmissionError } from "redux-form";
import { Id } from "storefront/lib/Id";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import {
  UserCreateError,
  createWithPassword,
} from "storefront/GrailedAPI/v1/Users/create";
import createSession, {
  EmailCredentials,
  SessionCreateError,
} from "storefront/GrailedAPI/v1/Sessions/create";
import { Tracker } from "storefront/Analytics/Tracker";
import useAnalytics from "storefront/hooks/useAnalytics";
import { USER_EXISTS } from "./Errors";
import { AuthenticationData } from "../Data";
import SignUpForm from "./SignUpForm";

export type SignUpStepCompletedEvent = {
  object: "sign_up_step";
  action: "completed";
  properties: {
    emailOptIn: boolean | null | undefined;
    identityProvider: "email";
    user_id: Id;
  };
};

type Props = {
  onSuccess: (data: AuthenticationData) => void;
  onLoginClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  emailSubscriptionIsChecked: boolean;
  onEmailSubscriptionChange: (
    e: React.SyntheticEvent<HTMLInputElement>,
  ) => void;
};

const createUser =
  (track: Tracker["track"]) =>
  (credentials: EmailCredentials): Promise<AuthenticationData> => {
    track(buttonClicked("sign_up", "signup"));
    return createWithPassword(
      credentials.email,
      credentials.password,
      credentials.recaptchaToken,
      credentials.recaptchaType,
      credentials.optInEmails,
    )
      .then((user) => {
        track({
          object: "sign_up_step",
          action: "completed",
          properties: {
            emailOptIn: credentials.optInEmails,
            identityProvider: "email",
            // eslint-disable-next-line camelcase
            user_id: user.id,
          },
        });

        return {
          user,
          token: "",
          redirectTo: "",
          credentials,
        };
      })
      .catch((error: UserCreateError) => {
        switch (error.type) {
          case "IPQSFailed":
            throw new SubmissionError({
              email: error.message,
            });

          case "EmailTaken": {
            return createSession(credentials).catch(
              (sessionError: SessionCreateError) => {
                console.log(sessionError);
                throw new SubmissionError({
                  _error: USER_EXISTS,
                });
              },
            );
          }

          default:
            throw new SubmissionError({
              _error: error.message,
            });
        }
      });
  };

const SignUpEmail = ({
  onSuccess,
  onLoginClick,
  emailSubscriptionIsChecked,
  onEmailSubscriptionChange,
}: Props) => {
  const { track } = useAnalytics();

  return (
    <SignUpForm
      onSubmit={createUser(track)}
      submitText="Sign up"
      onLoginClick={onLoginClick}
      onSubmitSuccess={onSuccess}
      emailSubscriptionIsChecked={emailSubscriptionIsChecked}
      onEmailSubscriptionChange={onEmailSubscriptionChange}
    />
  );
};

export default SignUpEmail;
