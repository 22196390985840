import { AuthenticationData } from "../Data";

/*
 * Action and Step Names
 */
export const GO_TO_EMAIL_AND_PASSWORD = "GO_TO_EMAIL_AND_PASSWORD" as const;
export const GO_TO_USERNAME = "GO_TO_USERNAME" as const;
export const GO_TO_PHONE_VERIFICATION = "GO_TO_PHONE_VERIFICATION" as const;
export const SIGNUP_IDENTITY_EMAIL = "SIGNUP_IDENTITY_EMAIL" as const;
export const SIGNUP_EMAIL = "SIGNUP_EMAIL" as const;
export const SIGNUP_OPTIONS = "SIGNUP_OPTIONS" as const;
export const SIGNUP_PHONE = "SIGNUP_PHONE" as const;
export const SIGNUP_USERNAME = "SIGNUP_USERNAME" as const;
export const TOGGLE_WANTS_EMAIL = "TOGGLE_WANTS_EMAIL" as const;

/*
 * Step Types
 */
export type SignupEmailStep = {
  type: typeof SIGNUP_EMAIL;
  wantsEmail: boolean;
};
export type SignupOptionsStep = {
  type: typeof SIGNUP_OPTIONS;
};
export type SignupPhoneStep = {
  type: typeof SIGNUP_PHONE;
  sessionData: AuthenticationData;
};
export type SignupUsernameStep = {
  type: typeof SIGNUP_USERNAME;
  sessionData: AuthenticationData;
};
export type Step =
  | SignupEmailStep
  | SignupOptionsStep
  | SignupPhoneStep
  | SignupUsernameStep;

/*
 * Action Types
 */
export type GoToEmailAndPassword = {
  type: typeof GO_TO_EMAIL_AND_PASSWORD;
};
export type GoToPhoneVerifiation = {
  type: typeof GO_TO_PHONE_VERIFICATION;
  payload: {
    sessionData: AuthenticationData;
  };
};
export type GoToUsername = {
  type: typeof GO_TO_USERNAME;
  payload: {
    sessionData: AuthenticationData;
  };
};
export type ToggleWantsEmail = {
  type: typeof TOGGLE_WANTS_EMAIL;
};
export type Action =
  | GoToEmailAndPassword
  | GoToPhoneVerifiation
  | GoToUsername
  | ToggleWantsEmail;
