import React, { useState, useEffect, useCallback, ComponentType } from "react";
import type { UserSelf } from "storefront/User";
import { Authentication } from "storefront/Authentication";
import useAuthentication from "storefront/hooks/useAuthentication";
import useAnalytics from "storefront/hooks/useAnalytics";
import sync from "storefront/RecentlyViewed/sync";
import FlashManager from "storefront/lib/flash/FlashManager";
import type { AuthenticationData } from "../Data";
import AuthenticationModal from "../Modal";
import { Method } from "../AuthenticateUser/Method";
import { Verification } from "../Verification";
import { fromUserAndVerifications } from "../Need";
import accountModalOpenedEventCreator from "./accountModalOpenedEventCreator";
import type {
  TrackMessage,
  ModalTrigger,
} from "./accountModalOpenedEventCreator";
import {
  State,
  unknown,
  ready,
  authorizing,
  fromAuthentication,
} from "./State";

export type Callback = (user: UserSelf) => Promise<void> | void;

export type Options = {
  modalTrigger?: ModalTrigger;
  message?: TrackMessage;
  reload?: boolean;
  verifications?: Set<Verification>;
};

export type AuthWrapperProps = {
  authenticated: (callback: Callback, options?: Options) => () => void;
  authentication: Authentication;
};

type ExternalProps<OwnProps> = OwnProps & {
  authType?: Method;
  onRequestClose?: () => void;
};

export type WrappedComponentProps<OwnProps> = OwnProps & AuthWrapperProps;

/**
 * @name Authentication.authWrapper
 * @description A higher order component (HOC) that provides the wrapped component with the ability
 * to perform operations only for authenticated users.
 *
 * This ability is offered by the `authenticated` prop, which wraps the protected operation. When
 * the user is authenticated and the wrapped protected operation is called, it will proceed
 * normally. When the user is not authenticated, however, the authentication modal will be
 * presented to the user so that they can become authenticated; when they are successfully
 * authenticated, the protected operation will be called.
 *
 * @example
 * import authWrapper from "storefront/Authentication/authWrapper";
 *
 * type OwnProps = {|
 *   ...
 * |};
 *
 * type Props = {|
 *   ...AuthWrapperProps,
 *   ...OwnProps,
 * |};
 *
 * const MyComponent = ({ ..., authenticated }: Props) => {
 *   // ...
 *
 *   const [body, setBody] = useState("");
 *
 *   // ...
 *
 *   const onSubmitClick = authenticated((user: User): Promise<Comment> => {
 *     return API.postComment(user.id, body);
 *   });
 *
 *   // ...
 * };
 *
 * const MyWrappedComponent = authWrapper<OwnProps>(MyComponent);
 *
 * export default MyWrappedComponent;
 */

function authWrapper<OwnProps>(
  WrappedComponent: ComponentType<WrappedComponentProps<OwnProps>>,
): ComponentType<ExternalProps<OwnProps>> {
  const Wrapper = ({
    authType,
    onRequestClose,
    ...ownProps
  }: ExternalProps<OwnProps>) => {
    const { track } = useAnalytics();
    const auth = useAuthentication();
    const [state, setState] = useState<State>(unknown);

    useEffect(() => {
      setState(fromAuthentication(auth));
    }, [auth]);

    const protectedOperationWrapper = useCallback(
      (protectedOperation: Callback, options: Options = {}) =>
        (): void => {
          if (state._tag !== "Ready") return;

          const {
            modalTrigger = "logged_in_action",
            message = "sign_up",
            reload,
            verifications = new Set([]),
          } = options;

          const { currentUser } = state;
          const need = fromUserAndVerifications(currentUser, verifications);

          if (!!currentUser && !need) {
            protectedOperation(currentUser);
          } else {
            track(
              accountModalOpenedEventCreator(
                currentUser,
                window.location.pathname,
                message,
                modalTrigger,
              ),
            );

            setState(
              authorizing(
                protectedOperation,
                verifications,
                reload !== false,
                currentUser,
              ),
            );
          }
        },
      [state, track],
    );

    switch (state._tag) {
      case "Ready":
        return (
          // @ts-expect-error doesn't like ownProps type pls fix
          <WrappedComponent
            {...ownProps}
            authentication={auth}
            authenticated={protectedOperationWrapper}
          />
        );

      case "Authorizing": {
        const onSuccess = ({ user }: AuthenticationData): Promise<void> => {
          if (fromUserAndVerifications(user, state.verifications))
            return Promise.resolve();

          return state.callback(user) || Promise.resolve();
        };

        return (
          <>
            {/* @ts-expect-error doesn't like ownProps type pls fix */}
            <WrappedComponent
              {...ownProps}
              authentication={auth}
              authenticated={protectedOperationWrapper}
            />
            <AuthenticationModal
              authType={authType}
              isOpen
              onRequestClose={(): void => {
                setState(ready(state.currentUser));
                if (onRequestClose) onRequestClose();
              }}
              currentUser={state.currentUser}
              onSuccess={(data: AuthenticationData): void => {
                onSuccess(data)
                  .then(() => setState(ready(data.user)))
                  .then(() => sync(data.user))
                  .then(() => {
                    if (state.shouldReload) {
                      window.location.reload();
                    }
                  });
              }}
              onError={(error: Error): void => {
                const flashManager = FlashManager.getInstance();
                flashManager.alert(error.message);
              }}
              verifications={state.verifications}
            />
          </>
        );
      }

      case "Unknown":
      default:
        return null;
    }
  };

  return Wrapper;
}

export default authWrapper;
