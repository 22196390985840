/**
 * https://developers.facebook.com/docs/javascript/reference/FB.api/
 */
type Options<Params> = {
  path: string;
  method?: "get" | "post" | "delete";
  params?: Params;
};

function api<Response, Params>({
  path,
  method = "get",
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'Params'.
  params = {},
}: Options<Params>): Promise<Response> {
  return new Promise((resolve) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 4.
    window.FB.api(path, method, params, resolve);
  });
}

export default api;
