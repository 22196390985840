import { useMemo } from "react";
import {
  AutocompleteSource,
  GetSources,
  OnActiveParams,
  Reshape,
} from "@algolia/autocomplete-core";
import { AutocompleteQuerySuggestionsHit } from "@algolia/autocomplete-plugin-query-suggestions/dist/esm/types";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import { SearchClient } from "algoliasearch";
import { Hit } from "react-instantsearch-core";

import usePublicConfig from "storefront/hooks/usePublicConfig";
import titleCase from "storefront/lib/String/titleCase";
import useAnalytics from "storefront/hooks/useAnalytics";
import findAlgoliaIndexName from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import getProductListFrom from "storefront/Analytics/getProductListFrom";
import { Designer } from "storefront/Algolia/Designer";

import useCreateResultsSearchAndRedirect from "./useCreateResultsSearchAndRedirect";

type DesignerHit = Hit<Designer>;

export type SearchSuggestionClickedEvent = {
  object: "search_suggestion";
  action: "clicked";
  properties: {
    query: string;
    from: string;
    suggestion_type: "featured" | "popular" | "designer" | "recent";
  };
};

export type RecentSearchesItem = {
  id: string;
  label: string;
  category?: string;
};

export const isRecentSearchItem = (
  item: AutocompleteItem,
): item is RecentSearchesItem => "id" in item && "label" in item;

export type AutocompleteItem =
  | AutocompleteQuerySuggestionsHit
  | DesignerHit
  | RecentSearchesItem;

export const POPULAR_SEARCHES_ID = "suggestions";
export const DESIGNER_ID = "designers";
export const RECENT_SEARCHES_ID = "recent_searches";

// the order sources will show in the panel
// if source isnt in this array it will be last
export const SOURCE_ORDER = [
  RECENT_SEARCHES_ID,
  DESIGNER_ID,
  POPULAR_SEARCHES_ID,
];

export const HEADERS: Record<string, string> = {
  [DESIGNER_ID]: "Designers",
  [POPULAR_SEARCHES_ID]: "Popular Searches",
  [RECENT_SEARCHES_ID]: "Recent Searches",
};

const onActive = ({
  setQuery,
  itemInputValue,
}: OnActiveParams<AutocompleteItem>) => setQuery(itemInputValue);

type Props = {
  algoliaClient: SearchClient;
};

export const useAutocompleteSourcesPlugins = ({ algoliaClient }: Props) => {
  const createResultsSearchAndRedirect = useCreateResultsSearchAndRedirect();
  const publicConfig = usePublicConfig();
  const { track } = useAnalytics();

  const querySuggestionIndex =
    findAlgoliaIndexName("queries", "default")(publicConfig) || "";

  const designersIndex =
    findAlgoliaIndexName("designers", "default")(publicConfig) || "";

  // https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-plugin-recent-searches/createLocalStorageRecentSearchesPlugin/
  const recentSearchesPlugin = useMemo(
    () =>
      createLocalStorageRecentSearchesPlugin({
        key: "navbar",
        limit: 5,
        transformSource({ source }) {
          return {
            ...source,
            sourceId: RECENT_SEARCHES_ID,
            getItemInputValue({ item }) {
              return item.label;
            },
            onActive,
            onSelect({ itemInputValue, item }) {
              track({
                object: "search_suggestion",
                action: "clicked",
                properties: {
                  from: getProductListFrom(),
                  suggestion_type: "recent",
                  query: itemInputValue,
                },
              });
              if (item.category === "designer") {
                window.location.href = `/designers/${item.id}`;
              } else {
                createResultsSearchAndRedirect(itemInputValue);
              }
            },
          };
        },
      }),
    [],
  );

  const querySuggestionsPlugin = useMemo(
    () =>
      createQuerySuggestionsPlugin<AutocompleteQuerySuggestionsHit>({
        searchClient: algoliaClient,
        indexName: querySuggestionIndex,
        getSearchParams() {
          return { hitsPerPage: 8 };
        },
        transformSource({ source }) {
          return {
            ...source,
            sourceId: POPULAR_SEARCHES_ID,
            getItemInputValue({ item }) {
              return titleCase(item.query);
            },
            onActive,
            onSelect({ itemInputValue, item }) {
              track({
                object: "search_suggestion",
                action: "clicked",
                properties: {
                  from: getProductListFrom(),
                  suggestion_type: "popular",
                  query: itemInputValue,
                },
              });
              recentSearchesPlugin.data?.addItem({
                id: item.objectID,
                label: itemInputValue,
                category: "suggestion",
              });
              createResultsSearchAndRedirect(itemInputValue);
            },
          };
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const designersSource: AutocompleteSource<DesignerHit> = {
    sourceId: DESIGNER_ID,
    getItems({ query }) {
      return getAlgoliaResults({
        searchClient: algoliaClient,
        queries: [
          {
            indexName: designersIndex,
            query,
            params: {
              hitsPerPage: 5,
            },
          },
        ],
      });
    },
    getItemInputValue({ item }) {
      return titleCase(item.name);
    },
    onActive,
    onSelect({ itemInputValue, item }) {
      track({
        object: "search_suggestion",
        action: "clicked",
        properties: {
          from: getProductListFrom(),
          suggestion_type: "designer",
          query: itemInputValue,
        },
      });
      recentSearchesPlugin.data?.addItem({
        id: item.slug,
        label: itemInputValue,
        category: "designer",
      });
      window.location.href = `/designers/${item.slug}`;
    },
  };

  const getSources: GetSources<AutocompleteItem> = () => [designersSource];
  const plugins = [recentSearchesPlugin, querySuggestionsPlugin];

  return {
    getSources,
    plugins,
    recentSearchesPlugin,
  };
};

// https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-core/createAutocomplete/#param-reshape
export const reshape: Reshape<AutocompleteItem> = ({
  sources,
  sourcesBySourceId,
  state,
}) => {
  const recentSearchesSource = sourcesBySourceId[RECENT_SEARCHES_ID];
  return sources
    .filter((source) => {
      // Filter sources based on whether recent searches and query are present
      // if no recent searches return all
      if (
        !recentSearchesSource ||
        recentSearchesSource.getItems().length === 0
      ) {
        return true;
      }

      // if recent searches and no query return only recent searches
      if (state.query.length === 0) {
        return source.sourceId === RECENT_SEARCHES_ID;
      }

      return source.sourceId !== RECENT_SEARCHES_ID;
    })
    .sort((a, b) => {
      // Sort sources in order of their position in SOURCE_ORDER, put at end if not present
      const aIndex = SOURCE_ORDER.indexOf(a.sourceId);
      const bIndex = SOURCE_ORDER.indexOf(b.sourceId);

      if (aIndex === -1) return 1;

      if (bIndex === -1) return -1;

      return aIndex - bIndex;
    });
};
