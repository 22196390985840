import findAlgoliaIndexName from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import searchesCreate from "storefront/GrailedAPI/v1/Searches/create";

const useCreateResultsSearchAndRedirect = () => {
  const config = usePublicConfig();
  return (query: string) => {
    const algoliaIndex =
      findAlgoliaIndexName("listings", "default")(config) || "";

    const params = {
      indexName: algoliaIndex,
      query,
    };

    searchesCreate(params).then(({ data }) => {
      window.location.href = `/shop/${data.uuid}`;
    });
  };
};

export default useCreateResultsSearchAndRedirect;
