/**
 * @name Basics.Fields.PhotoInput
 * @description Provides a photo upload input and
 * display preview of it if an image is uploaded.
 */
import React from "react";
import classnames from "classnames";
import Image from "storefront/components/Image";
import PhotoUpload from "../../PhotoUpload";

type Dimensions = {
  width: number;
  height: number;
};
export type Props = {
  type: "photo-input";
  name: string;
  value: string;
  onChange: (arg0: string) => void;
  className?: string;
  isDisabled?: boolean;
  maxSize?: number;
  dimensions?: Dimensions;
  placeholder?: string;
};

/**
 * @name Basics.Field.Control.PhotoInput
 * @private
 * NOTE: maxSize and dimensions are only used to show a message,
 * they don't actually limit photo upload.
 */
const PhotoInput: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({
  value,
  onChange,
  className,
  placeholder,
  maxSize,
  dimensions,
}) => (
  <div className={classnames(className, "--photo-input")}>
    <div className="-image-input">
      <div className="-input-button">
        <PhotoUpload className="-input" onSuccess={onChange} />

        <div className="-upload _border">
          {value ? "Replace Photo" : "Upload Photo"}
        </div>
      </div>

      <p className="-image-rules">
        {dimensions && `Dimension ${dimensions.width}x${dimensions.height}.`}
        {` `}
        {maxSize && `Max file size is ${maxSize}kb.`}
      </p>
    </div>

    {value || placeholder ? (
      <Image
        className="-image-preview"
        alt="preview"
        src={value || placeholder || ""}
        width={50}
        quality={90}
        fit="crop"
      />
    ) : null}
  </div>
);

export default PhotoInput;
