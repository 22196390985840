import React from "react";
import cn from "classnames";
import { lowerCase } from "lodash/fp";
import { reduxForm, SubmissionError, InjectedFormProps } from "redux-form";
import { EmailCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import useAnalytics from "storefront/hooks/useAnalytics";
import SuspenseWithLoading from "storefront/components/SuspenseWithLoading";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import Button from "storefront/components/Button";
import Form, { Field } from "storefront/components/Form";
import { emailValidate, passwordValidate } from "../utils";
import { AuthenticationData } from "../Data";
import Errors, { BANNED } from "./Errors";
import InvisibleRecaptcha from "../InvisibleRecaptcha";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../Button.module.scss";

type OwnProps = {
  onSubmit: (
    credentials: EmailCredentials,
  ) => Promise<AuthenticationData | void>;
  submitText: string;
  onSignupClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

type Props = OwnProps & InjectedFormProps<EmailCredentials>;

const SignInForm = ({
  error,
  handleSubmit,
  invalid,
  onSignupClick,
  onSubmit,
  pristine,
  submitText,
  submitting,
}: Props) => {
  const { track } = useAnalytics();

  const onRecaptchaSuccess = (
    recaptchaToken: string | null | undefined,
  ): void => {
    const trackingFrom = lowerCase(submitText).replace(/ /gi, "");
    track(buttonClicked(submitText, trackingFrom));

    handleSubmit(({ email, password }) =>
      onSubmit({
        email,
        password,
        recaptchaToken: recaptchaToken || "",
        recaptchaType: "invisible",
        identityProvider: "email",
      }).catch((err) => {
        throw new SubmissionError({
          _error: err.message,
        });
      }),
    )();
  };

  return (
    <SuspenseWithLoading>
      <InvisibleRecaptcha onChange={onRecaptchaSuccess}>
        {({ execute, reset }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              reset();
              execute();
            }}
          >
            <Field
              name="email"
              label="Email Address"
              type="email"
              isSuccessful={error !== BANNED}
              validate={emailValidate}
            />
            <Field
              name="password"
              label="Password"
              type="password"
              isSuccessful={error !== BANNED}
              validate={passwordValidate}
            />
            <Button
              className={cn(styles.authButton, "FormSubmit")}
              size="large"
              type="submit"
              variant="primary"
              disabled={invalid || pristine || submitting}
              data-cy="auth-login-submit"
            >
              {submitText}
            </Button>
            {error ? (
              <Errors error={error} onSignupClick={onSignupClick} />
            ) : null}
          </Form>
        )}
      </InvisibleRecaptcha>
    </SuspenseWithLoading>
  );
};

export default reduxForm<EmailCredentials, OwnProps>({
  form: "signInForm",
})(SignInForm);
