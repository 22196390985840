import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import myGrailsStateFromWindow from "storefront/Onboarding/myGrailsStateFromWindow";
import type { GlobalState } from "storefront/GlobalState";
import { compose } from "../../../lib/Function";
import Tooltip from "../Tooltip";
import { onboarding } from "../../../GlobalState";
import getStateForFlow from "../../../Onboarding/getStateForFlow";
import type { State } from "../../../Onboarding/State";
import { showHeaderTooltip } from "../../../Onboarding/State";
import { headerTooltipClickedActionCreator } from "./HeaderTooltipClickedAction";
import { tooltipMountedActionCreator } from "./TooltipMountedAction";

type Props = {
  className?: string;
};

const onboardingStateSelector = compose(getStateForFlow("myGrails"))(
  onboarding,
);

const MyGrailsHeaderTooltip = ({ className }: Props) => {
  const onboardingState = useSelector<GlobalState, State>(
    onboardingStateSelector,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const myGrailsState = myGrailsStateFromWindow(window);
    dispatch(tooltipMountedActionCreator(myGrailsState));
  }, [dispatch]);

  const handleClick = compose(dispatch)(headerTooltipClickedActionCreator);

  return (
    <Tooltip
      className={className}
      hidden={onboardingState !== showHeaderTooltip}
      onTop
      onClick={handleClick}
    >
      Access your favorited listings here!
    </Tooltip>
  );
};

export default MyGrailsHeaderTooltip;
