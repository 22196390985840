/**
 * @name Analytics.getProductListFrom
 * @description Returns the "from" analytics value from the window for "product_list" events
 * @private
 * @return string
 */
const getProductListFrom = (): string =>
  window.location.pathname.split("/")[1] || "homepage";

export default getProductListFrom;
