import React from "react";
import classnames from "classnames";
import { isEmpty, isNumber } from "lodash/fp";
import Label from "./Label";
import Control from "./Control";
import Note from "./Note";
import Error from "./Error";
import Icons from "./Icons";
import type { Props as ControlProps } from "./Control";

/**
 * @name Field.Props
 * @description All of the props expected by this component, based on the props expected by the
 * children components.
 */
type Props = ControlProps & {
  baseClassName: string;
  name: string;
  label: string;
  isFocused?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isSuccessful?: boolean;
  error?: string;
  note?: string;
  isHideable?: boolean;
  isClearable?: boolean;
  onHideableToggleClick?: (arg0: React.SyntheticEvent<any>) => void;
  onClearButtonClick?: (arg0: React.SyntheticEvent<any>) => void;
};

const noop = () => {};

/**
 * @private
 * @description Returns the className for the Field based on the component's props. We should feel
 * free to use more of the component's props than just this subset to determine the className, if
 * we need; we should only make sure that whatever we do aligns with the pattern library.
 */
const className: (arg0: Props) => string = ({
  baseClassName,
  isFocused,
  isRequired,
  isDisabled,
  isSuccessful,
  value,
  error,
}) =>
  classnames({
    [baseClassName]: true,
    _focused: isFocused,
    _empty: isEmpty(value) && !isNumber(value),
    _error: !isEmpty(error),
    _required: isRequired,
    _disabled: isDisabled,
    _successful: isSuccessful,
  });

/**
 * @name Field
 * @description A pattern library approved Field component. Will render a label and control; either
 * an input, a select, or a textarea. Optionally, can render a note or an error, error will take
 * presedence over note. Can also render icons based on given props.
 */
const Field = (props: Props) => (
  <div className={className(props)}>
    {props.type !== "checkbox" ? (
      <Label name={props.name} label={props.label} />
    ) : null}

    {}
    <Control {...props} />

    {props.type === "checkbox" ? (
      <Label name={props.name} label={props.label} />
    ) : null}

    {props.note ? <Note note={props.note} /> : null}

    {props.error ? <Error error={props.error} /> : null}

    <Icons
      type={props.type}
      isHideable={!!props.isHideable}
      isClearable={!!props.isClearable}
      onHideableToggleClick={props.onHideableToggleClick || noop}
      onClearButtonClick={props.onClearButtonClick || noop}
    />
  </div>
);

export default Field;
