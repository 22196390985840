import type { Onboarding } from "./index";
import type { FlowName } from "./FlowName";
import type { State } from "./State";

const getStateForFlow =
  (name: FlowName) =>
  (onboarding: Onboarding): State =>
    onboarding[name];

export default getStateForFlow;
