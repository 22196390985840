import React from "react";
import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import LinkButton from "storefront/components/LinkButton";

type Props = {
  label: string;
  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  className?: string;
};

const TrackableMenuButtonItem = ({ className, label, onClick }: Props) => {
  const { track } = useAnalytics();

  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }

    track(buttonClicked(label, "global_header"));
  };

  return (
    <LinkButton onClick={handleClick} variant="inherit" className={className}>
      {label}
    </LinkButton>
  );
};

export default TrackableMenuButtonItem;
