import React from "react";
import { capitalize } from "lodash/fp";
import useCategories from "storefront/hooks/useCategories";
import type { CategoriesV2 } from "storefront/Categories";
import type { Department } from "storefront/Department";
import { WOMENSWEAR } from "storefront/Department";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import activeHeader from "../activeHeader";
import Item from "../Item";
import SubItem from "../SubItem";

const slugSelector = (name: string) => (categories: CategoriesV2) =>
  categories[name]?.slug;

const subcategoriesSelector = (name: string) => (categories: CategoriesV2) =>
  categories[name]?.subcategories;

type Props = {
  category: string;
  department: Department;
  expandedTopLevelCategory: string | null;
  setExpandedTopLevelCategory: (
    value: React.SetStateAction<string | null>,
  ) => void;
};

const TopLevelCategory = ({
  category,
  department,
  expandedTopLevelCategory,
  setExpandedTopLevelCategory,
}: Props) => {
  const { track } = useAnalytics();
  const categoriesResource = useCategories();

  if (categoriesResource.type !== "Completed") return null;

  const categories = categoriesResource.value;

  const label = capitalize(categories[category].name);
  const categorySlug = slugSelector(category)(categories);
  const subcategories = subcategoriesSelector(category)(categories);
  const maybeWomenswearPath = department === WOMENSWEAR ? "womenswear/" : "";

  return (
    <Item
      label={label}
      onClick={() => {
        track(
          navigationClicked(
            label,
            `sub_header_${department}_dropdown`,
            department,
          ),
        );
        setExpandedTopLevelCategory((current) =>
          current === label ? null : label,
        );
      }}
      isOpen={expandedTopLevelCategory === label}
    >
      <SubItem
        label={`All ${label}`}
        onClick={() => {
          track(
            navigationClicked(
              label,
              "sub_header_category_dropdown",
              department,
            ),
          );
        }}
        href={`/categories/${maybeWomenswearPath}${categorySlug}`}
        isActive={activeHeader() === categorySlug}
        from="sub_header"
      />
      {subcategories.map(({ slug, name }) => (
        <SubItem
          key={slug}
          label={name}
          onClick={() => {
            track(
              navigationClicked(
                name,
                "sub_header_category_dropdown",
                department,
              ),
            );
          }}
          href={`/categories/${maybeWomenswearPath}${slug}`}
          isActive={activeHeader() === slug}
          from="sub_header"
        />
      ))}
    </Item>
  );
};

export default TopLevelCategory;
