import type { Store } from "redux";
import type { Action } from "storefront/Action";
import { compose } from "../lib/Function";
import { safelySetItem } from "../lib/Storage";
import type { GlobalState } from "../GlobalState";
import { onboarding } from "../GlobalState";
import { unstarted } from "./State";
import getStateForFlow from "./getStateForFlow";

export const LOCAL_STORAGE_KEY = "onboarding_my_grails_state";
const getStateForMyGrails = getStateForFlow("myGrails");
const myGrailsStateSelector = compose(getStateForMyGrails)(onboarding);

const localStorageSubscriber = (store: Store<GlobalState, Action>) => () => {
  const state = store.getState();
  const myGrailsState = myGrailsStateSelector(state);
  if (myGrailsState === unstarted) return;
  safelySetItem(window.localStorage)(LOCAL_STORAGE_KEY, myGrailsState);
};

export default localStorageSubscriber;
