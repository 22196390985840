import React from "react";
import cn from "classnames";
import useAuthentication from "storefront/hooks/useAuthentication";
import type { WrappedComponentProps } from "storefront/components/Authentication/authWrapper";
import authWrapper from "storefront/components/Authentication/authWrapper";
import TrackableMenuItem from "storefront/components/Page/Header/TrackableMenuItem";
import Button from "storefront/components/Button";
import SmallTitle from "storefront/components/SmallTitle";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import styles from "./SellButton.module.scss";

type OwnProps = { className?: string };
type Props = WrappedComponentProps<OwnProps>;

const SellButton = ({ authenticated, className = "" }: Props) => {
  const { track } = useAnalytics();
  const authentication = useAuthentication();

  const handleClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    track(navigationClicked("Sell", "global_header"));

    authenticated(
      () => {
        window.location.href = "/sell/for-sale";
      },
      {
        modalTrigger: "sell_button",
        reload: false,
        verifications: new Set(["email"]),
      },
    )();
  };

  return (
    <div className={styles.box}>
      <TrackableMenuItem
        href="/sell/for-sale"
        rel="nofollow"
        label="Sell"
        onClick={handleClick}
        data-cy="sell"
        className={cn(styles.link, className)}
      >
        <Button size="small" variant="secondary" className={styles.button}>
          <SmallTitle className={styles.buttonText}>Sell</SmallTitle>
        </Button>
      </TrackableMenuItem>
    </div>
  );
};

export default authWrapper<OwnProps>(SellButton);
