import React, { MouseEvent } from "react";
import {
  AutocompleteCollection,
  AutocompleteState,
  GetItemProps,
  GetListProps,
  GetPanelProps,
} from "@algolia/autocomplete-core";

import Subhead from "storefront/components/Subhead";

import { AUTOCOMPLETE_ID } from "../index";
import ResultItem from "../ResultItem";
import {
  AutocompleteItem,
  HEADERS,
  isRecentSearchItem,
} from "../autocompleteSources";

import styles from "./Results.module.scss";

type Props = {
  panelProps: ReturnType<GetPanelProps<MouseEvent>>;
  collections: AutocompleteCollection<AutocompleteItem>[];
  listProps: ReturnType<GetListProps>;
  getItemProps: GetItemProps<AutocompleteItem, MouseEvent>;
  autocompleteState: AutocompleteState<AutocompleteItem>;
  removeRecentSearch: (id: string) => void;
};

const Results = ({
  panelProps,
  collections,
  listProps,
  getItemProps,
  autocompleteState,
  removeRecentSearch,
}: Props) => {
  const activeItemId =
    autocompleteState.activeItemId !== null
      ? `${AUTOCOMPLETE_ID}-item-${autocompleteState.activeItemId}`
      : null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles.panel} {...panelProps}>
      {collections.map((collection) => {
        const { source, items } = collection;
        const header = HEADERS[source.sourceId];

        return items.length > 0 ? (
          <section
            className={styles.collectionSection}
            key={`${AUTOCOMPLETE_ID}-${source.sourceId}`}
          >
            {header ? (
              <Subhead className={styles.header} as="div">
                {header}
              </Subhead>
            ) : null}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <ul {...listProps}>
              {items.map((item) => {
                const inputValue = source.getItemInputValue({
                  item,
                  state: autocompleteState,
                });

                return (
                  <ResultItem
                    key={`${source.sourceId}-${inputValue}`}
                    label={inputValue}
                    itemProps={getItemProps({ item, source })}
                    activeItemId={activeItemId}
                    sourceId={source.sourceId}
                    removeRecentSearch={() => {
                      return isRecentSearchItem(item)
                        ? removeRecentSearch(item.id)
                        : null;
                    }}
                  />
                );
              })}
            </ul>
          </section>
        ) : null;
      })}
    </div>
  );
};

export default Results;
