import React from "react";

type Props = {
  error: string;
};

/**
 * @name Basics.Field.Error
 * @private
 */
const Error: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ error }) => (
  <span className="--error">{error}</span>
);

export default Error;
