/* eslint-disable max-classes-per-file */
import {
  Credentials,
  IdentityCredentials,
} from "storefront/GrailedAPI/v1/Sessions/create";

/**
 * @name Users.Authentication.SignInError
 * @description This error is emitted when there is an error in the Sign In phase that doesn't fall
 * into the category of another error type.
 */
export class SignInError extends Error {
  type = "SignInError" as const;

  credentials: Credentials;

  constructor(credentials: Credentials, message = "") {
    super();
    this.name = "SignInError";
    this.credentials = credentials;
    this.message = message;
  }
}

/**
 * @name Users.Authentication.SignUpError
 * @description This error is emitted when there is an error in the Sign Up phase that doesn't fall
 * into the category of another error type.
 */
export class SignUpError extends Error {
  type = "SignUpError" as const;

  credentials: Credentials;

  constructor(credentials: Credentials, message = "") {
    super();
    this.name = "SignUpError";
    this.credentials = credentials;
    this.message = message;
  }
}

/**
 * @name Users.Authentication.UserNotFoundError
 * @description This error is emitted when the user attempts to sign-in with come credentials, but
 * we cannot find an account with those credentials.
 */
export class UserNotFoundError extends Error {
  type = "UserNotFoundError" as const;

  credentials: IdentityCredentials;

  constructor(credentials: IdentityCredentials) {
    super();
    this.name = "UserNotFoundError";
    this.credentials = credentials;
  }
}

/**
 * @name Users.Authentication.OtpRequiredError
 * @description This error is emitted when the user attempts to sign-in to an account that requires
 * a one time password (OTP), our scheme for two-factor authentication. As of 2018-09-25, only
 * Admin accounts are eligible for two-factor authentication.
 */
export class OtpRequiredError extends Error {
  type = "OtpRequiredError" as const;

  credentials: Credentials;

  constructor(credentials: Credentials) {
    super();
    this.name = "OtpRequiredError";
    this.credentials = credentials;
  }
}

/**
 * @name Users.Authentication.GoBackError
 * @description This error is emitted when the user decides to go back from the Sign Up screen to
 * the Sign In screen. It's not really an "error", but it fits well into the rest of the design of
 * the User.Authentication component.
 */
export class GoBackError extends Error {
  type = "GoBackError" as const;

  constructor() {
    super();
    this.name = "GoBackError";
  }
}

/**
 * @name Users.Authentication.AuthenticationError
 * @description A union of the errors for the Users.Authentication component.
 */
export type AuthenticationError =
  | SignInError
  | SignUpError
  | UserNotFoundError
  | OtpRequiredError
  | GoBackError;
