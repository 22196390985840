import React from "react";
import authWrapper from "../../../../Authentication/authWrapper";
import type { AuthWrapperProps } from "../../../../Authentication/authWrapper";
import TrackableMenuItem from "../../TrackableMenuItem";

type Props = AuthWrapperProps;
export const GLOBAL_HEADER_SIGNUP_ID = "global-header-signup-btn";

const Signup = ({ authenticated }: Props) => (
  <TrackableMenuItem
    href="/users/sign_up"
    onClick={(e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      authenticated(() => {}, {
        modalTrigger: "sign_up_button",
        verifications: new Set(["email"]),
      })();
    }}
    className="--button _small _strong"
    label="Sign up"
    data-testid="signup-btn"
    data-cy="signup-btn"
    id={GLOBAL_HEADER_SIGNUP_ID}
  >
    Sign up
  </TrackableMenuItem>
);

export default authWrapper(Signup);
