import React from "react";
import useAuthentication from "storefront/hooks/useAuthentication";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import AuthButtons from "./AuthButtons";
import MenuItem from "./MenuItem";
import MyGrailsHeart from "./MyGrailsHeart";
import Profile from "./Profile";

const AuthOrProfile = () => {
  const { track } = useAnalytics();
  const authentication = useAuthentication();
  if (authentication.type === "Loading") return null;

  if (authentication.type === "Authenticated") {
    return (
      <>
        <MenuItem
          href="/foryou"
          label="For You"
          onClick={() => track(navigationClicked("For You", "global_header"))}
        />
        <MyGrailsHeart />
        <Profile />
      </>
    );
  }

  return <AuthButtons />;
};

export default AuthOrProfile;
