import React from "react";

type Props = {
  className?: string;
  size?: number;
};

const InstagramLogo = ({ className, size = 15 }: Props) => (
  <svg
    className={className}
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8605 0H4.13937C1.85692 0 0 1.85701 0 4.13946V10.8605C0 13.1431 1.85692 15 4.13937 15H10.8605C13.1431 15 15 13.143 15 10.8605V4.13946C15.0001 1.85701 13.1431 0 10.8605 0ZM13.6692 10.8605C13.6692 12.4092 12.4092 13.6691 10.8605 13.6691H4.13937C2.59077 13.6692 1.33087 12.4092 1.33087 10.8605V4.13946C1.33087 2.59085 2.59077 1.33087 4.13937 1.33087H10.8605C12.4091 1.33087 13.6691 2.59085 13.6691 4.13946V10.8605H13.6692Z"
      fill="black"
    />
    <path
      d="M7.5 3.63506C5.36874 3.63506 3.63488 5.36892 3.63488 7.50018C3.63488 9.63135 5.36874 11.3651 7.5 11.3651C9.63126 11.3651 11.3651 9.63135 11.3651 7.50018C11.3651 5.36892 9.63126 3.63506 7.5 3.63506ZM7.5 10.0342C6.10267 10.0342 4.96575 8.89741 4.96575 7.50009C4.96575 6.10267 6.10258 4.96584 7.5 4.96584C8.89742 4.96584 10.0342 6.10267 10.0342 7.50009C10.0342 8.89741 8.89733 10.0342 7.5 10.0342Z"
      fill="black"
    />
    <path
      d="M11.5272 2.50657C11.2708 2.50657 11.0189 2.61037 10.8378 2.79226C10.6559 2.97326 10.5512 3.22524 10.5512 3.48254C10.5512 3.73904 10.6559 3.99093 10.8378 4.17282C11.0188 4.35382 11.2708 4.45851 11.5272 4.45851C11.7845 4.45851 12.0356 4.35382 12.2175 4.17282C12.3994 3.99093 12.5032 3.73895 12.5032 3.48254C12.5032 3.22524 12.3994 2.97326 12.2175 2.79226C12.0365 2.61037 11.7845 2.50657 11.5272 2.50657Z"
      fill="black"
    />
  </svg>
);

export default InstagramLogo;
