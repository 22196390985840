import React from "react";

import { parseDomain } from "storefront/lib/url";

import { TRANSFORMERS } from "./transformers";

import { ImageProps } from "./types";
import {
  DEFAULT_RESOLUTIONS,
  getDPRSrcSet,
  getPriorityAttrs,
  getSrcSet,
  getStyleAttr,
} from "./utils";

/**
 * @name Image
 * @deprecated
 * @description Deprecated -- use ImageV2 instead
 */
function Image({
  alt,
  priority,
  src,
  srcSet,
  unoptimized,
  breakpoints,
  fill,
  width,
  height,
  sizes,
  quality,
  transformer,
  fit = "crop",
  additionalCDNOptions,
  style = {},
  rotate,
  ...passedImageAttrbibutes
}: ImageProps) {
  // Warnings for development
  if (process.env.NODE_ENV !== "production") {
    // We want to encourage using alts for accessibility
    if (alt === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `Image with src "${src}" is missing an alt tag. Add one now to improve accessibility.`,
      );
    }

    // Discourage putting onClick directly on images
    if (passedImageAttrbibutes.onClick) {
      // eslint-disable-next-line no-console
      console.warn(
        `Image with src "${src}" has an onClick handler. This is not recommended as it can cause accessibility issues. Consider wrapping the image in a button instead. https://usability.yale.edu/web-accessibility/articles/links#image-links`,
      );
    }
  }

  const priorityAttrs = getPriorityAttrs(priority);

  const domain = parseDomain(src);
  const imageTransformer = transformer || (domain && TRANSFORMERS[domain]);

  let imgSrc = src;
  let imgSrcSet = srcSet;
  let imgSizes = sizes;

  const isFillOrNonIntrinsic = fill || (!width && !height);
  const hasAllNeededAttrs = Boolean(
    imgSrc?.length && imgSrcSet?.length && imgSizes?.length,
  );

  const imgSyles = {
    ...getStyleAttr({
      fit,
      fill,
      width,
      height,
      rotate: imageTransformer ? undefined : rotate,
    }),
    ...style,
  };

  if (!imageTransformer || unoptimized || hasAllNeededAttrs) {
    return (
      <img
        alt={alt}
        width={width}
        height={height}
        srcSet={imgSrcSet}
        sizes={imgSizes}
        style={imgSyles}
        {...priorityAttrs}
        {...passedImageAttrbibutes}
        src={imgSrc}
      />
    );
  }

  // Generate sizes if not provided
  // Fill or non-intrinsic height/width images should use 100vw
  if (!imgSizes && isFillOrNonIntrinsic) {
    imgSizes = "100vw";
  }

  let imgBreakpoints = breakpoints;

  if (!imgBreakpoints?.length && isFillOrNonIntrinsic) {
    imgBreakpoints = DEFAULT_RESOLUTIONS;
  }

  const heightRatio = height && width ? height / width : undefined;

  if (!imgSrcSet && imgBreakpoints?.length) {
    imgSrcSet = getSrcSet({
      src,
      widths: imgBreakpoints,
      heightRatio,
      quality,
      transformer: imageTransformer,
      additionalCDNOptions,
      rotate,
    });
  }

  if (!imgSrcSet && !isFillOrNonIntrinsic) {
    imgSrcSet = getDPRSrcSet({
      src,
      quality,
      transformer: imageTransformer,
      additionalCDNOptions,
      rotate,
      width,
      height,
    });
  }

  imgSrc = imageTransformer({
    url: src,
    width,
    height,
    quality,
    additionalCDNOptions,
    rotate,
  });

  return (
    <img
      alt={alt}
      width={width}
      height={height}
      srcSet={imgSrcSet}
      sizes={imgSizes}
      style={imgSyles}
      {...priorityAttrs}
      {...passedImageAttrbibutes}
      src={imgSrc}
    />
  );
}

export default Image;
