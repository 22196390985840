/* eslint-disable max-classes-per-file */

import { $PropertyType } from "utility-types";
import type { UserSelf } from "storefront/User";
import "storefront/User";
import type { AuthenticationData } from "storefront/components/Authentication/Data";
import "storefront/components/Authentication/Data";
import { post } from "storefront/GrailedAPI/request";
import GrailedAPIError from "storefront/GrailedAPI/Error";

export type EmailCredentials = {
  identityProvider: "email";
  email: string;
  password: string;
  otp?: string;
  optInEmails?: boolean;
  recaptchaToken: string;
  recaptchaType: "invisible" | "checkbox";
};
export type IdentityCredentials = {
  identityToken: string;
  identityProvider: "google" | "facebook" | "apple";
  email: string | null | undefined;
};
export type OtpCredentials = {
  identityProvider: "email";
  email: string;
  password: string;
  otp: string;
  optInEmails?: boolean;
};
export type Credentials =
  | OtpCredentials
  | EmailCredentials
  | IdentityCredentials;
export type IdentityProvider = $PropertyType<Credentials, "identityProvider">;

const path: (arg0: Credentials) => string = (credentials) => {
  switch (credentials.identityProvider) {
    case "facebook":
      return "/api/sign_in/facebook";

    case "apple":
    case "google":
      return "/api/sign_in/oauth";

    case "email":
    default:
      return "/api/sign_in";
  }
};

type Response = {
  data: {
    redirectTo: string;
    token: string;
    user: UserSelf;
  };
};

const unpack =
  (credentials: Credentials) =>
  (res: Response): AuthenticationData => ({
    redirectTo: res.data.redirectTo,
    token: res.data.token,
    user: res.data.user,
    credentials,
  });

export class UserNotFound extends Error {
  type: "UserNotFound" = "UserNotFound";

  credentials: Credentials;

  constructor(
    credentials: Credentials,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "No account found for the given credentials.",
  ) {
    super(message);
    this.name = "UserNotFound";
    this.credentials = credentials;
  }
}
export class OneTimePasswordRequired extends Error {
  type: "OneTimePasswordRequired" = "OneTimePasswordRequired";

  credentials: Credentials;

  constructor(
    credentials: Credentials,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "Your one time password is required.",
  ) {
    super(message);
    this.name = "OneTimePasswordRequired";
    this.credentials = credentials;
  }
}
export class Banned extends Error {
  type: "Banned" = "Banned";

  credentials: Credentials;

  // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
  constructor(credentials: Credentials, message? = "That account is banned.") {
    super(message);
    this.name = "Banned";
    this.credentials = credentials;
  }
}
export class InvalidCredentials extends Error {
  type: "InvalidCredentials" = "InvalidCredentials";

  credentials: Credentials;

  constructor(
    credentials: Credentials,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "The given credentials are invalid.",
  ) {
    super(message);
    this.name = "InvalidCredentials";
    this.credentials = credentials;
  }
}
export class NeedsPasswordReset extends Error {
  type: "NeedsPasswordReset" = "NeedsPasswordReset";

  credentials: Credentials;

  constructor(
    credentials: Credentials,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "You need to reset your password.",
  ) {
    super(message);
    this.name = "NeedsPasswordReset";
    this.credentials = credentials;
  }
}
export class ServiceUnavailable extends Error {
  type: "ServiceUnavailable" = "ServiceUnavailable";

  credentials: Credentials;

  constructor(
    credentials: Credentials,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "We could not log you in. Please try again later or use a different identity provider.",
  ) {
    super(message);
    this.name = "ServiceUnavailable";
    this.credentials = credentials;
  }
}
export class Unknown extends Error {
  type: "Unknown" = "Unknown";

  credentials: Credentials;

  constructor(
    credentials: Credentials,
    // @ts-expect-error ts-migrate(1015) FIXME: Parameter cannot have question mark and initialize... Remove this comment to see the full error message
    message? = "Something went wrong, but we're not sure what. We suggest trying again or contacting help@grailed.com if the problem continues.",
  ) {
    super(message);
    this.name = "Unknown";
    this.credentials = credentials;
  }
}
export type SessionCreateError =
  | UserNotFound
  | OneTimePasswordRequired
  | Banned
  | InvalidCredentials
  | NeedsPasswordReset
  | ServiceUnavailable
  | Unknown;

const create = (credentials: Credentials): Promise<AuthenticationData> =>
  post<Response>(path(credentials), credentials)
    .then(unpack(credentials))
    .catch((reason: unknown) => {
      if (typeof reason === "string") {
        throw new Unknown(credentials, reason);
      }

      if (!(reason instanceof GrailedAPIError)) {
        if (reason instanceof Error) {
          throw new Unknown(credentials, reason.message);
        }

        throw new Unknown(credentials);
      }

      const { error } = reason.body;

      if (!error) {
        throw new Unknown(credentials);
      }

      // NOTE: For this endpoint, we assume that the error is our deprecated GrailedError, which
      // has an error_id. If we're wrong, the default case will make sure things aren't too broken.

      switch (error.error_id) {
        case "not_found":
          throw new UserNotFound(credentials);

        case "otp_required":
          throw new OneTimePasswordRequired(credentials);

        case "unauthorized":
        case "forbidden":
          throw new InvalidCredentials(credentials, error.message);

        case "banned":
          throw new Banned(credentials);

        case "needs_password_reset":
          throw new NeedsPasswordReset(credentials, error.message);

        case "service_unavailble":
          throw new ServiceUnavailable(credentials, error.message);

        default:
          throw new Unknown(credentials, error.message);
      }
    });

export default create;
