import React from "react";
import { useTranslation } from "react-i18next";
import Content from "storefront/components/Modal/Content";
import Header from "storefront/components/Modal/Header";
import Body from "storefront/components/Modal/Body";
import styles from "./index.module.scss";

export const Footer = () => {
  const { t } = useTranslation("common");

  return (
    <p className={styles.legal}>
      By creating an account, I accept {`${t("MARKETPLACE_NAME")}'s`}{" "}
      <a href="/about/terms" target="_blank">
        Terms of Service
      </a>
      . For Grailed&apos;s Privacy Policy, click{" "}
      <a href="/about/privacy" target="_blank">
        here
      </a>
      .
    </p>
  );
};

export const Hr = () => <hr className={styles.lineBreak} />;

export const Message = ({ children }: { children: React.ReactNode }) => (
  <p className={styles.message}>{children}</p>
);

export const Error = ({ children }: { children: React.ReactNode }) => (
  <p className={styles.error}>{children}</p>
);

type LayoutProps = {
  title: React.ReactNode;
  children: React.ReactNode;
};

// TODO: @hsiung might be able to get rid of -container div
export const Layout = ({ children, title }: LayoutProps) => (
  <Content>
    <Header title={title} />
    <Body>
      <div
        className={styles.usersAuthentication}
        data-cy="users-authentication"
      >
        <div className={styles.container}>{children}</div>
      </div>
    </Body>
  </Content>
);
