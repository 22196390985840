import { isEmpty } from "lodash/fp";
import type { Id } from "../lib/Id";
import type { User } from "../User";
import { safelyRemoveItem } from "../lib/Storage";
import syncListingViews from "../GrailedAPI/v1/Users/RecentViews/Listings/syncListingViews";
import { LOCAL_STORAGE_KEY } from "./constants";
import getFromLocalStorage from "./getFromLocalStorage";

/**
 * Whenever a user views a listing, we add it to their recently viewed listings. If the user is
 * authenticated, we add it via the Grailed API; if the user is not authenticated, we add it to the
 * local storage in their browser.
 *
 * When an unauthenticated user becomes authenticated (by signing in, creating an account, etc.),
 * this function is used to synchronize any recently viewed listing in local storage with the API.
 */
const sync = (user?: User, win: typeof window = window): Promise<Set<Id>> => {
  if (!user || !user.id) return Promise.resolve(new Set());
  const recentlyViewed = getFromLocalStorage(win);
  if (isEmpty(recentlyViewed)) return Promise.resolve(new Set());
  safelyRemoveItem(win.localStorage)(LOCAL_STORAGE_KEY);
  return syncListingViews(user.id, recentlyViewed);
};

export default sync;
