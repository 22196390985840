import React, { useEffect, useState } from "react";
import { keys, pickBy } from "lodash/fp";
import { capitalize } from "lodash";
import useCategories from "storefront/hooks/useCategories";
import { deprecated__isWomenswearCategoryPath } from "storefront/Category/byPath";
import type { Department as DepartmentType } from "storefront/Department";
import { WOMENSWEAR, MENSWEAR } from "storefront/Department";
import DepartmentItem from "storefront/components/Page/Header/SlideOutMenu/Items/DepartmentItem";
import TopLevelCategory from "storefront/components/Page/Header/SlideOutMenu/Items/TopLevelCategory";
import type { Props as SlideOutMenuItemsProps } from "storefront/components/Page/Header/SlideOutMenu/SlideOutMenuItems";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";

type DepartmentProps = {
  department: DepartmentType;
  menswearDepartmentItemRef?: React.RefObject<HTMLHeadingElement>;
} & Omit<SlideOutMenuItemsProps, "isSignedIn">;

const Department = ({
  expandedSection,
  setExpandedSection,
  department,
  menswearDepartmentItemRef,
}: DepartmentProps) => {
  const { track } = useAnalytics();
  const categoriesResource = useCategories();
  const [expandedTopLevelCategory, setExpandedTopLevelCategory] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (expandedSection !== department) {
      setExpandedTopLevelCategory(null);
    }
  }, [expandedSection, department]);

  if (categoriesResource.type !== "Completed") return null;

  const categories = categoriesResource.value;

  const departmentCategories = pickBy(
    ({ path }) =>
      department === WOMENSWEAR
        ? deprecated__isWomenswearCategoryPath(path)
        : !deprecated__isWomenswearCategoryPath(path),
    categories,
  );
  const topLevelCategories = keys(departmentCategories);

  return (
    <DepartmentItem
      label={capitalize(department)}
      menswearDepartmentItemRef={
        department === MENSWEAR ? menswearDepartmentItemRef : undefined
      }
      onClick={() => {
        track(navigationClicked(department, "global_header", department));
        setExpandedSection((current) => {
          // this prevents some of the womenswear categories
          // from jumping offscreen when you switch from a menswear subcategory
          // to toggling womenswear categories to be expanded
          if (department === WOMENSWEAR && department !== current) {
            menswearDepartmentItemRef?.current?.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          }
          return current === department ? null : department;
        });
      }}
      isOpen={expandedSection === department}
    >
      {topLevelCategories.map((category) => (
        <TopLevelCategory
          key={category}
          category={category}
          department={department}
          expandedTopLevelCategory={expandedTopLevelCategory}
          setExpandedTopLevelCategory={setExpandedTopLevelCategory}
        />
      ))}
    </DepartmentItem>
  );
};

export default Department;
