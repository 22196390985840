import type { Designer } from "storefront/Designer";
import { camelize } from "camelscore";
import { prop, compose, compact } from "lodash/fp";
import "storefront/lib/Id";
import createInsecureAlgoliaClient from "storefront/Algolia/Client/createInsecureClient";
import { getAlgoliaIndexName } from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import getConfig from "storefront/GrailedAPI/v1/Config/getConfig";

/**
 * Returns an array of designers using the Designer Index
 @param {Array<string>} slugs - an array of slugs
 @return {Promise<Array<Designer>>}
*/
const fetchBySlugs = (slugs: Array<string>): Promise<Array<Designer>> => {
  const params = {
    filters: slugs.map((slug) => `slug:${slug}`).join(" OR "),
    hitsPerPage: slugs.length,
  };
  return Promise.all([createInsecureAlgoliaClient(), getConfig()]).then(
    ([client, config]) => {
      const indexName = getAlgoliaIndexName("designers", "default")(config);
      const index = client.initIndex(indexName);
      return index
        .search("", params)
        .then(compose([camelize, compact, prop("hits")]));
    },
  );
};

export default fetchBySlugs;
