import { forEach } from "lodash/fp";
import type {
  CategoryV2,
  TopLevelCategory,
  TopLevelCategoryV2,
} from "./Category";
import { IndexedList } from "./lib/IndexedList";

export type Categories = IndexedList<TopLevelCategory>;

export type CategoriesV2 = IndexedList<TopLevelCategoryV2>;

export const categorySlugs = (categories: CategoriesV2): Array<string> => {
  const slugs: Array<string> = [];

  forEach((topLevelCategory: TopLevelCategoryV2) => {
    slugs.push(topLevelCategory.slug);

    forEach((subCategory: CategoryV2) => {
      slugs.push(subCategory.slug);
    }, topLevelCategory.subcategories);
  }, categories);

  return slugs;
};
