import React, {
  FocusEvent,
  RefObject,
  BaseSyntheticEvent,
  MouseEvent,
  KeyboardEvent,
} from "react";
import cn from "classnames";
import { GetFormProps, GetInputProps } from "@algolia/autocomplete-core";

import searchBarClicked from "storefront/Analytics/EventCreators/searchBarClicked";
import useAnalytics from "storefront/hooks/useAnalytics";
import Button from "storefront/components/Button";

import styles from "./Form.module.scss";

export type Props = {
  formRef: RefObject<HTMLFormElement>;
  inputRef: RefObject<HTMLInputElement>;
  closeAutocomplete: () => void;
  isActive: boolean;
  formProps: ReturnType<GetFormProps<BaseSyntheticEvent>>;
  inputProps: ReturnType<
    GetInputProps<BaseSyntheticEvent, MouseEvent, KeyboardEvent>
  >;
};

const Form = ({
  formRef,
  closeAutocomplete,
  inputRef,
  isActive,
  formProps,
  inputProps,
}: Props) => {
  const { track } = useAnalytics();
  const onFormBlur = (e: FocusEvent<HTMLFormElement>) => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(window.document.activeElement)) {
        closeAutocomplete();
      }
    }, 0);
  };

  // remove default onblur so we can handle it with our form events
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick, onBlur, ...restOfInputProps } = inputProps;

  const inputOnClickWithTrack: typeof onClick = (e) => {
    track(searchBarClicked());
    onClick(e);
  };

  return (
    <form
      ref={formRef}
      className={styles.form}
      onBlur={onFormBlur}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formProps}
    >
      <input
        className={styles.input}
        ref={inputRef}
        onClick={inputOnClickWithTrack}
        data-testid="autocompleteInput"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfInputProps}
      />
      <Button
        className={cn(styles.searchButton, {
          [styles.active]: isActive,
        })}
        size="small"
        variant="secondary"
        type="submit"
        title="Submit"
      >
        Search
      </Button>
    </form>
  );
};

export default Form;
