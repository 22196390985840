import React, { forwardRef, HTMLAttributes } from "react";
import { Size, Variant, getClassName } from "../Button";

type Props = {
  href?: string;
  target?: string;
  rel?: string;
  size: Size;
  variant: Variant;
} & HTMLAttributes<HTMLAnchorElement>;

/**
 * This component has the look of a button but acts as an anchor tag.
 * As with all anchor tags, it requires an `href`.
 *
 * Another notable callout is the lack of a disabled state, since you cannot disable anchors.
 *
 * > It spreads additional props so you can add additional anchor attributes like `target`
 */
const ButtonLink = forwardRef<HTMLAnchorElement, Props>(
  ({ children, className, href, size, variant, ...props }, ref) => {
    return (
      <a // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={getClassName(size, variant, className, false)}
        href={href}
        ref={ref}
      >
        {children}
      </a>
    );
  },
);
export default ButtonLink;
