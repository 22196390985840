/* eslint-disable jsx-a11y/label-has-for */

/**
 * @name Basics.Fields.NestedRadio
 * @description Provides a set of nested radio inputs
 * where each item in the collection can also have a collection.
 * It can handle multi-level nested collection.
 */
import React from "react";
import { map } from "lodash/fp";
import classNames from "classnames";

type CollectionItem = {
  label: string;
  value: string;
  // eslint-disable-next-line no-use-before-define
  collection?: Collection;
};
type Collection = Array<CollectionItem>;
export type Props = {
  type: "nested-radio";
  collection: Collection;
  name: string;
  value: Array<string>;
  isDisabled?: boolean;
  onChange: (arg0: Array<string>) => void;
  onFocus?: (arg0: React.SyntheticEvent<any>) => void;
  onBlur?: (arg0: React.SyntheticEvent<any>) => void;
};

const NestedRadio = ({
  type,
  collection,
  name,
  value,
  isDisabled,
  onChange,
  onFocus,
  onBlur,
}: Props) => {
  const [currentValue, ...nestedValue] = value;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const renderCollectionItem = (item) => (
    <div
      className={classNames({
        "--collection-item": true,
        _leaf: !item.collection,
      })}
    >
      <label htmlFor={`${name}-${item.value}`} className="--label">
        {item.label}
      </label>

      <input
        id={`${name}-${item.value}`}
        type="radio"
        className="--input"
        name={name}
        value={item.value}
        disabled={isDisabled}
        checked={item.value === currentValue}
        onChange={() => onChange([item.value])}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {item.value === currentValue && item.collection ? (
        <NestedRadio
          type={type}
          collection={item.collection}
          name={`${currentValue}_${name}`}
          value={nestedValue}
          isDisabled={isDisabled}
          onChange={(newNestedValue) => {
            onChange([currentValue, ...newNestedValue]);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      ) : null}
    </div>
  );

  return (
    <div className="--nested-radio">
      {map(renderCollectionItem, collection)}
    </div>
  );
};

export default NestedRadio;
