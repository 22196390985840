import type { Id } from "../lib/Id";
import { safelyGetItem } from "../lib/Storage";
import { safelyParse } from "../lib/JSON";
import { LOCAL_STORAGE_KEY } from "./constants";

/**
 * Retreives the recently viewed listings array from local storage as a set
 * The set contains unique listing ids and sorted by listing view order
 */
const getFromLocalStorage = (win: typeof window): Set<Id> => {
  const item = safelyGetItem(win.localStorage)(LOCAL_STORAGE_KEY);
  const parsedItem = safelyParse<Array<Id>>(item);
  return new Set(parsedItem);
};

export default getFromLocalStorage;
