import React from "react";
import cn from "classnames";
import styles from "./ToggleCaret.module.scss";

type Props = {
  className?: string;
  direction?: "up" | "down";
  height?: number;
  width?: number;
};

const ToggleCaret = ({
  className,
  direction = "down",
  height = 4,
  width = 7,
}: Props) => (
  <svg
    className={cn(className, {
      [styles.up]: direction === "up",
      [styles.down]: direction === "down",
    })}
    height={`${height}px`}
    width={`${width}px`}
    aria-hidden="true"
    viewBox="0 0 7 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0L3.49216 3.49095L7 0H0Z"
      fill="black"
    />
  </svg>
);

export default ToggleCaret;
