import type { AuthResponse } from "./AuthResponse";

/**
 * Based on https://developers.facebook.com/docs/facebook-login/web/
 */
export type Success = {
  status: "connected";
  authResponse: AuthResponse;
};
export type Failure = {
  status: "not_authorized" | "unknown";
};
export type Response = Success | Failure;

/**
 * NOTE: We are hardcoding the options because we have no cases for using any other options. If we need to have different options for different logins across the site, we should make these options arguments to the `login` function.
 */
const options = {
  scope: "public_profile,user_friends,email",
  return_scopes: false,
};

const login = () =>
  new Promise<AuthResponse>((resolve, reject) => {
    const onLogin = (res: Response) => {
      if (res.status === "connected") {
        resolve(res.authResponse);
      } else {
        reject(res);
      }
    };

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
    window.FB.login(onLogin, options);
  });

export default login;
