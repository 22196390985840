import {
  find,
  first,
  startsWith,
  includes,
  compose,
  head,
  split,
  values,
} from "lodash/fp";
import { Categories, CategoriesV2 } from "storefront/Categories";
import { WOMENSWEAR } from "storefront/Department";
import {
  Category,
  CategoryV2,
  TopLevelCategory,
  TopLevelCategoryV2,
  AnyCategory,
  AnyCategoryV2,
  isCategoryV2,
  PATH_SEPARATOR,
} from "../index";

const NULL_CATEGORY: Category = {
  slug: "",
  name: "",
  path: "",
  avgShipping: 0,
};

const NULL_TOP_LEVEL_CATEGORY: TopLevelCategory = {
  name: "",
  path: "",
  slug: "",
  avgShipping: 0,
  subcategories: [],
};

/**
 * @name isTopLevelCategoryPath
 * @memberof Category
 * @description Category paths are made up of all of the levels separated by dots.
 * This returns true for "top level categories", which won't have a dot in them.
 */
export const isTopLevelCategoryPath = (path: string): boolean =>
  !includes(PATH_SEPARATOR, path);

/**
 * @name getTopLevelPath
 * @memberof Category
 * @param {string} path - a subcategory path. e.g. 'tops.short_sleeve_t_shirts'
 * @returns {string} - this returns the top level category path, or ''
 */
export const getTopLevelPath = (path: string): string =>
  compose([head, split(".")])(path);

/**
 * @name findTopLevelCategoryV2ByPath
 * @memberof Category
 * @param {string} path - a top-level category path. e.g. 'tops' or 'womens_tops'.
 * @param {Array<TopLevelCategoryV2>}
 * @returns {TopLevelCategoryV2} - this returns the top level category object if it's found
 * by the given path or null.
 */
export const findTopLevelCategoryV2ByPath = (
  path: string,
  categories: Array<TopLevelCategoryV2>,
): TopLevelCategoryV2 | null =>
  find(
    {
      path,
    },
    categories,
  ) || null;

/**
 * @name findTopLevelCategoryByPath
 * @memberof Category
 * @param {string} path - a top-level category path. e.g. 'tops' or 'womens_tops'.
 * @param {CategoriesV2} - indexed list of categories
 * @returns {TopLevelCategory} - this returns the top level category object if it's found
 * by the given path.
 */
export const findTopLevelCategoryByPath = (
  topLevelPath: string,
  categories: CategoriesV2,
): TopLevelCategoryV2 | null => {
  const maybeFound = values(categories).find(
    (item) => item.path === topLevelPath,
  );

  return maybeFound || null;
};

/**
 * BRIANA NOTE: y we we return NULL_TOP_LEVEL_CATEGORY here, a category object with null values?
 * @name findTopLevelCategoryByPathOrEmpty ----
 * please note we have a similar function that does not return an "empty" category object
 * @memberof Category
 * @param {string} path - a top-level category path. e.g. 'tops' or 'womens_tops'.
 * @param {Array<TopLevelCategory>}
 * @returns {TopLevelCategory} - this returns the top level category object if it's found
 * by the given path, or constant "NULL_TOP_LEVEL_CATEGORY".
 */
export const findTopLevelCategoryByPathOrEmpty: (
  arg0: string,
  arg1: Array<TopLevelCategory> | Array<TopLevelCategoryV2>,
) => TopLevelCategory | TopLevelCategoryV2 = (path, categories) =>
  find(
    {
      path,
    },
    categories,
  ) || NULL_TOP_LEVEL_CATEGORY;

/**
 * BRIANA NOTE: y we we return NULL_CATEGORY here, a category object with null values?
 * @name findSubcategoryByPath
 * @memberof Category
 * @param {string} path - this path is a subcategory path. e.g. 'tops.long_sleeve_shirts'
 * @param {TopLevelCategory} - we only need 'subcategories' in the TopLevelCategory.
 * @returns {Category} - this returns the category object if it's found
 * by the given path, or constant "NULL_CATEGORY".
 * NOTE: the category object is actually a subcategory as defined above.
 */
export const findSubcategoryByPath: (
  arg0: string,
  arg1: TopLevelCategory | TopLevelCategoryV2,
) => Category | CategoryV2 = (path, { subcategories }) =>
  find(
    {
      path,
    },
    subcategories,
  ) || NULL_CATEGORY;

/**
 * @name getCategoryByPath
 * @memberof Category
 * @param {string} categoryPath - this path can be either a top-level category path or a subcategory path.
 * @param {Array<TopLevelCategory> | Array<TopLevelCategoryV2>}
 * @returns {AnyCategory | AnyCategoryV2} - this invokes helper functions that we defined above.
 * It returns AnyCategory based on its findings.
 */
export const getCategoryByPath = (
  categoryPath: string,
  categories: Array<TopLevelCategory> | Array<TopLevelCategoryV2>,
): AnyCategory | AnyCategoryV2 => {
  if (!isTopLevelCategoryPath(categoryPath)) {
    const topLevelCategoryPath = first(categoryPath.split(PATH_SEPARATOR));
    const topLevelCategory = findTopLevelCategoryByPathOrEmpty(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      topLevelCategoryPath,
      categories,
    );

    const subcategory = findSubcategoryByPath(categoryPath, topLevelCategory);

    return subcategory;
  }

  return findTopLevelCategoryByPathOrEmpty(categoryPath, categories);
};

/**
 * DEPRECATED -- use new helper with same name!
 * or grab the department by finding the category!
 * */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const deprecated__isWomenswearCategoryPath = (path: string) =>
  startsWith("womens", path);

/**
 * @name isWomenswearCategoryPath
 * @memberof Category
 * @param {string} path - a top-level category or subcategory path. e.g. 'womens_tops' or 'tops.short_sleeve_t_shirts'.
 * @param {CategoriesV1 | CategoriesV2} categories
 * @returns {boolean} - if the category is womenswear
 */
export const isWomenswearCategoryPath = (
  path: string,
  categories: Categories | CategoriesV2,
): boolean => {
  const category = getCategoryByPath(path, values(categories));

  if (!isCategoryV2(category)) return false;

  return category.department === WOMENSWEAR;
};
