import React, { useState } from "react";
import cn from "classnames";
import SuspenseWithLoading from "storefront/components/SuspenseWithLoading";
import Modal from "storefront/components/Modal";
import Authentication, { Props as AuthProps } from "./index";
import styles from "./Modal.module.scss";

type Props = AuthProps & {
  isOpen: boolean;
  onRequestClose: () => void;
};

const AuthenticationModal = ({
  onSuccess,
  onError,
  isOpen,
  onRequestClose,
  currentUser,
  authType,
  verifications,
}: Props) => {
  const [isNotClosable, setHandleCloseButton] = useState<boolean>(true);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      preventCloseOnClickOutside={false}
      className={cn(styles.authenticationModal, "_hasHeader")}
      hideCloseButton={isNotClosable}
    >
      <SuspenseWithLoading message="Loading...">
        <Authentication
          currentUser={currentUser}
          onSuccess={onSuccess}
          onError={onError}
          authType={authType}
          onPhoneVerificationMount={() => setHandleCloseButton(false)}
          verifications={verifications}
        />
      </SuspenseWithLoading>
    </Modal>
  );
};

export default AuthenticationModal;
