import React, { useContext } from "react";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import { Context as AnalyticsContext } from "../../../../../hooks/useAnalytics";
import buttonClicked from "../../../../../Analytics/EventCreators/buttonClicked";
import Dropdown from "./Dropdown";

import styles from "./DesignersDropdown.module.scss";

const DesignersDropdown = () => {
  const { track } = useContext(AnalyticsContext);
  return (
    <div className={styles.category}>
      <span className={styles.link}>
        <a
          href="/designers"
          onClick={() => {
            track(buttonClicked("designers", "sub_header"));
            track(navigationClicked("designers", "sub_header"));
          }}
        >
          Designers
        </a>
      </span>

      <Dropdown className={styles.dropdown} />
    </div>
  );
};

export default DesignersDropdown;
