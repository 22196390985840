/* eslint-disable max-classes-per-file */
import type { Failure as FacebookLoginFailure } from "./login";
import "./login";

export class NotAuthorized extends Error {
  identityProvider: "facebook" = "facebook";

  type: "NotAuthorized" = "NotAuthorized";
}
export class Unknown extends Error {
  identityProvider: "facebook" = "facebook";

  type: "Unknown" = "Unknown";
}
export type FacebookIdentityProviderError = NotAuthorized | Unknown;
export const fromFacebookLoginFailure = (
  failure: FacebookLoginFailure,
): FacebookIdentityProviderError => {
  switch (failure.status) {
    case "not_authorized":
      return new NotAuthorized();

    default:
      return new Unknown();
  }
};
