import React from "react";
import { Icon as IconProps } from "storefront/Icon";

const Menu = ({ className }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="24" height="24" fill="white" />
    <path d="M3 5H21V6.5H3V5Z" fill="black" />
    <path d="M3 11.5H21V13H3V11.5Z" fill="black" />
    <path d="M3 18H21V19.5H3V18Z" fill="black" />
  </svg>
);

export default Menu;
