import { Method } from "./Method";

export type StartAuthenticateUserEvent = {
  object: "start_authenticate_user";
  action: "";
  properties: {
    initialMethod: Method;
  };
};

export const startAuthenticateUserEvent = (
  initialMethod: Method,
): StartAuthenticateUserEvent => ({
  object: "start_authenticate_user",
  action: "",
  properties: {
    initialMethod,
  },
});
