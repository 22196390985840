import "./CategoriesNavigation.scss";
import React, { Suspense, lazy } from "react";

import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import { StaffPicksLink, CollectionsLink, CyberSaleLink } from "./SimpleLinks";
import DesignersDropdown from "./DesignersDropdown";
import BrowseByCategoryDropdown from "./BrowseByCategoryDropdown";

const SneakersLink = lazy(() =>
  import(
    /* webpackChunkName: "SneakersLink" */
    "./SimpleLinks"
  ).then((module) => ({
    default: module.SneakersLink,
  })),
);

const CategoriesNavigation = () => {
  const cyberSale2024EnabledResource = useFeatureFlag(Feature.CYBER_SALE_2024);

  const holidayLink = React.useMemo(() => {
    if (cyberSale2024EnabledResource.type === "Loading") {
      return null;
    }

    if (
      cyberSale2024EnabledResource.type === "Completed" &&
      cyberSale2024EnabledResource.value
    ) {
      return <CyberSaleLink />;
    }

    return <StaffPicksLink />;
  }, [cyberSale2024EnabledResource]);

  return (
    <nav className="Page-Header-CategoriesNavigation">
      <div className="-outer">
        <DesignersDropdown />
        <BrowseByCategoryDropdown department="menswear" />
        <BrowseByCategoryDropdown department="womenswear" />
        <Suspense fallback={<></>}>
          <SneakersLink />
        </Suspense>
        {holidayLink}
        <CollectionsLink />
      </div>
    </nav>
  );
};

export default CategoriesNavigation;
