import React from "react";
import { map } from "lodash/fp";
import { WOMENSWEAR, Department } from "storefront/Department";
import { Category } from "storefront/Category";
import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";

type Props = {
  subcategories: Array<Category>;
  className: string;
  from: string;
  department: Department;
};

const SubcategoryDisplay = ({
  subcategories,
  className,
  from,
  department,
}: Props) => {
  const { track } = useAnalytics();

  const trackClick = (subcategory: Category) => {
    track(buttonClicked(subcategory.name, from));
    track(
      navigationClicked(
        subcategory.name,
        "sub_header_category_dropdown",
        department,
      ),
    );
  };

  return (
    <div className={className}>
      {map((subcategory) => {
        return (
          <a
            key={subcategory.name}
            className="-link"
            href={
              department === WOMENSWEAR
                ? `/categories/${WOMENSWEAR}/${subcategory.slug}`
                : `/categories/${subcategory.slug}`
            }
            onClick={() => trackClick(subcategory)}
          >
            {subcategory.name}
          </a>
        );
      }, subcategories)}
    </div>
  );
};

export default SubcategoryDisplay;
