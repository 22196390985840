import React from "react";
import useAuthentication from "storefront/hooks/useAuthentication";
import * as SubItems from "./SubItems";
import styles from "./Help.module.scss";

const Help = () => {
  const authentication = useAuthentication();

  return (
    <div className={styles.help}>
      <SubItems.Help />
      <SubItems.About />
      <SubItems.Accessibility />
      <SubItems.Terms />
      <SubItems.Privacy />
      {authentication.type === "Authenticated" ? <SubItems.SignOut /> : null}
    </div>
  );
};

export default Help;
