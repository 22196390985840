import React, { MouseEvent } from "react";
import cn from "classnames";
import { GetItemProps } from "@algolia/autocomplete-core";

import Callout from "storefront/components/Callout";
import Clear from "storefront/components/Icons/Navigation/X";

import { AutocompleteItem, RECENT_SEARCHES_ID } from "../autocompleteSources";

import styles from "./ResultItem.module.scss";

type Props = {
  itemProps: ReturnType<GetItemProps<AutocompleteItem, MouseEvent>>;
  sourceId: string;
  label: string;
  activeItemId: string | null;
  removeRecentSearch: () => void;
};

const ResultItem = ({
  itemProps,
  label,
  activeItemId,
  removeRecentSearch,
  sourceId,
}: Props) => {
  const { id, ...restOfProps } = itemProps;

  return (
    <li
      className={cn(styles.item, {
        [styles.active]: activeItemId === id,
      })}
      id={id}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
    >
      <Callout as="span" className={styles.itemLabel}>
        {label}
      </Callout>
      {sourceId === RECENT_SEARCHES_ID ? (
        <button
          className={styles.removeButton}
          onClick={(e) => {
            e.stopPropagation();
            removeRecentSearch();
          }}
          title="Remove recent search"
          type="button"
          tabIndex={-1}
        >
          <Clear />
        </button>
      ) : null}
    </li>
  );
};

export default ResultItem;
