export type ProductSearchedEvent = {
  object: "product";
  action: "searched";
  properties: {
    query: string;
    from: string;
  };
};

/**
 * @description Creates a "product searched" event that can be given to `track` to record the event with our third-party event tracking services.
 *
 * @example
 *   const event = productSearched("some test query", "some page");
 *   track(event);
 */
const productSearched = (
  query: string,
  from: string,
): ProductSearchedEvent => ({
  object: "product",
  action: "searched",
  properties: {
    query,
    from,
  },
});

export default productSearched;
