import { get } from "storefront/GrailedAPI/request";

export type Decision = string;
type Response = {
  data: Array<Decision>;
};

const unpack = ({ data }: Response): Array<Decision> => data;

export const CACHE_KEY = "get_sift_user_decisions";

/*
 * getDecisions = (): Promise<Array<Decision>>

 * Returns an array with the Sift Science decisions
 * applied on the currently authorized user.
 * Linking the Decision Status API docs below:
 * https://sift.com/developers/docs/ruby/decisions-api/decision-status
 */
const getUserDecisions = (): Promise<Array<Decision>> =>
  get<Response>("/api/sift/user_decisions").then(unpack);

export default getUserDecisions;
