import React, { useRef } from "react";
import Recaptcha from "react-google-recaptcha";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import styles from "./index.module.scss";

type Execute = () => void;
type Reset = () => void;
type Props = {
  onChange: (value: string | null | undefined) => void;
  children: (functions: { execute: Execute; reset: Reset }) => React.ReactNode;
};

// This component is to deal with the terrible ref api that this library uses
// We pass the execute method to children as function instead of being forced to use refs 🤮
const InvisibleRecaptcha = ({ onChange, children }: Props) => {
  const recaptchaRef = useRef<Recaptcha>(null);
  const publicConfig = usePublicConfig();
  const siteKey = publicConfig?.recaptcha?.siteKeyInvisible || "";

  const execute: Execute = () => {
    if (siteKey === "") {
      onChange(null);
    } else if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const reset: Reset = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <>
      {siteKey !== "" ? (
        <Recaptcha
          className={styles.recaptcha}
          sitekey={siteKey}
          ref={recaptchaRef}
          onChange={onChange}
          size="invisible"
        />
      ) : null}
      {children({
        execute,
        reset,
      })}
    </>
  );
};

export default InvisibleRecaptcha;
