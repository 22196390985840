import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { AutocompleteState } from "@algolia/autocomplete-core";
import { AutocompleteInstance } from "../index";
import Form from "../Form";
import Results from "../Results";
import Icon from "../Icon";
import { AutocompleteItem } from "../autocompleteSources";

import styles from "./Autocomplete.module.scss";

type Props = {
  autocomplete: AutocompleteInstance;
  autocompleteState: AutocompleteState<AutocompleteItem>;
  removeRecentSearch: (id: string) => void;
};

const Autocomplete = ({
  autocomplete,
  autocompleteState,
  removeRecentSearch,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { getEnvironmentProps } = autocomplete;

  const closeAutocomplete = () => {
    // set query to empty string
    autocomplete.setQuery("");
    // close
    autocomplete.setIsOpen(false);
    // reset active item
    autocomplete.setActiveItemId(null);
    // refresh data
    autocomplete.refresh();
  };

  // https://www.algolia.com/doc/ui-libraries/autocomplete/guides/creating-a-renderer/#mirroring-a-native-mobile-experience
  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });

    window.addEventListener("touchstart", onTouchStart);
    window.addEventListener("touchmove", onTouchMove);

    return () => {
      window.removeEventListener("touchstart", onTouchStart);
      window.removeEventListener("touchmove", onTouchMove);
    };
  }, [getEnvironmentProps, formRef, inputRef, panelRef]);

  const isActive =
    autocompleteState.isOpen || document.activeElement === inputRef.current;

  // This effect handles applying the _searching class to the overlay
  useEffect(() => {
    const overlay = window.document.getElementById("FullPageOverlay");
    if (overlay && isActive && autocompleteState.query.length > 0) {
      overlay.classList.add("_searching");
      overlay.tabIndex = -1;
      overlay.addEventListener("click", closeAutocomplete);
    } else if (overlay) {
      overlay.classList.remove("_searching");
      overlay.removeAttribute("tabIndex");
      overlay.removeEventListener("click", closeAutocomplete);
    }
    return () => {
      if (overlay) {
        overlay.classList.remove("_searching");
        overlay.removeAttribute("tabIndex");
        overlay.removeEventListener("click", closeAutocomplete);
      }
    };
  }, [autocompleteState.query, isActive]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div
      className={classnames(styles.root, {
        [styles.focused]: isActive,
      })}
      {...autocomplete.getRootProps({})}
    >
      <Icon
        isOpen={isActive}
        closeSearchHandler={closeAutocomplete}
        className={classnames(styles.iconButton)}
        inputIsFocused={document.activeElement === inputRef.current}
        labelProps={autocomplete.getLabelProps({})}
      />
      <Form
        formProps={autocomplete.getFormProps({
          inputElement: inputRef.current,
        })}
        inputProps={autocomplete.getInputProps({
          inputElement: inputRef.current,
        })}
        formRef={formRef}
        inputRef={inputRef}
        isActive={isActive}
        closeAutocomplete={closeAutocomplete}
      />

      {autocompleteState.isOpen ? (
        <Results
          panelProps={autocomplete.getPanelProps({})}
          collections={autocompleteState.collections}
          listProps={autocomplete.getListProps({})}
          getItemProps={autocomplete.getItemProps}
          autocompleteState={autocompleteState}
          removeRecentSearch={removeRecentSearch}
        />
      ) : null}
    </div>
  );
};

export default Autocomplete;
