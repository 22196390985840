import type { Algolia$Filters } from "storefront/Algolia";
import type { Search } from "storefront/Search";
import { post } from "storefront/GrailedAPI/request";

/**
 * https://www.grailed.com/internal/api-docs#tag/Searches/operation/createSearch
 */

type Params = {
  indexName: string;
  query: string;
  // TODO: DB Search filters are not 1:1 Algolia Filters.
  filters?: Algolia$Filters;
};
export type Response = {
  data: Search;
};

/**
 * @name GrailedAPI.v1.Searches.create
 * @param {Params} params
 * @param {string} params.indexName - the name of the Algolia index being used
 * @param {string} params.query - the search string, could be empty
 * @param {Algolia$Filters} params.filters - an object of active filters
 * @return {Promise<Response>} a promise that resolves with a response containing the new search
 */
const create = (params: Params): Promise<Response> =>
  post("/api/searches", params);

export default create;
