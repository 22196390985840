import React from "react";
import authWrapper from "../../../../Authentication/authWrapper";
import type { AuthWrapperProps } from "../../../../Authentication/authWrapper";
import TrackableMenuItem from "../../TrackableMenuItem";
import { GLOBAL_HEADER_SIGNUP_ID } from "./index";

type Props = AuthWrapperProps;

const SignupMobile = ({ authenticated }: Props) => (
  <TrackableMenuItem
    href="/users/sign_up"
    onClick={(e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      authenticated(() => {}, {
        modalTrigger: "sign_up_button",
        verifications: new Set(["email"]),
      })();
    }}
    className="--link -sign-up"
    label="Sign up"
    data-cy="signup-btn-mobile"
    id={GLOBAL_HEADER_SIGNUP_ID}
  >
    Sign up
  </TrackableMenuItem>
);

export default authWrapper(SignupMobile);
