import React from "react";

type Props = {
  name: string;
  label: string;
};

/**
 * @name Field.Label
 * @private
 */
const Label: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ name, label }) => (
  <label className="--label" htmlFor={name}>
    {label}
  </label>
);

export default Label;
