import type { PublicConfig } from "storefront/Config/PublicConfig";

/**
 * https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js
 */
const init = ({
  appleSignInClientId,
  appleSignInRedirectUri,
}: PublicConfig) => {
  if (!window.AppleID) throw new Error("AppleID is missing in window.");

  /**
   * We are hardcoding these values because we do not have any cases for initializing AppleID authentication with any other values. If we need to change that, we can refactor this to take the configuration as an argument.
   */
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
  window.AppleID.auth.init({
    clientId: appleSignInClientId,
    redirectURI: appleSignInRedirectUri,
    scope: "name email",
    usePopup: true,
  });
};

export default init;
