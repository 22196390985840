import React from "react";

type Props = {
  href: string;
  name: string;
  onClick?: (arg0: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
};

const Subcategory = ({ href, name, onClick, className }: Props) => (
  <a
    className={className}
    onClick={onClick}
    href={href}
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
    tabIndex="0" // Required by Safari in order to fire a focus event on click so we can close the dropdown
  >
    {name}
  </a>
);

export default Subcategory;
