import React from "react";
import cn from "classnames";
import { reduxForm, InjectedFormProps } from "redux-form";
import SuspenseWithLoading from "storefront/components/SuspenseWithLoading";
import {
  Credentials,
  EmailCredentials,
} from "storefront/GrailedAPI/v1/Sessions/create";
import BasicField from "storefront/components/deprecated_Field";
import Button from "storefront/components/Button";
import Form, { Field } from "storefront/components/Form";
import InvisibleRecaptcha from "../InvisibleRecaptcha";
import { emailValidate, passwordValidate } from "../utils";
import Errors from "./Errors";
import styles from "./SignUpForm.module.scss";
// eslint-disable-next-line css-modules/no-unused-class
import buttonStyles from "../Button.module.scss";

type OwnProps = {
  onSubmit: (credentials: Credentials) => void;
  submitText: string;
  onLoginClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  emailSubscriptionIsChecked: boolean;
  onEmailSubscriptionChange: (
    e: React.SyntheticEvent<HTMLInputElement>,
  ) => void;
};

type Props = OwnProps & InjectedFormProps<EmailCredentials>;

const SignUpForm = ({
  submitting,
  pristine,
  submitText,
  error,
  handleSubmit,
  onSubmit,
  onLoginClick,
  emailSubscriptionIsChecked,
  onEmailSubscriptionChange,
}: Props) => {
  const onRecaptchaSuccess = (
    recaptchaToken: string | null | undefined,
  ): void => {
    const optInEmails = emailSubscriptionIsChecked;
    handleSubmit(({ email, password }) =>
      onSubmit({
        email,
        password,
        optInEmails,
        recaptchaToken: recaptchaToken || "",
        recaptchaType: "invisible",
        identityProvider: "email",
      }),
    )();
  };

  return (
    <SuspenseWithLoading>
      <InvisibleRecaptcha onChange={onRecaptchaSuccess}>
        {({ execute, reset }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              reset();
              execute();
            }}
          >
            <Field
              name="email"
              label="Email Address"
              type="email"
              validate={emailValidate}
            />
            <Field
              name="password"
              label="Password"
              type="password"
              validate={passwordValidate}
            />
            <Button
              className={cn(buttonStyles.authButton, "FormSubmit")}
              size="large"
              type="submit"
              variant="primary"
              disabled={pristine || submitting}
              data-cy="auth-signup-submit"
            >
              {submitText}
            </Button>
            <div className={styles.emailNotification}>
              <BasicField
                baseClassName={styles.checkbox}
                label="Sign up for email to access sales, exclusive drops & more from Grailed. You can unsubscribe at any time."
                type="checkbox"
                value={emailSubscriptionIsChecked}
                name="notification-checkbox"
                onChange={onEmailSubscriptionChange}
              />
            </div>
            {error ? (
              <Errors error={error} onLoginClick={onLoginClick} />
            ) : null}
          </Form>
        )}
      </InvisibleRecaptcha>
    </SuspenseWithLoading>
  );
};

export default reduxForm<EmailCredentials, OwnProps>({
  form: "signUpForm",
})(SignUpForm);
