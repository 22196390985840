import { post } from "storefront/GrailedAPI/request";
import type { Lookup } from "storefront/Twilio/Lookup";

type Params = {
  verificationCode: string;
  countryCode: string;
  phoneNumber: string;
  countryAbbreviation: string;
  lookupData: Lookup | {};
};
type Response = {
  message: string;
  success: boolean;
};

/**
 * @name GrailedAPI.v1.Users.Verification.checkVerificationCode
 * @param {Params} params
 * @return {Promise<Response>} returns a response to notify success/failure of SMS code verification
 */
const checkVerificationCode: (arg0: Params) => Promise<Response> = (params) =>
  post("/api/users/verify_sms_code", params);

export default checkVerificationCode;
