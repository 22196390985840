import cn from "classnames";
import React, { useRef, useState } from "react";

import authenticationStarted, {
  AuthType,
} from "storefront/Analytics/EventCreators/authenticationStarted";

import { completeIdentityProviderEvent } from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/CompleteIdentityProviderEvent";
import { failIdentityProviderEvent } from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/FailIdentityProviderEvent";
import { IdentityCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import { IdentityProviderError } from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/Error";
import useAnalytics from "storefront/hooks/useAnalytics";
import useGoogleLogin from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/Google/useGoogleLogin";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../../Button.module.scss";

type Props = {
  authType: AuthType;
  className?: string;
  onError: (error: IdentityProviderError) => void;
  onSuccess: (credentials: IdentityCredentials) => void;
};

const Google = ({ authType, className, onError, onSuccess }: Props) => {
  const [hideProvider, setHideProvider] = useState<boolean>(false);

  const { track } = useAnalytics();
  const buttonRef = useRef<HTMLDivElement>(null);

  const method = authType === "signup" ? "CreateAccount" : "LogIn";

  const onGoogleLoginSuccess = (credentials: IdentityCredentials) => {
    track(completeIdentityProviderEvent(method, "google"));
    onSuccess(credentials);
  };

  const onGoogleLoginFailure = (error: IdentityProviderError) => {
    setHideProvider(true);
    track(failIdentityProviderEvent(method, error));
    onError(error);
  };

  useGoogleLogin({
    buttonRef,
    onClick: () => track(authenticationStarted("google", authType)),
    onSuccess: onGoogleLoginSuccess,
    onError: onGoogleLoginFailure,
  });

  return (
    <div
      // if google errors out, hide button - for layout purposes
      style={{ display: hideProvider ? "none" : "block" }}
      data-testid="googleAuthButton"
      className={cn(styles.authButton, styles.google, className)}
      ref={buttonRef}
    />
  );
};

export default Google;
