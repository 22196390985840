import React, { useEffect, useState } from "react";
import sendConfirmationEmail from "storefront/GrailedAPI/v1/Users/sendConfirmationEmail";
import useAnalytics from "storefront/hooks/useAnalytics";
import { Layout } from "../Layout";
import { startVerifyEmailEvent } from "./StartVerifyEmailEvent";
import Prompt from "./Prompt";
import Success from "./Success";
import Failure from "./Failure";

type Phase = "prompt" | "success" | "failure";

type Props = {
  email: string;
  isAfterSignIn?: boolean;
};

const VerifyEmail = ({ isAfterSignIn, email }: Props) => {
  const { track } = useAnalytics();
  const [phase, setPhase] = useState<Phase>("prompt");

  useEffect(() => {
    track(startVerifyEmailEvent);
  }, [track]);

  useEffect(() => {
    // Note: this only works / is relevant in the sign in/up flow (bc of how show_modal works)
    return () => {
      if (isAfterSignIn) {
        window.location.href = window.location.pathname;
      }
    };
  }, [isAfterSignIn]);

  const resend = () => {
    sendConfirmationEmail()
      .then((): Phase => "success")
      .catch((): Phase => "failure")
      .then(setPhase);
  };

  switch (phase) {
    case "prompt":
      return (
        <Layout title="Confirm Your Email">
          <Prompt email={email} onResendClick={resend} />
        </Layout>
      );

    case "success":
      return (
        <Layout title="Confirm Your Email">
          <Success email={email} />
        </Layout>
      );

    case "failure":
      return (
        <Layout title="Confirm Your Email">
          <Failure email={email} />
        </Layout>
      );

    default:
      return <></>;
  }
};

export default VerifyEmail;
