import React from "react";
import classnames from "classnames";

import styles from "./SocialLink.module.scss";

type SocialLinkProps = {
  href: string;
  children?: React.ReactNode;
  className?: string;
};

const SocialLink = ({ href, children, className }: SocialLinkProps) => (
  <a
    href={href}
    className={classnames(styles.socialLink, className)}
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    {children}
  </a>
);

export default SocialLink;
