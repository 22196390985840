import { ImageTransformer } from "../types";
import grailedImageSrc from "../imageSrc/grailedImageSrc";

const grailedTransformer: ImageTransformer = ({
  url,
  width,
  height,
  quality,
  rotate,
  dpr,
}) => {
  return grailedImageSrc(url, {
    width,
    height,
    quality,
    fit: "clip",
    rotate: rotate || undefined,
    dpr,
  });
};

export default grailedTransformer;
