import React, { useReducer, useContext } from "react";
import useSWR from "swr";
import { IdentityCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import { AuthenticationData } from "storefront/components/Authentication/Data";
import { AuthenticationError } from "storefront/components/Authentication/Error";
import { Context as AnalyticsContext } from "storefront/hooks/useAnalytics";
import { phoneVerificationEnabledAtSignUp } from "storefront/hooks/usePhoneVerification";
import PhoneVerification from "storefront/components/PhoneVerification";
import authenticationStarted from "storefront/Analytics/EventCreators/authenticationStarted";
import getUserDecisions, {
  CACHE_KEY,
  Decision,
} from "storefront/GrailedAPI/v1/Sift/getUserDecisions";
import SignUpOptions from "./SignUpOptions";
import SignUpEmail from "./SignUpEmail";
import Username from "./Username";
import reducer, { initialStep } from "./reducer";
import { Layout, Footer } from "../Layout";
import { Step } from "./actions";

type Props = {
  credentials?: IdentityCredentials;
  onLoginClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  onSuccess: (data: AuthenticationData) => void;
  onError: (error: AuthenticationError) => void;
  onPhoneVerificationMount?: () => void;
  onSignUpError: (error: AuthenticationError) => void;
};

type UseSignupStepsValues = {
  currentStep: Step;
  goToEmailAndPassword: () => void;
  goToPhoneVerification: (data: AuthenticationData) => void;
  goToUsername: (data: AuthenticationData) => void;
  toggleWantsEmail: () => void;
};

const useSignupSteps = (): UseSignupStepsValues => {
  const { track } = useContext(AnalyticsContext);
  const [currentStep, dispatch] = useReducer(reducer, initialStep);

  const goToEmailAndPassword = (): void => {
    track(authenticationStarted("email", "signup"));
    dispatch({
      type: "GO_TO_EMAIL_AND_PASSWORD",
    });
  };

  const goToPhoneVerification = (sessionData: AuthenticationData): void => {
    dispatch({
      type: "GO_TO_PHONE_VERIFICATION",
      payload: {
        sessionData,
      },
    });
  };

  const goToUsername = (sessionData: AuthenticationData): void => {
    dispatch({
      type: "GO_TO_USERNAME",
      payload: {
        sessionData,
      },
    });
  };

  const toggleWantsEmail = (): void => {
    dispatch({
      type: "TOGGLE_WANTS_EMAIL",
    });
  };

  return {
    currentStep,
    goToEmailAndPassword,
    goToPhoneVerification,
    goToUsername,
    toggleWantsEmail,
  };
};

const SignUpMultiStep = ({
  credentials: identityCredentials,
  onLoginClick,
  onSuccess,
  onError,
  onPhoneVerificationMount,
  onSignUpError,
}: Props) => {
  const {
    currentStep,
    goToEmailAndPassword,
    goToPhoneVerification,
    goToUsername,
    toggleWantsEmail,
  } = useSignupSteps();

  const { data: decisions } = useSWR<Array<Decision>, Error>(
    CACHE_KEY,
    getUserDecisions,
  );

  const displayPhoneVerificationStep = (): boolean =>
    phoneVerificationEnabledAtSignUp(decisions);

  const handleEmailSignup = (data: AuthenticationData) => {
    if (data.token) {
      onSuccess(data);
    } else {
      goToUsername(data);
    }
  };

  const onComplete = (data: AuthenticationData): void => {
    onSuccess(data);
  };

  const onUsernameSuccess = (data: AuthenticationData): void => {
    if (displayPhoneVerificationStep()) {
      goToPhoneVerification(data);
    } else {
      onComplete(data);
    }
  };

  switch (currentStep.type) {
    case "SIGNUP_EMAIL":
      return (
        <Layout title="Create an Account">
          <SignUpEmail
            onSuccess={handleEmailSignup}
            onLoginClick={onLoginClick}
            emailSubscriptionIsChecked={currentStep.wantsEmail}
            onEmailSubscriptionChange={toggleWantsEmail}
          />
          <Footer />
        </Layout>
      );

    case "SIGNUP_PHONE":
      return (
        <Layout title="Verify Your Phone Number">
          <PhoneVerification
            handleSubmit={() => onComplete(currentStep.sessionData)}
            onMount={onPhoneVerificationMount}
          />
        </Layout>
      );

    case "SIGNUP_USERNAME":
      return (
        <Layout title="Create a Username">
          <Username
            userId={currentStep.sessionData.user.id}
            onSuccess={() => onUsernameSuccess(currentStep.sessionData)}
          />
        </Layout>
      );

    case "SIGNUP_OPTIONS":
    default:
      return (
        <Layout title="Create an Account">
          <SignUpOptions
            credentials={identityCredentials}
            displayPhoneVerificationStep={displayPhoneVerificationStep}
            goToPhoneVerification={goToPhoneVerification}
            onSuccess={onSuccess}
            onError={onError}
            onLoginClick={onLoginClick}
            onEmailClick={goToEmailAndPassword}
            onSignUpError={onSignUpError}
          />
          <Footer />
        </Layout>
      );
  }
};

export default SignUpMultiStep;
