import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import createSession, {
  Credentials,
  SessionCreateError,
} from "storefront/GrailedAPI/v1/Sessions/create";
import Button from "storefront/components/Button";
import { IdentityProviderError } from "../AuthenticateUser/IdentityProvider/Error";
import GoogleLogin from "../AuthenticateUser/IdentityProvider/Google";
import FacebookLogin from "../AuthenticateUser/IdentityProvider/Facebook";
import AppleLogin from "../AuthenticateUser/IdentityProvider/Apple";
import {
  AuthenticationError,
  OtpRequiredError,
  SignInError,
  UserNotFoundError,
} from "../Error";
import { AuthenticationData } from "../Data";
import { Message, Hr } from "../Layout";
import Errors, { BANNED } from "./Errors";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../Button.module.scss";

const LOGIN = "login" as const;

type Props = {
  onSuccess: (data: AuthenticationData) => void;
  onError: (error: AuthenticationError) => void;
  useSetStep: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSignupClick: (e: React.SyntheticEvent) => void;
};

const SignInOptions = ({
  onSuccess,
  onError,
  useSetStep,
  onSignupClick,
}: Props) => {
  const { t } = useTranslation("common");
  const [error, setError] = useState<string | null>(null);

  const handleIdentityProviderSuccess = (credentials: Credentials) => {
    setError(null);
    createSession(credentials)
      .then(onSuccess)
      .catch((err: SessionCreateError) => {
        switch (err.type) {
          case "UserNotFound":
            if (err.credentials.identityProvider !== "email") {
              onError(new UserNotFoundError(err.credentials));
            }

            break;

          case "OneTimePasswordRequired":
            onError(new OtpRequiredError(credentials));
            break;

          case "InvalidCredentials":
            setError(err.message);
            break;

          case "Banned":
            setError(BANNED);
            break;

          default:
            onError(new SignInError(credentials, err.message));
            break;
        }
      });
  };

  const handleIdentityProviderError = ({ message }: IdentityProviderError) => {
    if (message) setError(message);
  };

  return (
    <>
      <Message>
        Log in to your {t("MARKETPLACE_NAME")} account to buy, sell, comment,
        and more.
      </Message>
      {error ? (
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Errors error={error} />
        </div>
      ) : null}
      <FacebookLogin
        onSuccess={handleIdentityProviderSuccess}
        onError={handleIdentityProviderError}
        authType={LOGIN}
      >
        Continue with Facebook
      </FacebookLogin>
      <AppleLogin
        onSuccess={handleIdentityProviderSuccess}
        onError={handleIdentityProviderError}
        authType={LOGIN}
      >
        Continue with Apple
      </AppleLogin>
      <GoogleLogin
        onSuccess={handleIdentityProviderSuccess}
        onError={handleIdentityProviderError}
        authType={LOGIN}
      />
      <Hr />

      <Button
        variant="primary"
        size="large"
        className={styles.authButton}
        onClick={useSetStep}
        data-cy="login-with-email"
      >
        Log in with Email
      </Button>

      <Message>
        Don&apos;t have an account?{" "}
        <a
          href="/users/sign_up"
          onClick={(event) => {
            event.preventDefault();
            onSignupClick(event);
          }}
        >
          Sign Up
        </a>
      </Message>
    </>
  );
};

export default SignInOptions;
