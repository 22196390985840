import React from "react";
import Caret from "storefront/components/Icons/Navigation/Caret";
import HideableToggle from "./HideableToggle";
import ClearButton from "./ClearButton";
import { TypeValue } from "../Control";

type Props = {
  type: TypeValue;
  isHideable: boolean;
  isClearable: boolean;
  onHideableToggleClick: (e: React.SyntheticEvent<HTMLElement>) => void;
  onClearButtonClick: (e: React.SyntheticEvent<HTMLElement>) => void;
};

/**
 * @name Basics.Field.Icons
 * @private
 * @description Returns child icons based on the given props. Currently:
 *   - renders a select arrow when the Field is a 'select' type,
 *   - renders a 'Show'/'Hide' toggle when the Field is hideable, and
 *   - renders a 'Clear' button when the Field is clearable.
 */
const Icons = ({
  type,
  isHideable,
  onHideableToggleClick,
  isClearable,
  onClearButtonClick,
}: Props) => (
  <div className="--icons">
    {type === "select" ? (
      <Caret direction="down" width="10px" height="7px" />
    ) : null}

    {isHideable ? (
      <HideableToggle type={type} onClick={onHideableToggleClick} />
    ) : null}

    {isClearable ? <ClearButton onClick={onClearButtonClick} /> : null}
  </div>
);

export default Icons;
