import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import TrackableMenuItem from "../../TrackableMenuItem";
import imageUrlSelector from "../../imageUrlSelector";
import usernameSelector from "../../usernameSelector";
import hasNotificationsSelector from "../../hasNotificationsSelector";
import DropdownMenu from "./DropdownMenu";

const className = (hasNotifications: boolean): string =>
  cn("--profile", {
    _notified: hasNotifications,
  });

const Profile = () => {
  const { track } = useAnalytics();
  const imageUrl = useSelector(imageUrlSelector);
  const username = useSelector(usernameSelector);
  const hasNotifications = useSelector(hasNotificationsSelector);

  return (
    <div className={className(hasNotifications)}>
      <TrackableMenuItem
        href="/messages"
        onClick={() =>
          track(navigationClicked("Profile User", "global_header"))
        }
        className="--profile-user"
        label="Profile User"
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ alt: string; className: string; style: { b... Remove this comment to see the full error message
          alt={username}
          className="--image"
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
          role="button"
          tabIndex={0}
        />
      </TrackableMenuItem>

      <DropdownMenu className="DropdownMenu" />
    </div>
  );
};

export default Profile;
