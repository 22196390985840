/**
 * @name GlobalHeader.SlideOutMenu
 * @description Uses the current location to determine the active header. The active header is used
 * by some Items and SubItems to decide whether they should be active.
 */
const activeHeader = (): string => {
  const pathAndHashArr =
    `${window.location.pathname}${window.location.hash}`.split("/");
  return pathAndHashArr[pathAndHashArr.length - 1];
};

export default activeHeader;
