import React from "react";
import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";

type Props = {
  href?: string;
  label: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
  from?: string;
  rel?: string;
} & React.HTMLAttributes<HTMLAnchorElement>;

/**
 * Create a menu item (anchor) that tracks clicks via the onMouseDown event
 * @param {string} href - the url for the link
 * @param {string} label - this acts as the name for the event being
 * tracked, and optionally the text of the link (if the children? param is
 * not present.
 * @param {string} className - any css classes to be applied to the anchor link
 * @param {any} children - if this item wraps additional elements, they are
 * passed here.
 * @param onClick {Function} - a callback optionally passed to the element
 * @return {React$Element} - an anchor tag wrapping the content
 */
const TrackableMenuItem = ({
  children,
  className,
  from,
  href,
  label,
  onClick,
  ...props
}: Props) => {
  const { track } = useAnalytics();

  const clickHandler = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }

    track(buttonClicked(label, from || "global_header"));
  };

  return (
    <a
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      href={href}
      className={className}
      onClick={clickHandler}
      title={label}
      aria-label={label}
    >
      {children || label}
    </a>
  );
};

export default TrackableMenuItem;
