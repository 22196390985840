import type { Id } from "../../../../../lib/Id";
import { post } from "../../../../request";

type Data = {
  listingIds: Array<Id>;
};
type Response = {
  data: Data;
};

const unpack = (res: Response): Set<Id> => new Set(res.data.listingIds);

const syncListingViews = (
  userId: Id,
  listingIds: Set<Id>,
): Promise<Set<Id>> => {
  const path = `/api/users/${userId}/recent_views/listings/sync`;
  return post<Response>(path, {
    listingIds: Array.from(listingIds),
  }).then(unpack);
};

export default syncListingViews;
