import jwtDecode, { InvalidTokenError } from "jwt-decode";
import { Result, success, failure } from "storefront/lib/Result";

export const parse = <T>(token: string): Result<InvalidTokenError, T> => {
  try {
    return success(jwtDecode<T>(token));
  } catch (error) {
    return failure(error);
  }
};
