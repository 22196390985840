import React from "react";
import { pickBy } from "lodash/fp";
import { Department, WOMENSWEAR } from "storefront/Department";
import { deprecated__isWomenswearCategoryPath } from "storefront/Category/byPath";
import useCategories from "storefront/hooks/useCategories";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import ToggleCaret from "../../../../Icons/ToggleCaret";
import CategoryPopover from "./CategoryPopover";

import "./BrowseByCategoryDropdown.scss";

type Props = {
  department: Department;
};

const BrowseByCategoryDropdown = ({ department }: Props) => {
  const { track } = useAnalytics();
  const categories = useCategories();
  if (categories.type !== "Completed") return null;

  const departmentCategories = pickBy(
    ({ path }) =>
      department === WOMENSWEAR
        ? deprecated__isWomenswearCategoryPath(path)
        : !deprecated__isWomenswearCategoryPath(path),
    categories.value,
  );

  const trackClick = () => {
    track(buttonClicked("All Categories", "global_header"));
    track(
      navigationClicked(
        "All Categories",
        "sub_header_category_dropdown",
        department,
      ),
    );
  };

  return (
    <div className="-category _browse-by-category">
      <span className="--link" tabIndex={0}>
        {department}
        <ToggleCaret className="-toggle" />
      </span>
      <div className="-dropdown _category">
        <a
          className="-link"
          href={
            department === WOMENSWEAR
              ? `/categories/${WOMENSWEAR}/all`
              : "/categories/all"
          }
          onClick={trackClick}
        >
          All Categories
        </a>
        {Object.values(departmentCategories).map((category, index) => (
          <CategoryPopover
            key={category.name}
            category={category}
            department={department}
            // Lower the subcategory container if the number of subcategories is fewer than the index number of the current category
            // This will help users navigate their mouse to be able to hover over the subcategory menu
            // https://app.shortcut.com/grailed-team/story/95051/hard-to-click-dresses-jewelry-womenswear-categories-in-top-nav
            shouldLowerSubcategoryContainer={
              category.subcategories.length <= index + 1
            }
          />
        ))}
      </div>
    </div>
  );
};

export default BrowseByCategoryDropdown;
