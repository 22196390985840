import React from "react";
import classnames from "classnames";
import TrackableMenuItem from "../TrackableMenuItem";

type Props = {
  label: string;
  href?: string;
  isActive?: boolean;
  hasNotifications?: boolean;
  onClick?: (arg0: React.SyntheticEvent<any>) => void;
  from?: string;
};

/**
 * @private
 */
const className = (isActive: boolean, hasNotifications: boolean): string =>
  classnames({
    "--sub-item": true,
    _active: isActive,
    _notifications: hasNotifications,
  });

/**
 * @name GlobalHeader.SlideOutMenu.SubItem
 */
const SubItem = ({
  label,
  href,
  isActive = false,
  hasNotifications = false,
  onClick,
  from = "global_header",
}: Props) => (
  <TrackableMenuItem
    href={href}
    label={label}
    className={className(isActive, hasNotifications)}
    onClick={onClick}
    from={from}
  />
);

export default SubItem;
