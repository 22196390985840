import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "storefront/GlobalState";
import { SavedSearch } from "storefront/SavedSearch";
import { Id } from "storefront/lib/Id";
import { safelyGetItem, safelySetItem } from "storefront/lib/Storage";
import usePrevious from "storefront/hooks/usePrevious";
import Heart from "storefront/components/Icons/Heart";
import { MyGrailsHeaderTooltip } from "storefront/components/Onboarding/MyGrails";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import TrackableMenuItem from "../TrackableMenuItem";
import SavedSearchesTooltip from "./SavedSearchesTooltip";
import styles from "./MyGrailsHeart.module.scss";

const userIdSelector = (state: GlobalState): Id => state.session.currentUser.id;

const savedSearchesSelector = (state: GlobalState): Array<SavedSearch> =>
  state.filters.savedSearches;

const SS_TOOLTIP_LOCAL_STORAGE_KEY = "savedSearchesTooltipDisplayed";

const MyGrailsHeart = () => {
  const { track } = useAnalytics();
  const userId = useSelector(userIdSelector);
  const savedSearches = useSelector(savedSearchesSelector);
  const [showSavedSearchesTooltip, setShowSavedSearchesTooltip] =
    useState<boolean>(false);
  const prevCount = usePrevious(savedSearches.length);
  useEffect(() => {
    const SSTooltipAlreadySeen = safelyGetItem(window.localStorage)(
      SS_TOOLTIP_LOCAL_STORAGE_KEY,
    );

    if (
      prevCount === 0 &&
      savedSearches.length === 1 &&
      !SSTooltipAlreadySeen
    ) {
      setShowSavedSearchesTooltip(true);
      safelySetItem(window.localStorage)(SS_TOOLTIP_LOCAL_STORAGE_KEY, "true");
    }
  }, [savedSearches.length, prevCount]);

  useEffect(() => {
    const savedSearchesTooltipTimer = setTimeout(() => {
      if (showSavedSearchesTooltip) setShowSavedSearchesTooltip(false);
    }, 10000);
    return () => clearTimeout(savedSearchesTooltipTimer);
  }, [showSavedSearchesTooltip]);

  const handleClick = () =>
    track(navigationClicked("Favorites", "global_header"));

  const handleSSTooltipClick = () => setShowSavedSearchesTooltip(false);

  return (
    <div className="-mygrails">
      <TrackableMenuItem
        href={`/mygrails/${userId}`}
        label="Favorites"
        onClick={handleClick}
      >
        <div className="-heart-wrapper">
          <Heart isFull />
        </div>
      </TrackableMenuItem>
      <MyGrailsHeaderTooltip className={styles.tooltip} />
      {showSavedSearchesTooltip ? (
        <SavedSearchesTooltip
          className={styles.tooltip}
          onClick={handleSSTooltipClick}
        />
      ) : null}
    </div>
  );
};

export default MyGrailsHeart;
