import { useState } from "react";
import type { IdentityCredentials } from "storefront/GrailedAPI/v1/Sessions/create";
import "storefront/GrailedAPI/v1/Sessions/create";
import type { Failure as FBLoginFailure } from "./login";
import fbLogin from "./login";
import fbMe from "./me";
import type { AuthResponse as FBAuthResponse } from "./AuthResponse";
import "./AuthResponse";
import type { User as FBUser } from "./User";
import "./User";
import type { FacebookIdentityProviderError } from "./Error";
import { fromFacebookLoginFailure } from "./Error";

type Options = {
  onSuccess: (credentials: IdentityCredentials) => unknown;
  onFailure?: (error: FacebookIdentityProviderError) => unknown;
};

const toCredentials =
  ({ accessToken }: FBAuthResponse) =>
  ({ email }: FBUser): IdentityCredentials => ({
    identityToken: accessToken,
    email,
    identityProvider: "facebook",
  });

const getUserData = (authResponse: FBAuthResponse) =>
  fbMe().then(toCredentials(authResponse));

const useFacebookLogin = ({
  onSuccess,
  onFailure,
}: Options): [() => void, boolean] => {
  /**
   * We keep track of whether the fb login is processing so that we don't try to re-start the process while it's already in-flight. We also provide this back to the caller so that we can adjust the UI.
   */
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onError = (res: FBLoginFailure) => {
    if (onFailure) onFailure(fromFacebookLoginFailure(res));
  };

  const onComplete = () => setIsProcessing(false);

  const attemptLogin = () => {
    if (isProcessing) return;
    setIsProcessing(true);
    fbLogin()
      .then(getUserData)
      .then(onSuccess)
      .catch(onError)
      .finally(onComplete);
  };

  return [attemptLogin, isProcessing];
};

export default useFacebookLogin;
