import { post } from "storefront/GrailedAPI/request";

export type Params = {
  via: "sms" | "call";
  countryCode: string;
  phoneNumber: number;
};
export type Response = {
  carrier: string;
  isCellphone: boolean;
  message: string;
  secondsToExpire: number;
  uuid: string;
  success: boolean;
  data: any;
};

/**
 * @name GrailedAPI.v1.Users.Verification.sendVerificationCode
 * @param {Params} params
 * @return {Promise<Response>} returns a response to notify receipt of verification code
 */
const sendVerificationCode: (arg0: Params) => Promise<Response> = (params) =>
  post("/api/users/send_verification_sms", params);

export default sendVerificationCode;
